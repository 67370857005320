import { makeAutoObservable, observable, action, values, computed, runInAction, autorun } from "mobx";

import moment from "moment";

import { ScanningPlan, ScanningStarted, ScanningFinished, ScanningResult } from "~/modules/scanning/data/models";
import ScanningsApiV0 from "../api/ScanningsApiV0";

/**
 * Стор планов сканирований.
 *
 *
 */
class ScanningPlansStore {
  @observable root;
  @observable pendingRequests = 0;

  @observable plans = new Map();
  @observable plansForEmployee = new Map();

  constructor(root) {
    makeAutoObservable(this);

    this.root = root;
    this.api = new ScanningsApiV0(root.authStore);

    // Эффект при смене бранча - получим его методы платежа. Они нужны для выставления счетов.
    autorun(() => {
      const { branch } = this.root.branchStore;
      const effect = async () => {
        await this.root.employeesStore.fetchEmployees(branch.id);
        // TODO: также нужно запросить оборудование - авто и сканеры
      };
      if (!branch) return;
      effect();
    });
  }

  @computed get isPending() {
    return this.pendingRequests > 0;
  }

  /**
   * Returns plans for Employee.
   *
   * @param {number} employeeId
   *
   * @returns
   */
  @computed get getPlansForEmployee() {
    const { authStore } = this.root;
    const employeeId = authStore.userId;
    const plansMap = this.plansForEmployee.get(`${employeeId}`);
    if (!plansMap) return [];
    return values(plansMap);
  }

  /**
   * Добавить план в стор.
   *
   * TODO: переименовать методв в addScanningPlan.
   * 
   * @param {object} data данные плана.
   * @param {Facility} facility экземпляр объекта для которого запланировано сканирование.
   */
  @action addPlan(data, facility, employee) {
    // TODO: не передали employee потому что его нет в подчинённых у отрисовщика
    // Если не передали работника
    if (!employee) employee = data.employee;

    const plan = new ScanningPlan(facility, employee, data);

    const planKey = `${plan.id}`;
    const mapKey = `${employee.id}`;

    this.plans.set(planKey, plan);

    if (this.plansForEmployee.has(mapKey)) {
      this.plansForEmployee.get(mapKey)[planKey] = plan;
    } else {
      this.plansForEmployee.set(mapKey, { [planKey]: plan });
    }
    return plan;
  }

  /**
   * Запросить сканирования для отображения на плане.
   *
   * @param {*} branchId
   * @param {*} employeeId
   * @param {*} withFinished
   * @param {*} withResult
   * @param {*} from
   * @param {*} to
   */
  // TODO: этот запрос должен быть в ScanningPlansStore? Там же нужно и планы очищать 
  // после перезапроса.
  @action async fetchFacilitiesWithScanningPlans(branchId, employeeId, withFinished, withResult, from, to) {
    const employee = this.root.employeesStore.getEmployeeById(employeeId);
    // TODO: как будто бекенд отдаёт лишнее здесь?
    const facilities = await this.api.getFacilitiesPlannedForScanning(
      branchId, employeeId, withFinished, withResult, from, to);
    runInAction(() => {
      this.plansForEmployee.clear();
      this.plans.clear();
      facilities.forEach((data) => {
        const facility = this.root.facilityStore.addFacility(null, data);
        if (!facility) return;
        // this.addPlan(data, facility, employee);
      });
    });
  }


  // /**
  //  * Обновить план.
  //  *
  //  * @param {*} planId идентификатор плана
  //  * @param {*} data данные полученные от бекенда
  //  *
  //  * "started": {
  //  *     "id": 493,
  //  *     "datetime": "2024-06-18T23:46:50.231000+00:00",
  //  *     "point": [
  //  *         54.8864,
  //  *         38.0731392
  //  *     ]
  //  * },
  //  */
  // @action updatePlan(planId, data) {
  //   console.log(data);
  // }

  /**
   * Начать сканирование.
   *
   * @param {ScanningPlan} plan план сканирования, к нему прикручен объект
   * @returns обновленный план сканирования
   */
  @action async startScanning(plan) {
    runInAction(() => this.pendingRequests++);
    try {
      const position = await this.root.getLocation();
      if (position) {
        const response = await this.root.api.setScanPlanStart({
          planId: plan.id,
          point: [position.coords.latitude, position.coords.longitude],
          datetime: moment(position.timestamp).utc(true).toISOString(),
        });
        if (response.started) {
          runInAction(() => (plan.started = new ScanningStarted(plan, response.started)));
        }
      }
    } catch (error) {
      console.warn(error);
      runInAction(() => this.pendingRequests--);
      return [false, error];
    }
    runInAction(() => this.pendingRequests--);
    return plan;
  }

  /**
   * Завершить сканирование.
   *
   * @param {ScanningPlan} plan план сканированя
   * @returns обновленный план сканирования
   */
  @action async finishScanning(plan, values) {
    runInAction(() => this.pendingRequests++);
    try {
      const position = await this.root.getLocation();
      if (position) {
        const response = await this.root.api.setScanPlanFinish({
          planId: plan.id,
          point: [position.coords.latitude, position.coords.longitude],
          datetime: moment(position.timestamp).utc(true).toISOString(),
          // количество станций при завершении сканирования
          stationsCount: Number.parseInt(values.stationsCount),
          mileage: Number.parseInt(values.mileage) || 0,
        });
        if (response["status"] === 400) {
          runInAction(() => this.pendingRequests--);
          return [false, response];
        }
        if (response.finished) {
          runInAction(() => (plan.finished = new ScanningFinished(plan, response.finished)));
        }
      }
    } catch (error) {
      console.warn(error);
      runInAction(() => this.pendingRequests--);
      return [false, error];
    }
    runInAction(() => this.pendingRequests--);
    return [true, {}];
  }

  /**
   * Загрузить ссылку на чистое облако.
   */
  @action async uploadScanningRawResultUrl(plan, url) {
    runInAction(() => this.pendingRequests++);
    const response = await this.api.putScanningRawResult(plan.id, url);
    runInAction(() => {
      this.pendingRequests--;
      plan.result = new ScanningResult(plan, response);
    });
    return;
  }

  /**
   * Загрузить ссылку на сшитое облако.
   * 
   * @param {*} plan
   * @param {*} url 
  */
  @action async uploadScanningLinkedResultUrl(plan, url) {
    runInAction(() => this.pendingRequests++);
    const response = await this.api.postScanningPlanLinkedResult(plan.id, url);
    runInAction(() => {
      this.pendingRequests--;
      plan.result = new ScanningResult(plan, response);
    });
    return;
  }
}

export default ScanningPlansStore;
