/**
 * Scanner's branch working shifts.
 */
export default class API {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  /**
   * Gets scanner's working shifts for Branch.
   *
   * @param {number} branchId
   * @returns JSON
   */
  async getBranchWorkingShifts(branchId, pagination, searchBy, daterange) {
    const token = this.rootStore.authStore.token;

    const { page, perPage } = pagination;
    const params = { page, perPage, searchBy };

    if (!!daterange[0]) {
      params["from"] = daterange[0].format("YYYY-MM-DD");
    }

    if (!!daterange[1]) {
      params["to"] = daterange[1].format("YYYY-MM-DD");
    }

    const search = new URLSearchParams(params);

    const url = `../api/v0/branches/${branchId}/working-shifts/for-scanners?${search}`;

    const response = await fetch(url, { headers: { Authorization: token } });
    const totalCount = parseInt(response.headers.get("Total-Count"));

    return { shifts: await response.json(), totalCount };
  }

  /**
   * Download trip certificate for shift.
   *
   * @param {number} shiftId
   * @returns
   */
  async downloadTripCertificate(shiftId) {
    const token = this.rootStore.authStore.token;
    const url = `/api/v0/working-shifts/${shiftId}/trip-certificate`;
    const response = await fetch(url, { headers: { Authorization: token } });
    return await response.blob();
  }
}
