import { ApiBase } from "~/api";

/**
 * API расходов.
 */
export default class API extends ApiBase {
  /**
   * Retrieve all expense categories for branch.
   */
  async getExpenseCategories(branchId) {
    const url = `/api/v0/branches/${branchId}/expenses/categories`;
    const response = await fetch(url, { headers: { Authorization: this.authStore.token } });
    return await response.json();
  }

  /**
   * Retriene all expenses from a given branch.
   *
   * @param {integer} branchId
   * @returns
   */
  // TODO: @deprecated
  async getExpensesForBranch(branchId) {
    // TODO: add pagination
    // TODO: add options
    const url = `/api/v0/branches/${branchId}/expenses`;
    const response = await fetch(url, { headers: { Authorization: this.authStore.token } });
    return await response.json();
  }

  /**
   * Create Contract Expense.
   *
   * @param {integer} contractId идентификатор контракта
   * @param {object} payload
   */
  async createContractExpense(contractId, payload) {
    const url = `api/v0/contracts/${contractId}/expenses`;
    const [data] = await this.post(url, payload);
    return data;
  }

  /**
   * Create shift expense.
   * 
   * @param {*} shiftId идентификатор смены
   * @param {*} payload данные расхода
   * @returns 
   */
  async createShiftExpense(shiftId, payload) {
    const url = `api/v0/working-shifts/${shiftId}/expenses`;
    const [data] = await this.post(url, payload);
    return data;
  }

  /**
   * Create facility expense.
   * 
   * @param {*} facilityId идентификатор объекта
   * @param {*} payload данные расхода
   * @returns 
   */
  async createFacilityExpense(facilityId, payload) {
    const url = `api/v0/facilities/${facilityId}/expenses`;
    const [data] = await this.post(url, payload);
    return data;
  }

  /**
   * Запросить расходы филиала.
   * 
   * Вернет данные и общее количество для пагинации.
   */
  async getBranchExpenses(branchId, pagination) {
    const url = `api/v0/branches/${branchId}/expenses/branch-expenses`;
    const { page, pageSize } = pagination;
    const backendPagination = { page: page + 1, perPage: pageSize };
    const parameters = { ...backendPagination };
    const [data, headers] = await this.get(url, parameters);
    return [data, headers.get("Total-Count", 0)];
  }

  /**
   * Добавить расход филиала.
   * 
   * @param {*} branchId идентификатор филиала.
   * @param {*} payload данные расхода.
   * @returns 
   */
  async createBranchExpense(branchId, payload) {
    const url = `api/v0/branches/${branchId}/expenses/branch-expenses`;
    const [data] = await this.post(url, payload);
    return data;
  }

  /**
   * Запросить расходы филиала.
   * 
   * Вернет данные и общее количество для пагинации.
   */
  async getSalaryExpenses(branchId, pagination) {
    const url = `api/v0/branches/${branchId}/expenses/salary-expenses`;
    const { page, pageSize } = pagination;
    const backendPagination = { page: page + 1, perPage: pageSize };
    const parameters = { ...backendPagination };
    const [data, headers] = await this.get(url, parameters);
    return [data, headers.get("Total-Count", 0)];
  }

  /**
   * Запросить расходы по контрактам.
   * 
   * Вернет данные и общее количество для пагинации.
   * 
   * @param {*} branchId 
   * @param {*} pagination 
   * @param {*} options 
   */
  async getContractExpenses(branchId, pagination, options = {}) {
    const url = `/api/v0/branches/${branchId}/expenses`;
    const SCOPE = "contract";

    const { page, pageSize } = pagination;
    const backendPagination = { page: page + 1, perPage: pageSize };
    const parameters = { ...backendPagination, ...options, scope: SCOPE };

    const [data, headers] = await this.get(url, parameters);

    return [data, headers.get("Total-Count", 0)];
  }

  /**
 * Запросить расходы по объектам.
 * 
 * Вернет данные и общее количество для пагинации.
 * 
 * @param {*} branchId 
 * @param {*} pagination 
 * @param {*} options 
 */
  async getFacilityExpenses(branchId, pagination, options = {}) {
    const url = `/api/v0/branches/${branchId}/expenses`;
    const SCOPE = "facility";

    const { page, pageSize } = pagination;
    const backendPagination = { page: page + 1, perPage: pageSize };
    const parameters = { ...backendPagination, ...options, scope: SCOPE };

    const [data, headers] = await this.get(url, parameters);

    return [data, headers.get("Total-Count", 0)];
  }

  /**
  * Запросить расходы по сменам.
  * 
  * Вернет данные и общее количество для пагинации.
  * 
  * @param {*} branchId 
  * @param {*} pagination 
  * @param {*} options 
  */
  async getShiftExpenses(branchId, pagination, options = {}) {
    const url = `/api/v0/branches/${branchId}/expenses`;
    const SCOPE = "shift";

    const { page, pageSize } = pagination;
    const backendPagination = { page: page + 1, perPage: pageSize };
    const parameters = { ...backendPagination, ...options, scope: SCOPE };

    const [data, headers] = await this.get(url, parameters);

    return [data, headers.get("Total-Count", 0)];
  }

  ;
}
