import React from "react";
import { observer } from "mobx-react";

import { Translator } from "~/components";
import { Button } from "@mui/material";

import BackupIcon from "@mui/icons-material/Backup";

/**
 * Кнопка загрузки сшитого облака.
 */
const UploadLinkedResultButton = observer(({ onClick }) => {
  return (
    <Button variant="contained" startIcon={<BackupIcon />} onClick={onClick}>
      <Translator text="Set linked url" />
    </Button>
  );
});

export default UploadLinkedResultButton;
