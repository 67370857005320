import React from "react";
import { observer } from "mobx-react";

import moment from "moment";

import { Box, Chip, Tooltip, Typography } from "@mui/material";

import { ScannerOutlined, ViewInArOutlined } from "@mui/icons-material";
import Translator from "../../components/Translator";
import FacilityIcon from "./components/FacilityIcon";

const FacilityRender = observer(({ facility, isFull, isSmall, isMinimal, isDescr, isName, color = "info", status }) => {
  if (!facility) return;

  const serviceIconsByKind = {
    scanning: <ScannerOutlined />,
    modelling: <ViewInArOutlined />,
  };

  return (
    <Box sx={{ flexDirection: "column", display: "flex", overflow: "hidden" }}>
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignItems: "flex-start",
          mt: 0.25,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            flexDirection: "column",
            display: "flex",
            mr: 0.5,
          }}
        >
          {!isName && (
            <Tooltip
              arrow
              title={
                <Box
                  sx={{
                    flexDirection: "row",
                    display: "flex",
                    flexShrink: 0,
                  }}
                >
                  <Box
                    sx={{
                      flexDirection: "column",
                      display: "flex",
                      mr: 0.5,
                      mt: 0.5,
                    }}
                  >
                    <FacilityIcon kind={facility.kind} />
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      mr: "1rem",
                    }}
                  >
                    <Typography sx={{ whitespace: "nowrap" }} variant="body2">
                      {facility.name}
                    </Typography>
                    {facility.address && <Typography variant="body">{facility.address}</Typography>}
                    {facility.area && (
                      <Typography variant="h5">
                        <Translator number={facility.area} /> <Translator text="m2" />
                      </Typography>
                    )}
                    {facility.description && <Typography variant="body">{facility.description}</Typography>}
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    {!!facility.services.length &&
                      facility.services.map((service) => {
                        return (
                          <Box key={service && service.uid} sx={{ flexDirection: "row", display: "flex" }}>
                            <Box>
                              <Typography
                                key="employee"
                                sx={{
                                  whitespace: "nowrap",
                                  textTransform: "capitalize",
                                }}
                                variant="h6"
                              >
                                {service.kind}
                              </Typography>
                              {service.employeeFullName && (
                                <Typography key="employee" sx={{ whitespace: "nowrap" }} variant="body2">
                                  {service.employeeFullName}
                                </Typography>
                              )}
                              {service.hasRaw && (
                                <Typography key="raw" sx={{ whitespace: "nowrap" }} variant="body2">
                                  <a href={service.result.rawCloud}>
                                    <Translator text={"Scanning result"} />
                                  </a>
                                </Typography>
                              )}
                              {service.hasLinked && (
                                <Typography key="linked" sx={{ whitespace: "nowrap" }} variant="body2">
                                  <a href={service.result.linkedCloud}>
                                    <Translator text={"Linking result"} />
                                  </a>
                                </Typography>
                              )}
                              {service.hasResult && service.kind === "modelling" && (
                                <Typography key="modelled" sx={{ whitespace: "nowrap" }} variant="body2">
                                  <a href={service.result.url}>
                                    <Translator text={"Modelling result"} />
                                  </a>
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        );
                      })}
                  </Box>
                </Box>
              }
            >
              <Chip
                sx={{ p: "0.5rem", borderRadius: "4px" }}
                // color={}
                size="small"
                icon={<FacilityIcon kind={facility.kind} />}
                label={
                  <Typography variant="body">
                    <Translator number={facility.area} /> <Translator text="m2" />
                  </Typography>
                }
              />
            </Tooltip>
          )}
        </Box>
        {!isMinimal && (
          <Box
            sx={{
              mt: "0.25rem",
              overflow: "hidden",
              textOverflow: isSmall || isName ? "ellipsis" : null,
            }}
          >
            <Typography variant="body">{facility.name}</Typography>
          </Box>
        )}
        {!isFull && !isSmall && !!status && (
          <Box
            sx={{
              alignItems: "center",
              flexDirection: "row",
              display: "flex",
              ml: 0.5,
            }}
          >
            {!!facility.services.length &&
              facility.services.map((service) => {
                let color = "info";
                if (service.isStarted) {
                  color = "warning";
                }
                if (service.hasRaw && status === "Measuring") {
                  color = "success";
                }
                if (service.hasLinked && service.kind === "scanning") {
                  color = "success";
                }
                if (service.hasResult && service.kind === "modelling") {
                  color = "success";
                }
                return (
                  <Tooltip
                    key={service.uid}
                    arrow
                    title={
                      <Box sx={{ flexDirection: "row", display: "flex" }}>
                        <Box>
                          {service.employeeFullName && (
                            <Typography key="employee" sx={{ whitespace: "nowrap" }} variant="body2">
                              {service.employeeFullName}
                            </Typography>
                          )}
                          <Translator key="plan" text={"Plan"} />{" "}
                          {service.start && (
                            <Typography key="ps" sx={{ whitespace: "nowrap" }} variant="body2">
                              <Translator text={"Start:"} /> <Translator datetime={service.start} />
                            </Typography>
                          )}
                          {service.finish && (
                            <Typography key="pf" sx={{ whitespace: "nowrap" }} variant="body2">
                              <Translator text={"Finish:"} /> <Translator datetime={service.finish} />
                            </Typography>
                          )}
                          {(service.started || service.finished) && <Translator key="fact" text={"Fact"} />}
                          {service.started && (
                            <Typography key="fs" sx={{ whitespace: "nowrap" }} variant="body2">
                              <Translator text={"Start:"} />{" "}
                              <Translator datetime={moment(service.started.datetime).utc()} />
                            </Typography>
                          )}
                          {service.finished && (
                            <Typography key="ff" sx={{ whitespace: "nowrap" }} variant="body2">
                              <Translator text={"Finish:"} />{" "}
                              <Translator datetime={moment(service.finished.datetime).utc()} />
                            </Typography>
                          )}
                          {service.hasRaw && (
                            <Typography key="raw" sx={{ whitespace: "nowrap" }} variant="body2">
                              <a href={service.result.rawCloud}>
                                <Translator text={"Scanning result"} />
                              </a>
                            </Typography>
                          )}
                          {service.hasLinked && (
                            <Typography key="linked" sx={{ whitespace: "nowrap" }} variant="body2">
                              <a href={service.result.linkedCloud}>
                                <Translator text={"Linking result"} />
                              </a>
                            </Typography>
                          )}
                          {service.hasResult && service.kind === "modelling" && (
                            <Typography key="modelled" sx={{ whitespace: "nowrap" }} variant="body2">
                              <a href={service.result.url}>
                                <Translator text={"Modelling result"} />
                              </a>
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    }
                  >
                    <Chip
                      sx={{
                        p: "0.25rem",
                        m: "0 0.25rem",
                        "& .MuiChip-label": {
                          pr: 0,
                        },
                      }}
                      key={service.kind}
                      color={color}
                      size="small"
                      varian="outlined"
                      icon={serviceIconsByKind[service.kind]}
                    />
                  </Tooltip>
                );
              })}
          </Box>
        )}
      </Box>
      {(isFull || isDescr) && (
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            mt: "0.5rem",
          }}
        >
          <Box>
            {facility.address && <Typography variant="body">{facility.address}</Typography>}
            {facility.description && <Typography variant="body">{facility.description}</Typography>}
          </Box>
        </Box>
      )}
    </Box>
  );
});

export default FacilityRender;
