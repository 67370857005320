import React, { useCallback, useState, useMemo } from "react";

import { FormDialog } from "~/components/dialogs";

import useStores from "~/hooks";

/**
 * Диалог создания клиента.
 *
 * @param {object} props пропсы диалога создания клиента
 */
const CreateClientDialog = ({ isOpen, onClose }) => {
  const { rootStore, branchStore, clientStore } = useStores();
  const { branch } = branchStore;

  const [isPending, setIsPending] = useState(false);

  // Обработать форму
  const handleSubmit = useCallback(
    async (data) => {
      setIsPending(true);
      const payload = { ...data };
      await clientStore.createClient(branch, payload);
      setIsPending(false);
      onClose && onClose();
    },
    [branch, clientStore, onClose]
  );

  // Конфиг формы
  const formConfig = useMemo(() => {
    return {
      submitText: "Create",
      cancelText: "Cancel",
      fields: [
        {
          name: "name",
          title: "Name",
          type: "text",
          multiline: false,
          isRequired: true,
          isReadOnly: false,
          validate: true,
        },
        {
          name: "description",
          title: "Description",
          type: "text",
          multiline: true,
          isRequired: false,
          isReadOnly: false,
          validate: false,
        },
      ],
    };
  }, []);

  return (
    <FormDialog
      title={"Creating client"}
      formConfig={formConfig}
      isVisible={isOpen}
      closeDialog={onClose}
      handleSubmit={handleSubmit}
      isPending={isPending}
    />
  );
};

export default CreateClientDialog;
