import { observer } from "mobx-react";
import { Box, Typography } from "@mui/material";
import RequestPageIcon from "@mui/icons-material/RequestPage";

const ContractButton = observer(({ number }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", color: "#1177d3" }}>
      {/* иконка договора */}
      <RequestPageIcon sx={{ marginRight: 1 }} />
      {/* номер договора */}
      <Typography variant="h6" sx={{ fontSize: "1.1rem" }}> 
        {number}
      </Typography>
    </Box>
  );
});

export default ContractButton;
