import { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react";

import { FileOpen, Image, PictureAsPdf } from "@mui/icons-material";
import { Box, ListItem, ListItemIcon, Tooltip, Typography } from "@mui/material";

import FileView from "./FileView";
import Translator from "./Translator";

const DocumentAsIcon = observer(({ document, noPreview, noDownload, type, isCompact }) => {
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  const onShowPreview = useCallback(() => {
    if (!noPreview) {
      setIsPreviewVisible(true);
    }
  }, [setIsPreviewVisible, noPreview]);

  const icon = useMemo(() => {
    if (document.file) {
      if (document.file.mimetype && document.file.mimetype === "application/pdf") {
        return <PictureAsPdf color="info" />;
      } else if (document.file.mimetype && document.file.mimetype.split("/")[0] === "image") {
        return <Image color="info" />;
      }
    }
    return <FileOpen color="info" />;
  }, [document]);

  const onHidePreview = useCallback(
    (e) => {
      e.stopPropagation();
      setIsPreviewVisible(false);
    },
    [setIsPreviewVisible]
  );

  if (!document) return;

  return (
    <ListItem sx={{ overflow: "hidden", width: "auto" }} onClick={onShowPreview}>
      <Tooltip arrow title={<Typography variant="body">{document.file.name}</Typography>}>
        <ListItemIcon sx={{ minWidth: 0 }}>{icon}</ListItemIcon>
      </Tooltip>
      {!isCompact && (
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column", overflow: "hidden" }}>
          <Box sx={{ flex: 1, display: "flex", alignItems: "center", overflow: "hidden" }}>
            <Tooltip arrow title={<Typography variant="body">{document.file.name}</Typography>}>
              <Typography
                sx={{ flex: 1, textOverflow: "ellipsis", overflow: "hidden" }}
                variant="body"
                color="text.secondary"
              >
                {document.file.name}
              </Typography>
            </Tooltip>
          </Box>
          <Typography
            sx={{ flex: 1, textOverflow: "ellipsis", overflow: "hidden" }}
            variant="body2"
            color="text.secondary"
          >
            <Translator datetime={document.createdAt} variant={"compact"} />
          </Typography>
        </Box>
      )}
      <FileView
        isPreviewVisible={isPreviewVisible}
        onHidePreview={onHidePreview}
        file={document}
        type={type}
        noPreload={true}
      />
    </ListItem>
  );
});

export default DocumentAsIcon;
