import { ApiBase } from "~/api";

/**
 * API клиентов версии 0.
 */
class ClientsApiV0 extends ApiBase {
  /**
   * Запросить клиентов филиала.
   */
  async getClientsForBranch(branchId) {
    const url = `api/v0/branches/${branchId}/clients`;
    const [data] = await this.get(url);
    return data;
  }

  /**
   * Создать клиента в филиале.
   *
   * @param {number} branchId идентификатор филиала
   * @param {object} payload объект с данными клиента
   */
  async createClient(branchId, payload) {
    const url = `api/v0/branches/${branchId}/clients`;
    const [data] = await this.post(url, payload);
    return data;
  }
}

export default ClientsApiV0;
