import React, { useState, useEffect } from 'react';
import { Box, IconButton, Collapse, Alert, AlertTitle } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Translator } from '~/components';

const GlobalErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(null);

  const handleDismiss = () => {
    setHasError(false);
    setError(null);
  };

  useEffect(() => {
    const errorHandler = (event) => {
      setHasError(true);
      setError(event.error);
    };

    // ловим глобальные ошибки
    window.addEventListener('error', errorHandler);

    return () => {
      window.removeEventListener('error', errorHandler);
    };
  }, []);

  if (hasError) {
    return (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          padding: "0.25rem",
          zIndex: 2000,
        }}
      >
        <Collapse in={hasError}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleDismiss}
              >
                <Close />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            <AlertTitle>
              <Translator text="Error Occurred" />
            </AlertTitle>
            <Translator text={error?.message || "Something went wrong"} />
          </Alert>
        </Collapse>
      </Box>
    );
  }

  return children;
};

export default GlobalErrorBoundary;
