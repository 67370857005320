import React from "react";

import { IconButton, useTheme, useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

export default function ToolbarMenuIconButton({ onClick, isOpen }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <IconButton
      color="inherit"
      aria-label="open drawer"
      onClick={onClick}
      edge="start"
      sx={{
        paddingLeft: isMobile ? "0.75rem" : undefined,
        marginRight: isMobile ? 0 : 5,
        ...(isOpen && { display: "none" }),
      }}
    >
      <MenuIcon />
    </IconButton>
  );
}
