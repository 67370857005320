import { computed, makeObservable, observable } from "mobx";

/**
 * Категория расходов.
 */
class Category {
  @observable id;
  @observable name = "";
  @observable scopes = new Set();

  @observable store;

  constructor({ id, name, scopes }, store) {
    makeObservable(this);

    this.id = id;
    this.name = name;
    scopes.forEach((scope) => {
      this.scopes.add(scope);
    });

    this.store = store;
  }

  @computed get label() {
    return this.name;
  }
}

export default Category;
