import React, { useCallback, useMemo, useRef } from "react";
import { observer } from "mobx-react";

import { Box, Button, Card, CardContent, TextField } from "@mui/material";
import Translator from "./Translator";
import { Add, AddAPhoto } from "@mui/icons-material";
import FilePreview from "./FilePreview";

const FileInput = observer(
  React.forwardRef(
    (
      {
        hiddenStyle,
        name,
        value,
        type,
        accept,
        error,
        multiple,
        min,
        max,
        setFileValue,
        setIsChanged,
        fileValue,
        fileType,
      },
      ref
    ) => {
      const labelRef = useRef();

      const onClick = useCallback(
        (e) => {
          if (labelRef && labelRef.current) {
            labelRef.current.click(e);
          }
        },
        [labelRef]
      );
      const onChange = useCallback(
        (e) => {
          if (e.target.files) {
            setIsChanged(true);
            setFileValue([...value, ...e.target.files]);
          }
        },
        [setFileValue, value, setIsChanged]
      );

      const deleteByIndex = useCallback(
        (index) => {
          const newValue = [...value];
          newValue.splice(index, 1);
          setFileValue(newValue);
        },
        [value, setFileValue]
      );

      const previews = useMemo(() => {
        return (
          value &&
          value.map((file, i) => {
            return (
              <FilePreview
                key={file && !!file.file ? `itm-${file.id}` : "index" + i}
                file={file}
                fileType={fileType}
                deleteByIndex={deleteByIndex}
                index={i}
              />
            );
          })
        );
      }, [value, deleteByIndex, fileType]);

      const numberOfFiles = useMemo(() => {
        return (value && value.length) || 0;
      }, [value]);

      const canAdd = useMemo(() => {
        if (!numberOfFiles) {
          return true;
        }

        if (!multiple && !!numberOfFiles) {
          return false;
        }

        if (!!multiple && numberOfFiles >= max) {
          return false;
        }

        return true;
      }, [multiple, max, numberOfFiles]);

      return (
        <Box
          ref={ref}
          sx={{
            flexDirection: "row",
            flex: 1,
            display: "flex",
            p: "0.5rem",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="upload__btn-box">
            <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
              {previews}
              {canAdd && (
                <Card sx={{ m: "0.25rem", width: "15rem", border: 0 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "10rem",
                    }}
                    onClick={onClick}
                  >
                    <AddAPhoto color="info" sx={{ fontSize: "7rem", cursor: "pointer" }} />
                  </Box>
                  {/* <CardContent sx={{ paddingBottom: "0.5rem !important" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Button
                        key={"addFile"}
                        variant="outlined"
                        startIcon={<Add />}
                        color="info"
                        onClick={onClick}
                      >
                        <Translator text={"Add"} />
                      </Button>
                    </Box>
                  </CardContent> */}
                </Card>
              )}
            </Box>

            <input ref={labelRef} type={type} onChange={onChange} accept={accept} style={{ display: "none" }} />
            <TextField sx={hiddenStyle} error={error} name={name} id={`file-${name}`} value={fileValue} />
          </div>
        </Box>
      );
    }
  )
);

export default FileInput;
