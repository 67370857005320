import React from "react";

import { Button } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import Translator from "~/components/Translator";

const URLButton = ({ link, name }) => {
  // const theme = useTheme();
  if (!link) return;
  return (
    <Button href={link} target="blank" startIcon={<OpenInNewIcon />}>
      <Translator text={name} />
    </Button>
  );
};

export default URLButton;
