import React, { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react";

import { Dialog } from "@mui/material";

import { Notify } from "~/common/components";
import Translator from "~/components/Translator";
import Form from "~/components/Form";
import useStores from "~/hooks";

/**
 * Диалог добавления расхода в контракт.
 */
const AddingContractExpenseDialog = observer(({ isOpen, onClose, contract }) => {
  const SCOPE = "contract";

  const [isNotifyOpen, setIsNotifyOpen] = useState(false);

  const { expenseStore, branchStore } = useStores();
  const { branch } = branchStore;

  // закрыть диалог
  const handleClose = useCallback(
    (e, reason) => {
      if (reason === "backdropClick") return;
      !!onClose && onClose();
    },
    [onClose]
  );

  // TODO: здесь нужно учесть isPending стора
  // отправить форму
  const handleSubmit = useCallback(
    async (data) => {
      const payload = {
        ...data,
        documents: data.documents === "" ? [] : data.documents,
        // TODO: сделать тип Money
        value: {
          amount: Number(data.value),
          currency: branchStore.branch.currency,
        },
      };
      await expenseStore.createExpense(payload, contract);
      !!onClose && onClose();
      setIsNotifyOpen(true);
    },
    [contract, expenseStore, setIsNotifyOpen, onClose, branchStore.branch.currency]
  );

  // Ошибки отправки формы
  const formErrors = [];

  // диалог
  const expenseCategoryOptions = useMemo(() => {
    const opts = [];
    for (const category of branch.expenseCategories) {
      if (category.scopes.has(SCOPE)) {
        opts.push({ id: category.id, label: category.name, value: category.id });
      }
    }
    return opts;
  }, [branch]);

  const config = {
    submitText: "Register",
    cancelText: "Cancel",
    formTitle: "Registering contract expense",
    fields: [
      {
        name: "categoryId",
        fakeName: "category",
        title: "Choose expense kind",
        type: "select",
        isRequired: true,
        isReadOnly: false,
        validate: true,
        loading: !branch,
        options: expenseCategoryOptions,
      },
      {
        name: "value",
        title: "Expense value",
        type: "number",
        viewConfig: "input",
        inputMode: "decimal",
        startAdornment: branch && branch.currency,
        min: 0,
        step: 0.01,
        isRequired: true,
        isReadOnly: false,
        validate: true,
      },
      {
        id: "documents",
        name: "documents",
        title: "Documents",
        type: "file",
        multiple: true,
        min: 1,
        isRequired: true,
        isReadOnly: false,
        validate: true,
      },
    ],
  };
  return (
    <>
      <Dialog sx={{ ".MuiPaper-root": { maxWidth: "60vw" } }} open={isOpen} onClose={handleClose}>
        <Form
          isPending={expenseStore.isPending}
          config={config}
          onSubmit={handleSubmit}
          onCancel={handleClose}
          errors={formErrors}
        />
      </Dialog>
      <Notify isOpen={isNotifyOpen} onClose={() => setIsNotifyOpen(false)}>
        <Translator text={"Expense created"} />
      </Notify>
    </>
  );
});

export default AddingContractExpenseDialog;
