import React, { useCallback, useState } from "react";
import { Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Difference, Close } from "@mui/icons-material";

import Translator from "~/components/Translator";

import HistoryView from "../HistoryView";

/**
 * Диалог отображения истории контракта.
 *
 * @param {*} props
 * @returns
 */
const ContractHistoryDialog = ({ contract, isOpen, onClose }) => {
  const [isDiff, setIsDiff] = useState(true);
  const onDiffToggle = useCallback(() => {
    setIsDiff(!isDiff);
  }, [isDiff, setIsDiff]);

  return (
    <Dialog sx={{ ".MuiPaper-root": { maxWidth: "60vw" } }} open={isOpen} onClose={onClose}>
      <DialogTitle sx={{ display: "flex", dlexDirection: "row", justifyContent: "space-between" }}>
        <Translator text="Contract history" />
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <HistoryView isDiff={isDiff} contract={contract} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onDiffToggle}
          startIcon={<Difference />}
          color={isDiff ? "warning" : "info"}
        >
          <Translator text={isDiff ? "Difference" : "Full"} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContractHistoryDialog;
