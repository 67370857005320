import { makeObservable, observable, computed } from "mobx";

import moment from "moment";
import Money from "./Money";
import Document from "./Document";

import { UserInfo } from "~/modules/employees/data/models";
import Category from "./Category";

/**
 * Расход.
 */
class Expense {
  @observable id;
  @observable createdAt;
  @observable scope;
  @observable category;
  @observable documents = [];
  @observable author;

  @observable contract;
  @observable shift;
  @observable facility;

  @observable store;

  constructor({ id, author, createdAt, scope, category, value, documentsMeta, contract, shift, facility }, store) {
    makeObservable(this);
    this.store = store;

    this.id = id;
    this.createdAt = moment(createdAt);
    this.scope = scope;
    this.documents = documentsMeta.map((d) => new Document(d));

    this.value = new Money({ amount: value.amount, currency: value.currency });
    this.contract = contract;
    this.shift = shift;
    this.facility = facility;

    const knownAuthor = store.root.employeesStore.getEmployeeById(author.id);
    if (!knownAuthor) {
      this.author = new UserInfo(author);
    } else {
      this.author = knownAuthor;
    }
    this.category = new Category({ ...category, scopes: [scope] });
  }

  /**
   * Является ли расходом объекта.
   */
  @computed get isFacilityExpense() {
    return this.scope === "facility";
  }

  /**
   * Является ли расходом контракта.
   */
  @computed get isContractExpense() {
    return this.scope === "contract";
  }

  @computed get isShiftExpense() {
    return this.scope === "shift";
  }

  /**
   * Ссылка на объект к которому относится расход
   */
  @computed get context() {
    if (this.isFacilityExpense) return this.facility;
    if (this.isContractExpense) return this.contract;
    if (this.isShiftExpense) return this.shift;
    return null;
  }
}

export default Expense;
