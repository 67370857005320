import React, { useMemo, useCallback, useState } from "react";
import { observer } from "mobx-react";

import { FormDialog } from "~/components/dialogs";
import { Notify, Translator } from "~/components";

import useStores from "~/hooks";

/**
 * Диалог добавления расхода смены.
 */
const AddingShiftExpenseDialog = observer(({ shift, isOpen, onClose }) => {
  const { branchStore, expenseStore } = useStores();
  const { branch } = branchStore;

  const [isNotifyOpen, setIsNotifyOpen] = useState(false);

  const isPending = expenseStore.isPending;
  const pendingText = expenseStore.pendingText;

  const config = useMemo(() => {
    if (!branch) return {};
    return {
      fields: [
        {
          name: "categoryId",
          fakeName: "category",
          title: "Choose expense kind",
          type: "select",
          isRequired: true,
          isReadOnly: false,
          validate: true,
          loading: false,
          options: expenseStore.getCategories(branch.id, "shift"),
        },

        {
          name: "amount",
          title: "Expense amount",
          type: "number",
          viewConfig: "input",
          inputMode: "decimal",
          startAdornment: branch.currency,
          min: 1,
          step: 0.01,
          isRequired: true,
          isReadOnly: false,
          validate: true,
        },

        {
          id: "documents",
          name: `documents`,
          title: "Documents",
          fileType: "documents",
          type: "file",
          multiple: true,
          min: 1,
          max: 4,
          isRequired: true,
          isReadOnly: false,
          validate: true,
        },
      ],
      submitText: "Add",
      cancelText: "Cancel",
    };
  }, [branch, expenseStore]);

  const handleSubmit = useCallback(
    async (data) => {
      const result = await expenseStore.createExpense(data, shift);
      if (result) {
        onClose();
        setIsNotifyOpen(true);
      }
    },
    [shift, onClose, setIsNotifyOpen, expenseStore]
  );

  return (
    <>
      <FormDialog
        title={"Adding shift expense"}
        formConfig={config}
        isVisible={isOpen}
        closeDialog={onClose}
        handleSubmit={handleSubmit}
        isPending={isPending}
        pendingText={pendingText}
      />
      <Notify isOpen={isNotifyOpen} onClose={() => setIsNotifyOpen(false)}>
        <Translator text={"Shift expense added"} />
      </Notify>
    </>
  );
});

export default AddingShiftExpenseDialog;
