import { action, computed, makeObservable, observable } from "mobx";
import ExpenseCategory from "../../../../models/ExpenseCategory";
import Scanner from "../../../../models/Scanner";
import Car from "~/modules/equipment/data/models/Car";

/**
 * Модель филиала.
 */
class Branch {
  @observable id = null;
  @observable name = null;
  @observable currency = null;
  @observable icon = null;

  @observable store = null;

  @observable pending = false;
  @observable pendingScanners = false;
  @observable pendingCars = false;
  @observable pendingShift = false;

  @observable pendingExpensesForFacility = false;

  @observable pendingExpensesForContract = false;

  @observable allExpenseCategoriesMap = new Map();
  @observable contractExpenseCategoriesMap = new Map();
  @observable facilityExpenseCategoriesMap = new Map();
  @observable shiftExpenseCategoriesMap = new Map();

  @observable paymentMethodsMap = new Map();

  @observable shiftsByRoleMap = new Map();

  @observable scannersMap = new Map();

  @observable carsMap = new Map();

  // Категории расходов
  @observable expenseCategories = [];

  constructor(store, props) {
    makeObservable(this);
    const { id, name, currency, icon, expenseCategories } = props;
    this.id = id;
    this.name = name;
    this.currency = currency;
    this.icon = icon;

    // Категории расходов.
    this.expenseCategories = [];

    // Нужно пересобрать структуру дописав к каждой все её скопы в бранче.
    const index = {};
    for (const [scope, categories] of Object.entries(expenseCategories)) {
      for (const category of categories) {
        if (category.id in index) {
          const scopes = index[category.id].scopes;
          if (!scopes.includes(scope)) scopes.push(scope);
        } else {
          index[category.id] = { ...category, scopes: [scope] };
        }
      }
    }
    // Теперь делаем модели и в стор.
    for (const category of Object.values(index)) {
      const c = store.root.expenseStore.addCategory({ ...category }, id);
      this.expenseCategories.push(c);
    }

    this.store = store;
    this.api = this.store.api; // TODO: не должно здесь быть
  }

  @action
  async getEquipmentScanners() {
    this.setPendingScanners(true);
    const branchId = this.id;
    const scannersData = await this.store.root.api.getEquipmentScanners(branchId);
    this.processScannersData(scannersData);
    this.setPendingScanners();
  }

  @action
  async getEquipmentCars() {
    this.setPendingCars(true);
    const branchId = this.id;
    const carsData = await this.store.root.api.getEquipmentCars(branchId);
    this.processCarsData(carsData);
    this.setPendingCars();
  }

  async getExpensesCategoriesForFacility() {
    this.setPendingExpensesForFacility(true);
    const facilityCategories = await this.store.root.api.getExpensesCategories(this.id, "facility");
    const shiftCategories = await this.store.root.api.getExpensesCategories(this.id, "workingshift");
    this.processFacilityExpenseCategories(facilityCategories);
    this.processShiftExpenseCategories(shiftCategories);
    this.setPendingExpensesForFacility(false);
  }

  async getExpensesCategoriesForContract() {
    this.setPendingExpensesForContract(true);
    const contractCategories = await this.store.root.api.getExpensesCategories(this.id, "contract");
    this.processContractExpenseCategories(contractCategories);
    this.setPendingExpensesForContract(false);
  }

  @action
  processContractExpenseCategories(data = []) {
    data.forEach((categoryData) => {
      const category = new ExpenseCategory(categoryData, this);
      this.contractExpenseCategoriesMap.set(category.id, category);
      this.allExpenseCategoriesMap.set(category.id, category);
    });
  }

  @action
  processFacilityExpenseCategories(data = []) {
    data.forEach((categoryData) => {
      const category = new ExpenseCategory(categoryData, this);
      this.facilityExpenseCategoriesMap.set(category.id, category);
      this.allExpenseCategoriesMap.set(category.id, category);
    });
  }

  @action
  processShiftExpenseCategories(data = []) {
    data.forEach((categoryData) => {
      const category = new ExpenseCategory(categoryData, this);
      this.shiftExpenseCategoriesMap.set(category.id, category);
      this.allExpenseCategoriesMap.set(category.id, category);
    });
  }

  // TODO: delete!
  // @action async processNewShiftData(item) {
  //   const shift = new Shift(item, this);
  //   if (shift && shift.id) {
  //     await shift.getExpenses();
  //     this.shiftsByRoleMap.set(shift.role, shift);
  //   }
  // }

  @action
  processScannersData(data) {
    if (data) {
      this.scannersMap.clear();
      data.forEach((item) => {
        const scanner = new Scanner(item, this);
        this.scannersMap.set(scanner.id, scanner);
      });
    }
  }

  @action
  processCarsData(data) {
    if (data) {
      this.carsMap.clear();
      data.forEach((item) => {
        const car = new Car(item, this);
        this.carsMap.set(car.id, car);
      });
    }
  }

  @action processShiftData(data) {
    if (data) {
      this.shiftsByRoleMap.clear();
      data.forEach((item) => {
        this.processNewShiftData(item);
      });
    }
  }

  @action
  setPending(pending = false) {
    this.pending = pending;
  }

  @action
  setPendingScanners(pending = false) {
    this.pendingScanners = pending;
  }

  @action
  setPendingCars(pending = false) {
    this.pendingCars = pending;
  }

  @action
  setPendingShift(pending = false) {
    this.pendingShift = pending;
  }

  @action
  setPendingExpensesForFacility(pending = false) {
    this.pendingExpensesForFacility = pending;
  }

  @action
  setPendingExpensesForContract(pending = false) {
    this.pendingExpensesForContract = pending;
  }

  @computed
  get isPending() {
    return this.isPendingScannes || this.isPendingShifts;
  }

  @computed
  get isPendingScannes() {
    return this.pendingScanners;
  }

  @computed
  get isPendingShifts() {
    return this.pending;
  }

  @computed
  get isPendingShift() {
    return this.pendingShift;
  }

  @computed
  get isPendingExpensesForFacility() {
    return this.pendingExpensesForFacility;
  }
  @computed
  get isPendingExpensesForContract() {
    return this.pendingExpensesForContract;
  }

  @computed get contractExpenseCategoriesArray() {
    return Array.from(this.contractExpenseCategoriesMap.values());
  }

  @computed
  get facilityExpenseCategoriesArray() {
    return Array.from(this.facilityExpenseCategoriesMap.values());
  }
  @computed
  get shiftExpenseCategoriesArray() {
    return Array.from(this.shiftExpenseCategoriesMap.values());
  }

  // @action
  getExpenseCategoryById(id) {
    if (!id) return null;
    return this.allExpenseCategoriesMap.get(`${id}`);
  }

  @computed
  get label() {
    return `${this.flag} ${this.name}`;
  }

  @computed
  get currentScannerShift() {
    const shift = this.shiftsByRoleMap.get("scanner");
    if (shift && shift.startedAt && !shift.finishedAt) {
      return shift;
    }
    return null;
  }

  @computed
  get scannersArray() {
    return Array.from(this.scannersMap.values());
  }

  @computed
  get carsArray() {
    return Array.from(this.carsMap.values());
  }

  @computed
  get value() {
    return `${this.id}`;
  }
}

export default Branch;
