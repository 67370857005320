import React, { useCallback, useState } from "react";
import { observer } from "mobx-react";
import { Button, Dialog } from "@mui/material";
import { Delete } from "@mui/icons-material";

import useStores from "~/hooks";

import Translator from "../../components/Translator";
import Form from "../../components/Form";
import Preloader from "../../components/Preloader";

const DeleteInvoice = observer(({ invoice }) => {
  const { invoiceStore, rootStore } = useStores();
  const { id, amount, currency, contractNumber, clientName, payedAmount } =
    invoice;
  const [open, setOpen] = useState(false);
  const [isModalPending, setModalPending] = useState(false);

  const onSubmit = useCallback(
    async (data) => {
      try {
        setModalPending(true);
        await invoiceStore.deleteInvoiceByIdAsync(data);
      } catch (error) {
        rootStore.setError("error", null, error && error.message);
      }
      setModalPending(false);
      setOpen(false);
    },
    [invoiceStore]
  );

  const onOpenDialogDelete = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const onCloseDialog = useCallback(
    (e, reason) => {
      reason !== "backdropClick" && setOpen(false);
    },
    [setOpen]
  );

  return (
    <Button
      key={"deleteInvoice"}
      variant="outlined"
      color="error"
      startIcon={<Delete />}
      sx={{ flex: 1 }}
      disabled={!!payedAmount}
      onClick={onOpenDialogDelete}
    >
      <Translator text={"Delete invoice"} />
      <Dialog maxWidth={"xs"} open={open} onClose={onCloseDialog}>
        {!isModalPending && (
          <Form
            config={{
              submitText: "Submit",
              cancelText: "Cancel",
              formTitle: "Delete invoice",
              formText: "Are you shure you want to delete invoice below?",
              fields: [
                {
                  name: "invoiceId",
                  type: "hidden",
                  viewConfig: "input",
                  initialValue: id,
                },
                {
                  name: "contractNumber",
                  title: "Contract number",
                  type: "string",
                  viewConfig: "input",
                  isReadOnly: true,
                  initialValue: contractNumber,
                },
                {
                  name: "clientName",
                  title: "Client name",
                  type: "string",
                  viewConfig: "input",
                  isReadOnly: true,
                  initialValue: clientName,
                },
                {
                  name: "amount",
                  title: "Amount",
                  type: "number",
                  viewConfig: "input",
                  inputMode: "decimal",
                  startAdornment: currency,
                  isReadOnly: true,
                  initialValue: amount,
                },
              ],
            }}
            onSubmit={onSubmit}
            onCancel={onCloseDialog}
          />
        )}
        <Preloader isPending={isModalPending} />
      </Dialog>
    </Button>
  );
});

export default DeleteInvoice;
