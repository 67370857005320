import { makeAutoObservable, action, observable, computed } from "mobx";
import { MaterialsApiV0 } from "../api";

/**
 * Стор материалов - текстовых справок для показа пользователям.
 */
class MaterialsStore {
  @observable pendingRequests = 0;
  @observable root;
  @observable api;

  constructor(root) {
    makeAutoObservable(this);
    this.root = root;
    this.api = new MaterialsApiV0(root.authStore);
  }

  @computed get isPending() {
    return this.pendingRequests > 0;
  }

  /**
   * Запросить материалы для роли в филиале на указанном языке.
   *
   * @param {*} branchId
   * @param {*} role
   * @param {*} lang
   * @returns
   */
  @action async fetchMaterialsForRole(branchId, role, lang) {
    this.pendingRequests++;
    const materials = this.api.fetchMaterials(branchId, { role, lang });
    this.pendingRequests--;
    return materials;
  }
}

export default MaterialsStore;
