import React, { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react";

import {
  Box,
  Button,
  Dialog,
  CardContent,
  CardActions,
  Typography,
  Tooltip,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
} from "@mui/material";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";

import useStores from "~/hooks";
import { Card } from "~/components/cards";

import { Preloader, Translator } from "~/components";

import { OpeningShiftDialog, ClosingShiftDialog } from "../dialogs";

import ShiftExpenses from "../../../scanning/ShiftExpenses";
import OpenShiftButton from "../buttons/OpenShiftButton";
import { AddingShiftExpenseDialog } from "~/modules/expenses/components/dialogs";

/**
 * Карточка текущей смены.
 *
 * С этой карточки начинает смену сканировщик.
 */
const ShiftCard = observer((props) => {
  const { authStore, branchStore, equipmentStore } = useStores();
  const { shiftExpensesFormConfig } = branchStore;

  const theme = useTheme();

  const [formType, setFormType] = useState("start");
  const [formVisible, setFormVisible] = useState(false);
  const [formErrorText, setFormErrorText] = useState(null);
  const [expensesVisible, setExpensesVisible] = useState(false);

  // ----
  const { shift, isScanningInProgress } = props;
  const isPending = props.isPending || equipmentStore.isPending;

  const [isOpeningShiftDialogOpen, setIsOpeningShiftDialogOpen] = useState(false);
  const [isClosingShiftDialogOpen, setIsClosingShiftDialogOpen] = useState(false);
  const [isAddingShiftExpenseDialogOpen, setIsAddingShiftExpenseDialogOpen] = useState(false);

  const { branch } = branchStore;
  // ----

  // TODO: разложить на отдельные формы и обработчики
  // const onSubmitForm = useCallback(
  //   async (data) => {
  //     try {
  //       setModalPending(true);
  //       setFormErrorText(null);
  //       if (formType === "finish-expenses" && !!shift) {
  //         await shift.setExpenses(data);
  //         setFormType("finish");
  //       } else if (formType === "expenses" && !!shift) {
  //         await shift.setExpenses(data);
  //         setFormVisible(false);
  //         setFormType(null);
  //       } else if (formType === "start") {
  //         // if (data.scannerPhoto && data.scannerPhoto.length) {
  //         //   const scannerPhotoIdsArray = await rootStore.uploadPhotos(data.scannerPhoto);
  //         //   data.scannerPhotoIds = scannerPhotoIdsArray;
  //         // }
  //         // if (data.carPhoto && data.carPhoto.length) {
  //         //   const carPhotoIdsArray = await rootStore.uploadPhotos(data.carPhoto);
  //         //   data.carPhotoIds = carPhotoIdsArray;
  //         // }
  //         // const result = await branch.startShift("scanner", data);
  //         // if (result && result.id) {
  //         //   setFormVisible(false);
  //         //   setFormType(null);
  //         // } else {
  //         //   setFormErrorText(result.detail);
  //         // }
  //       }
  //       // else if (formType === "finish") {
  //       //   if (result && result.id) {
  //       //     setFormVisible(false);
  //       //     setFormType(null);
  //       //   } else {
  //       //     setFormErrorText(result.detail);
  //       //   }
  //       // }
  //     } catch (error) {
  //       rootStore.setError("error", null, error && error.message);
  //       setFormVisible(false);
  //       setFormType(null);
  //     }
  //     setModalPending(false);
  //   },
  //   [branch, rootStore, formType, shift, setFormType, setFormVisible, setModalPending, setFormErrorText]
  // );

  const handleClickOpenShift = useCallback(() => {
    setFormErrorText(null);
    setIsOpeningShiftDialogOpen(true);
  }, [setIsOpeningShiftDialogOpen]);

  // Когда нажали на кнопку закрытия смены
  const handleClickCloseShift = useCallback(() => {
    setFormErrorText(null);
    setIsClosingShiftDialogOpen(true);
  }, [setIsClosingShiftDialogOpen]);

  const onClickExpenses = useCallback(() => {
    setFormErrorText(null);
    setIsAddingShiftExpenseDialogOpen(true);
  }, [setIsAddingShiftExpenseDialogOpen]);

  const dropFormErrorText = useCallback(() => {
    setFormErrorText(null);
  }, [setFormErrorText]);

  const formConfig = useMemo(() => {
    if (formType === "start") {
      return {};
    } else if (formType === "expenses") {
      return shiftExpensesFormConfig;
    } else if (formType === "finish-expenses") {
      return { ...shiftExpensesFormConfig, cancelText: "Skip" };
    }
  }, [formType, shiftExpensesFormConfig]);

  const showExpenses = useCallback(() => {
    setExpensesVisible(true);
  }, []);

  const hideExpenses = useCallback(() => {
    setExpensesVisible(false);
  }, []);

  const expensesButton = useMemo(() => {
    if (!shift) return null;
    if (!shift.expensesTotal) return null;

    return (
      <Tooltip
        arrow
        title={
          <Typography variant="body">
            <Translator text={"View shift expenses"} />
          </Typography>
        }
      >
        <Box sx={{ cursor: "pointer", mt: "0rem", pt: "0rem" }} onClick={showExpenses}>
          {shift && <ShiftExpenses shift={shift} isTiny={true} />}
        </Box>
      </Tooltip>
    );
  }, [showExpenses, shift]);

  const cardBackgroundColor = useMemo(() => {
    if (!shift) return theme.palette.grey["200"];
    return theme.palette.green.light;
  }, [shift, theme.palette]);

  if (isPending) return <Preloader />;

  return (
    <>
      <Card backgroundColor={cardBackgroundColor} sx={{ mt: 0 }}>
        <CardContent sx={{ m: 0, p: "0.5rem 0.5rem 0" }}>
          <Typography variant="h6">
            <Translator text={shift?.isOpen ? "Shift is open" : "Shift not open"} />
          </Typography>
          {shift?.isOpen && (
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              <Translator datetime={shift.startedAt} variant="compact" />
            </Typography>
          )}
          {expensesButton}
        </CardContent>
        <CardActions sx={{ justifyContent: "end", p: 0.75 }}>
          <OpenShiftButton shift={shift} onClick={handleClickOpenShift} />
          {shift?.isOpen && (
            <Button
              onClick={onClickExpenses}
              variant="contained"
              startIcon={<MoneyOffIcon />}
              disabled={branch.isPendingExpensesForFacility}
            >
              <Translator text="Add shift expense" />
            </Button>
          )}
          {shift?.isOpen && (
            <Button
              variant="contained"
              startIcon={<SportsScoreIcon />}
              color={"warning"}
              onClick={handleClickCloseShift}
              disabled={isScanningInProgress}
            >
              <Translator text="Close shift" />
            </Button>
          )}
        </CardActions>
        {/* <Dialog sx={{ minHeight: "5rem" }} open={formVisible} onClose={onCloseDialog}>
          {formVisible && formConfig && (
            <Form
              config={formConfig}
              isPending={isModalPending}
              alert={
                formErrorText && (
                  <Alert onClose={dropFormErrorText} severity="error">
                    <Translator text={formErrorText} />
                  </Alert>
                )
              }
              onSubmit={onSubmitForm}
              onCancel={onCloseForm}
            />
          )}
        </Dialog> */}
        <Dialog open={expensesVisible} onClose={hideExpenses}>
          <DialogTitle sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Translator text="Shift expenses" />
          </DialogTitle>
          <DialogContent>{shift && <ShiftExpenses shift={shift} />}</DialogContent>
          <DialogActions>
            <Button onClick={hideExpenses}>
              <Translator text="Close" />
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
      {!isPending && (
        <OpeningShiftDialog
          employeeId={authStore.userId}
          branch={branch}
          isOpen={isOpeningShiftDialogOpen}
          onClose={() => setIsOpeningShiftDialogOpen(false)}
        />
      )}
      {!isPending && (
        <ClosingShiftDialog
          shift={shift}
          isOpen={isClosingShiftDialogOpen}
          onClose={() => setIsClosingShiftDialogOpen(false)}
        />
      )}
      {!isPending && (
        <AddingShiftExpenseDialog
          shift={shift}
          isOpen={isAddingShiftExpenseDialogOpen}
          onClose={() => setIsAddingShiftExpenseDialogOpen(false)}
        />
      )}
    </>
  );
});

export default ShiftCard;
