import { computed, makeObservable, observable } from "mobx";

/**
 * Модель автомобиля как оборудования.
 */
class Car {
  @observable store;
  @observable id;
  @observable branches = [];
  @observable name;
  @observable vin;

  constructor({ id, name, vin }, branch) {
    makeObservable(this);

    this.id = id;
    // this.store = branch.store;
    this.name = name;
    this.vin = vin;
  }

  @computed get value() {
    return this.id;
  }

  @computed get label() {
    return `${this.name} (${this.vin})`;
  }
}

export default Car;
