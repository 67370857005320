import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react";
import useStores from "~/hooks";
import moment from "moment";
import Translator from "~/components/Translator";
import { ContractButton } from "..";

const ContractLink = observer(({ contractId, contractNumber }) => {
  const { contractStore } = useStores();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const handleClick = async () => {
    setLoading(true);
    setOpen(true);
    setError(null);

    try {
      await contractStore.getContractByIdd(contractId);
      // console.log("contract", contractStore.currentContract);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setError(<Translator text="You do not have permission to view this contract." />);
      } else {
        console.error("qwe", error);
        setError(<Translator text="An error occurred while loading the contract." />);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    contractStore.clearCurrentContract();
    setError(null);
  };

  const contractData = contractStore.currentContract;

  return (
    <>
      <Box onClick={handleClick} sx={{ cursor: "pointer" }}>
        <ContractButton number={contractNumber} />
      </Box>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" sx={{ zIndex: 1500 }}>
        <DialogTitle>
          <Translator text="Contract Details" />
        </DialogTitle>
        <DialogContent dividers>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              {error && <Typography color="error">{error}</Typography>}
              
              {/* metadata */}
              <Typography variant="h6"><Translator text="Contract Metadata" /></Typography>
              <Divider />
              <Typography><strong>ID:</strong> {contractData?.id}</Typography>
              <Typography><strong>Number:</strong> {contractData?.number}</Typography>
              <Typography><strong>Date:</strong> {contractData?.date ? moment(contractData.date).format("YYYY-MM-DD") : "N/A"}</Typography>
              <Typography><strong>Description:</strong> {contractData?.description}</Typography>
              <Typography><strong>Client:</strong> {contractData?.client?.name || "N/A"}</Typography>

              {/* facilities */}
              {contractData?.facilities?.map((facility, index) => (
                <React.Fragment key={index}>
                  <Typography variant="h6" sx={{ mt: 2 }}><Translator text={`Facility ${index + 1}: ${facility.name}`} /></Typography>
                  <Divider />
                  <Typography><strong>Area:</strong> {facility.area}</Typography>
                  <Typography><strong>Kind:</strong> {facility.kind}</Typography>
                  <Typography><strong>Description:</strong> {facility.description}</Typography>

                  {/* scanning  */}
                  {facility.scanningPlan && (
                    <>
                      <Typography variant="subtitle1" sx={{ mt: 1, fontWeight: 'bold' }}><Translator text="Scanning Information" /></Typography>
                      <Typography><strong>Employee ID:</strong> {facility.scanningPlan.employeeId}</Typography>
                      <Typography><strong>Facility ID:</strong> {facility.scanningPlan.facilityId}</Typography>
                      <Typography><strong>Start:</strong> {moment(facility.scanningPlan.start).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                      <Typography><strong>Finish:</strong> {moment(facility.scanningPlan.finish).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                      <Typography><strong>Status:</strong> {facility.scanningPlan.status}</Typography>
                      {facility.scanningPlan.finished && (
                        <Typography><strong>Finished At:</strong> {moment(facility.scanningPlan.finished.datetime).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                      )}
                    </>
                  )}

                  {/* modelling */}
                  {facility.modellingPlans?.length > 0 ? (
                    <>
                      <Typography variant="subtitle1" sx={{ mt: 1, fontWeight: 'bold' }}><Translator text="Modelling Information" /></Typography>
                      {facility.modellingPlans.map((plan, i) => (
                        <Typography key={i}><strong>Modelling Plan ID:</strong> {plan.id}</Typography>
                      ))}
                    </>
                  ) : (
                    <Typography>No Modelling Plans Available</Typography>
                  )}
                </React.Fragment>
              ))}

              {/* expenses */}
              <Typography variant="h6" sx={{ mt: 2 }}><Translator text="Expenses Information" /></Typography>
              <Divider />
              <Typography><strong>Expense Plan:</strong> {contractData?.expensesPlan?.amount ?? "N/A"} {contractData?.expensesPlan?.currency || ""}</Typography>
              {contractData?.expenses?.length > 0 ? (
                contractData.expenses.map((expense, index) => (
                  <Typography key={index}>
                    <strong>Expense ID:</strong> {expense.id}, Amount: {expense.amount}, Date: {expense.date ? moment(expense.date).format("YYYY-MM-DD") : "N/A"}
                  </Typography>
                ))
              ) : (
                <Typography>No Expenses Data Available</Typography>
              )}

              {/* invoices */}
              <Typography variant="h6" sx={{ mt: 2 }}><Translator text="Invoices and Payments" /></Typography>
              <Divider />
              {contractData?.invoices?.length > 0 ? (
                contractData.invoices.map((invoice, index) => (
                  <Typography key={index}>
                    <strong>Invoice ID:</strong> {invoice.id}, Amount: {invoice.amount}, Date: {invoice.date ? moment(invoice.date).format("YYYY-MM-DD") : "N/A"}
                  </Typography>
                ))
              ) : (
                <Typography>No Invoices Data Available</Typography>
              )}
              <Typography><strong>Payed Total:</strong> {contractData?.payedTotal?.amount ?? "N/A"} {contractData?.payedTotal?.currency || ""}</Typography>
              <Typography><strong>Value:</strong> {contractData?.value?.amount ?? "N/A"} {contractData?.value?.currency || ""}</Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}><Translator text="Close" /></Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default ContractLink;
