import React, { useCallback, useState } from "react";
import { observer } from "mobx-react";
import { Box, Button, Dialog, Tooltip } from "@mui/material";
import { Add } from "@mui/icons-material";

import useStores from "~/hooks";

import Translator from "../../components/Translator";
import Form from "../../components/Form";
import Preloader from "../../components/Preloader";
import moment from "moment";

const AddPayment = observer(({ invoice }) => {
  const { invoiceStore, rootStore } = useStores();
  const { id: invoiceId, amount, currency, contractNumber, clientName, createdAt } = invoice;

  const addPaymentFormConfig = {
    submitText: "Create",
    cancelText: "Cancel",
    formTitle: "Creating payment",
    fields: [
      {
        name: "amount",
        title: "Amount with VAT",
        type: "number",
        viewConfig: "input",
        inputMode: "decimal",
        min: 1,
        step: 0.01,
        startAdornment: currency,
        isRequired: true,
        isReadOnly: false,
        validate: true,
      },
      {
        name: "date",
        title: "Date",
        type: "date",
        disablePast: false,
        disableFuture: false,
        // maxDate: moment().add(2, "days"),
        // minDate: createdAt,
        isRequired: true,
        isReadOnly: false,
        validate: true,
      },
      {
        name: "isPayerIndividualPerson",
        title: "Payer is individual person",
        type: "boolean",
        isRequired: true,
        isReadOnly: false,
      },
      {
        name: "invoiceId",
        type: "hidden",
        viewConfig: "input",
        initialValue: invoiceId,
      },
      {
        name: "currency",
        type: "hidden",
        viewConfig: "input",
        initialValue: currency,
      },
      {
        name: "contractNumber",
        title: "Contract number",
        type: "string",
        viewConfig: "input",
        isReadOnly: true,
        initialValue: contractNumber,
      },
      {
        name: "clientName",
        title: "Client name",
        type: "string",
        viewConfig: "input",
        isReadOnly: true,
        initialValue: clientName,
      },
      {
        name: "totalAmount",
        title: "Amount",
        type: "number",
        viewConfig: "input",
        inputMode: "decimal",
        startAdornment: currency,
        isReadOnly: true,
        initialValue: amount,
      },
      {
        name: "invoiceDate",
        title: "Invoice date",
        type: "date",
        disablePast: false,
        disableFuture: false,
        isReadOnly: true,
        initialValue: createdAt,
      },
    ],
  };

  const [open, setOpen] = useState(false);
  const [isModalPending, setModalPending] = useState(false);

  const onSubmit = useCallback(
    async (data) => {
      try {
        setModalPending(true);
        data.date = moment(data.date, "L").format("YYYY-MM-DD");
        await invoiceStore.addPaymentAsync(data);
      } catch (error) {
        rootStore.setError("error", null, error && error.message);
      }
      setModalPending(false);
      setOpen(false);
    },
    [invoiceStore]
  );

  const onOpenDialogPayment = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const onCloseDialog = useCallback(
    (e, reason) => {
      reason !== "backdropClick" && setOpen(false);
    },
    [setOpen]
  );

  return (
    <>
      <Tooltip title={<Translator text={"Add payment"} />} arrow>
        {/* Нужна обёртка над кнопкой внутри тултипа иначе warning */}
        <Box>
          <Button
            key={"addInvoice"}
            variant="outlined"
            startIcon={<Add />}
            // sx={{ flex: 1 }}
            color="primary"
            onClick={onOpenDialogPayment}
          >
            <Translator text={"Payment"} />
          </Button>
        </Box>
      </Tooltip>
      <Dialog open={open} onClose={onCloseDialog}>
        {!isModalPending && <Form config={addPaymentFormConfig} onSubmit={onSubmit} onCancel={onCloseDialog} />}
        <Preloader isPending={isModalPending} />
      </Dialog>
    </>
  );
});

export default AddPayment;
