import React, { useState, useCallback } from "react";
import { observer } from "mobx-react";

import { Button, DialogContent, IconButton } from "@mui/material";
import { Dialog, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DepartureBoardIcon from "@mui/icons-material/DepartureBoard";

import { Translator as T } from "~/components";

import PublicTransportShiftDetails from "./PublicTransportShiftDetails";

/**
 * Откроет окно с информацией о машине в смене.
 */
const PublicTransportShiftButton = observer(({ shift }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Открыть диалог
  const handleOpen = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  // Закрыть диалог
  const handleClose = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  // Закроем диалог если нажали Escape
  const onDialogClose = useCallback(
    (event, reason) => {
      if (reason === "escapeKeyDown") handleClose();
    },
    [handleClose]
  );

  return (
    <>
      <Dialog open={isDialogOpen} maxWidth="xxl" fullWidth disableEscapeKeyDown={false} onClose={onDialogClose}>
        <DialogTitle sx={{ display: "flex", flexDirection: "row", alignItems: "end" }}>
          <DepartureBoardIcon fontSize="large" sx={{ marginRight: 1 }} />
          <T text={"Public transport"} />
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ minHeight: "80vh" }}>
          {shift && <PublicTransportShiftDetails shift={shift} />}
        </DialogContent>
      </Dialog>
      <Button onClick={handleOpen} variant="outlined" size="small" startIcon={<DepartureBoardIcon />}>
        <T text={"Public transport"} />
      </Button>
    </>
  );
});

export default PublicTransportShiftButton;
