import React, { useState, useCallback } from "react";
import { observer } from "mobx-react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Add } from "@mui/icons-material";

import { Translator, Form, Preloader } from "~/components";
import useStores from "~/hooks";
import moment from "moment";

/**
 * Контекстное меню инвойса.
 */
const InvoiceContextMenu = observer(({ isOpen, position, handleClose, selectedInvoice }) => {
  const { invoiceStore, rootStore } = useStores();
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [isModalPending, setModalPending] = useState(false);

  const [x, y] = position;
  const isVisible = !!x && !!y;

  const { id: invoiceId, amount, currency, contractNumber, clientName, createdAt } = selectedInvoice || {};

  const onSubmitPayment = useCallback(
    async (data) => {
      try {
        setModalPending(true);
        data.date = moment(data.date, "L").format("YYYY-MM-DD");
        await invoiceStore.addPaymentAsync(data);
      } catch (error) {
        rootStore.setError("error", null, error && error.message);
      }
      setModalPending(false);
      setOpenPaymentDialog(false);
    },
    [invoiceStore]
  );

  const addPaymentFormConfig = {
    submitText: "Create",
    cancelText: "Cancel",
    formTitle: "Creating payment",
    fields: [
      {
        name: "amount",
        title: "Amount with VAT",
        type: "number",
        viewConfig: "input",
        inputMode: "decimal",
        min: 1,
        step: 0.01,
        startAdornment: currency,
        isRequired: true,
        isReadOnly: false,
        validate: true,
      },
      {
        name: "date",
        title: "Date",
        type: "date",
        disablePast: false,
        disableFuture: false,
        isRequired: true,
        isReadOnly: false,
        validate: true,
      },
      {
        name: "isPayerIndividualPerson",
        title: "Payer is individual person",
        type: "boolean",
        isRequired: true,
        isReadOnly: false,
      },
      {
        name: "invoiceId",
        type: "hidden",
        viewConfig: "input",
        initialValue: invoiceId,
      },
      {
        name: "currency",
        type: "hidden",
        viewConfig: "input",
        initialValue: currency,
      },
      {
        name: "contractNumber",
        title: "Contract number",
        type: "string",
        viewConfig: "input",
        isReadOnly: true,
        initialValue: contractNumber,
      },
      {
        name: "clientName",
        title: "Client name",
        type: "string",
        viewConfig: "input",
        isReadOnly: true,
        initialValue: clientName,
      },
      {
        name: "totalAmount",
        title: "Amount",
        type: "number",
        viewConfig: "input",
        inputMode: "decimal",
        startAdornment: currency,
        isReadOnly: true,
        initialValue: amount,
      },
      {
        name: "invoiceDate",
        title: "Invoice date",
        type: "date",
        disablePast: false,
        disableFuture: false,
        isReadOnly: true,
        initialValue: createdAt,
      },
    ],
  };

  const handleOpenPaymentDialog = () => setOpenPaymentDialog(true);
  const handleClosePaymentDialog = () => setOpenPaymentDialog(false);

  const handleDownloadInvoice = useCallback(() => {
    invoiceStore.downloadInvoice(invoiceId);
  }, [invoiceId, invoiceStore]);

  return (
    <>
      <Menu
        open={isOpen}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={isVisible ? { top: y, left: x } : undefined}
        componentsProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault();
              handleClose();
            },
          },
        }}
      >
        <MenuItem onClick={handleOpenPaymentDialog}>
          <ListItemIcon>
            <Add fontSize="small" />
          </ListItemIcon>
          <Translator text="Add payment" />
        </MenuItem>

        <MenuItem onClick={handleDownloadInvoice}>
          <ListItemIcon>
            <CloudDownloadIcon fontSize="small" />
          </ListItemIcon>
          <Translator text="Download invoice" />
        </MenuItem>
      </Menu>

      <Dialog open={openPaymentDialog} onClose={handleClosePaymentDialog} maxWidth="sm" fullWidth>
        <DialogContent>
          {!isModalPending && (
            <Form config={addPaymentFormConfig} onSubmit={onSubmitPayment} onCancel={handleClosePaymentDialog} />
          )}
          <Preloader isPending={isModalPending} />
        </DialogContent>
      </Dialog>
    </>
  );
});

export default InvoiceContextMenu;
