import React, { useMemo, useCallback } from "react";
import { observer } from "mobx-react";

import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import Translator from "./Translator";
import useStores from "~/hooks";

/**
 * Lang switcher он же переключатель текущего языка.
 */
const LangSwitcher = observer(() => {
  const { languageStore, workspaceStore } = useStores();
  const { languagesArray, currentLangId, currentLangShortLocale } = languageStore;
  const { theme } = workspaceStore;

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const changeLang = useCallback(
    (event) => {
      languageStore.setLang(event.target.value);
    },
    [languageStore]
  );

  const languages = useMemo(() => {
    return languagesArray.map((lang) => {
      return (
        <MenuItem key={lang.id} value={lang.id}>
          {lang.flag} {!isMobile && lang.name}
        </MenuItem>
      );
    });
  }, [languagesArray, isMobile]);

  return (
    <FormControl
      sx={{
        m: 0,
        p: "0 0.25rem",
        position: "relative",
        maxHeight: "2.5rem",
      }}
    >
      <InputLabel id="select-native-label">
        <Translator text="Language" />
      </InputLabel>
      <Select
        sx={{
          maxHeight: "2.5rem",
        }}
        labelid={"select-native-label"}
        label={<Translator text="Language" />}
        value={currentLangId}
        onChange={changeLang}
      >
        {languages}
      </Select>
    </FormControl>
  );
});

export default LangSwitcher;
