import { computed, makeObservable, observable } from "mobx";

class Client {
  @observable store = null; // TODO: _store

  @observable id = null;
  @observable name = null;
  @observable description = null;
  @observable payed = null; // сколько клиент заплатил
  @observable debt = null; // сколько клиент должен

  /** Список id завершенных контрактов данного клиента */
  @observable closedContractIds = [];

  /** Список id контрактов в работе данного клиента */
  @observable processingContractIds = [];

  /** Список id отмененных контрактов клиента */
  @observable cancelledContractIds = [];

  stats = null;

  constructor(props, store) {
    makeObservable(this);

    this.store = store;
    this.id = `${props.id}`;
    this.name = props.name;
    this.description = props.description;

    // TODO: это метаданные клиентов, не сами клиенты. Унести в отдельные модели
    this.closedContractIds = props.contractIds?.closed || [];
    this.processingContractIds = props.contractIds?.processing || [];
    this.cancelledContractIds = props.contractIds?.cancelled || [];
    this.debt = props.stats?.debt || null;
    this.payed = props.stats?.payed || null;
  }

  @computed
  get label() {
    return this.name;
  }

  @computed
  get value() {
    return this.id;
  }

  @computed
  get closedContractsCount() {
    return this.closedContractIds.length;
  }

  @computed
  get cancelledContractsCount() {
    return this.cancelledContractIds.length;
  }

  @computed
  get processingContractsCount() {
    return this.processingContractIds.length;
  }
}

export default Client;
