  import React, { useState, useCallback } from "react";
  import { observer } from "mobx-react";
  import { Toolbar, Box } from "@mui/material";

  import SearchField from "../../components/SearchField";
  import Translator from "../../components/Translator";
  import { Module, DateRangePicker } from "../../components";

  import OffersGrid from "./OffersGrid";

  import useStores from "~/hooks";
  import AddCommercialOfferDialog from "./components/AddCommercialOfferDialog";
  import StatesSelect from "./components/StatesSelect";
  import { FAB } from "~/components/buttons";

  /**
   * Модуль работы с коммерческими предложениями.
   */
  const CommercialOffersModule = observer(() => {
    // Фильтры отображаемых данных модуля
    const [searchBy, setSearchBy] = useState("");
    const [daterange, setDaterange] = useState([null, null]);
    const [selectedStates, setSelectedStates] = useState([]);

    // диалоги
    const [isAddCommercialOfferDialogVisible, setIsAddCommercialOfferDialogVisible] = useState(false);

    const { commercialOffersStore } = useStores();
    const onSearch = useCallback((value) => {
      if (value.length > 2) {
        setSearchBy(value);
      } else {
        setSearchBy("");
      }
    }, []);

    const onAcceptDaterange = useCallback((value) => {
      setDaterange(value);
    }, []);

    const onSelectStates = useCallback((e) => {
      const {
        target: { value },
      } = e;
      setSelectedStates(value);
    }, []);

    const openAddOfferDialog = useCallback(() => {
      setIsAddCommercialOfferDialogVisible(true);
    }, []);

    return (
      <Module>
        {/* Сам поиск не сделал */}
        <Toolbar disableGutters={true} sx={{ mt: 0.5 }}>
          <Box sx={{ ml: 1, fontWeight: "bold", fontSize: "large", minWidth: 150 }}>
            <Translator
              number={commercialOffersStore.totalValue.amount || "0"}
              currency={commercialOffersStore.totalValue.currency}
            />
          </Box>
          <SearchField label={<Translator text={"Search commercial offers"} />} onSearch={onSearch} />
          <StatesSelect
            options={["created", "accepted", "rejected", "postponed"]}
            value={selectedStates}
            onSelect={onSelectStates}
          />
          <DateRangePicker onAccept={onAcceptDaterange} />
        </Toolbar>
        {/* Унести все параметры фильтрации в одни prop */}
        <OffersGrid
          store={commercialOffersStore}
          searchBy={searchBy}
          daterange={daterange}
          selectedStates={selectedStates}
        />
        <FAB onClick={openAddOfferDialog} />
        <AddCommercialOfferDialog
          isVisible={isAddCommercialOfferDialogVisible}
          setIsVisible={setIsAddCommercialOfferDialogVisible}
        />
      </Module>
    );
  });

  export default CommercialOffersModule;
