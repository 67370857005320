import { observable, makeObservable } from "mobx";

class Module {
  @observable slug;
  @observable requiredPermission = "";

  constructor(slug, permission) {
    makeObservable(this);
    this.slug = slug;
    this.requiredPermission = permission;
  }
}

export default Module;
