import { action, computed, makeObservable, observable } from "mobx";
import moment from "moment";

import File from "./File";

class Document {
  @observable id = 0;
  @observable author = null;
  @observable createdAt = null;
  @observable file = null;

  constructor(props, store) {
    makeObservable(this);
    this.store = store;
    const { file, id, author, createdAt } = props;
    this.id = id;
    this.file = file; // экземпляр модели файла
    this.author = author; // экземпляр модели пользователя
    this.createdAt = createdAt;
  }

  /**
   * Creates new Document instance from JSON data.
   *
   *   props = {
   *      "id": x.id,
   *      "authorId": x.author_id,
   *      "createdAt": x.created_at.isoformat(),
   *      "file": {
   *          "name": x.file.name,
   *          "size": x.file.size,
   *          "mimetype": x.file_mimetype,
   *      },
   *  }
   */
  static create(props, store) {
    // Идентификатор
    const { id } = props;

    // Автор
    const { authorId } = props;
    const author = store.root.employeesStore.getEmployeeById(authorId);

    // Дата создания документа
    const { createdAt } = props;
    const created = moment(createdAt, "YYYY-MM-DD");

    // Файл
    const { file } = props;
    const fileInstance = File.create(file);

    return new Document({ store, id, createdAt: created, author, file: fileInstance });
  }
}

export default Document;
