import React from "react";

import { Button } from "@mui/material";
import { Start } from "@mui/icons-material";
import Translator from "../../../../components/Translator";

/**
 * Кнопка отрытия новой смены.
 *
 * Отображается только если смена не начата.
 */
export default function OpenShiftButton({ shift, onClick }) {
  if (shift?.startedAt) return;
  return (
    <Button variant="contained" sx={{ m: "0.125rem" }} startIcon={<Start />} onClick={onClick}>
      <Translator text="Open shift" />
    </Button>
  );
}
