/**
 * Интерактивный контрол скрывающий за собой список объектов.
 */

import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { Box, Badge, Button, ButtonGroup } from "@mui/material";

import FacilityButton from "./FacilityButton";

const FacilitiesButton = observer(({ facilities }) => {
  const count = facilities.length;

  if (!count) {
    return;
  }

  return (
    <Badge
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      invisible={count < 2}
      badgeContent={count}
      color="secondary"
    >
      <ButtonGroup variant="outlined">
        {facilities.map((f) => (
          <FacilityButton variant={count > 1 ? "compact" : ""} key={f.id} facility={f} />
        ))}
      </ButtonGroup>
    </Badge>
  );
});

export default FacilitiesButton;
