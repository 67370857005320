import React, { useLayoutEffect } from "react";
import { observer } from "mobx-react";
import { Box, ButtonGroup, Button } from "@mui/material";
import { DataGrid } from "~/components/grid";

import useStores from "~/hooks";

import Translator from "../components/Translator";

const Employees = observer(() => {
  const { employeesStore, branchStore } = useStores();
  const { currentBranchId } = branchStore;
  const { employeesArray, isPending } = employeesStore;

  useLayoutEffect(() => {
    employeesStore.fetchEmployees(currentBranchId);
  }, [currentBranchId, employeesStore]);

  const columns = [
    { field: "id", headerName: "ID", type: "number", width: 90 },
    {
      field: "lastname",
      headerName: <Translator text="Lastname" />,
      width: 200,
    },
    {
      field: "firstname",
      headerName: <Translator text="Firstname" />,
      width: 200,
    },
    {
      field: "email",
      headerName: <Translator text="Email" />,
      width: 250,
    },
    {
      field: "roles",
      headerName: <Translator text="Roles" />,
      width: 300,
      renderCell: (params) => {
        return (
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            {params.row.roles.map((role) => {
              return (
                <Button key={role} disabled>
                  <Translator text={role} />
                </Button>
              );
            })}
          </ButtonGroup>
        );
      },
    },
  ];

  return (
    <Box
      sx={{
        flexDirection: "column",
        display: "flex",
        flex: 1,
        padding: 1,
      }}
    >
      <DataGrid
        rows={employeesArray}
        columns={columns}
        loading={isPending}
      />
    </Box>
  );
});

export default Employees;
