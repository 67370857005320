import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { Box, Typography } from "@mui/material";
import GantLinePLan from "./GantLinePlan";

const GantLine = observer(({ startOfPeriod, endOfPeriod, modeller }) => {
  const { label } = modeller;

  const days = useMemo(() => {
    const days = [];
    const start = startOfPeriod.clone().startOf("day");
    const end = endOfPeriod.clone().endOf("day");
    const cursor = start.clone().startOf("day").set("hour", 12);
    while (cursor <= end) {
      days.push(
        <Box
          key={cursor.clone().format("DD-MM")}
          sx={{
            flex: 1,
            flexShrink: 0,
            borderRight: (theme) => {
              return `0.0625rem solid ${theme.palette.grey[300]}`;
            },
          }}
        ></Box>
      );
      cursor.add(1, "days");
    }
    return days;
  }, [startOfPeriod, endOfPeriod]);

  const plans = useMemo(() => {
    const plans = [];
    if (modeller.modellingPlanFacilities) {
      modeller.modellingPlanFacilities.forEach((facility) => {
        facility.modellingPlans.forEach((plan) => {
          if (plan.start <= endOfPeriod && plan.finish > startOfPeriod) {
            plans.push(
              <GantLinePLan startOfPeriod={startOfPeriod} endOfPeriod={endOfPeriod} plan={plan} facility={facility} />
            );
          }
        });
      });
    }
    return plans;
  }, [modeller.modellingPlanFacilities, startOfPeriod, endOfPeriod]);

  return (
    <Box
      sx={{
        flexDirection: "row",
        display: "flex",
        flexShrink: 0,
        minHeight: "2rem",
        borderBottom: (theme) => {
          return `0.0625rem solid ${theme.palette.grey[300]}`;
        },
      }}
    >
      <Box
        sx={{
          flexDirection: "column",
          display: "flex",
          flexShrink: 0,
          p: "0.125rem 0.75rem",
          justifyContent: "center",
          width: "15rem",
          borderRight: (theme) => {
            return `0.0625rem solid ${theme.palette.grey[300]}`;
          },
          borderLeft: (theme) => {
            return `0.0625rem solid ${theme.palette.grey[300]}`;
          },
        }}
      >
        <Typography variant="body" sx={{ fontWeight: 600 }}>
          {label}
        </Typography>
      </Box>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flex: 1,
          flexDirection: "row",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
            display: "flex",
            flexDirection: "row",
            zIndex: 1,
          }}
        >
          {days}
        </Box>
        <Box
          sx={{
            flex: 1,
            zIndex: 2,
            overflow: "hidden",
          }}
        >
          {plans}
        </Box>
      </Box>
    </Box>
  );
});

export default GantLine;
