import { ApiBase } from "~/api";

class InvoicesApiV0 extends ApiBase {
  /**
   * Запросить возможнные методы оплаты филиала.
   *
   * @param {integer} branchId идентификатор филиала.
   */
  async getPaymentMethods(branchId) {
    const url = `api/v0/branches/${branchId}/payments/methods`;
    const [data] = await this.get(url);
    return data;
  }

  /**
   * Создать новый счёт к конракту.
   */
  async createInvoice(contractId, payload) {
    const url = `api/v0/contracts/${contractId}/payments/invoices`;
    const [data] = await this.post(url, payload);
    return data;
  }

  /**
   * Запрос инвойсов постранично.
   */
  async getInvoices(branchId, searchBy, pagination, states) {
    const { page, pageSize } = pagination;
    const backendPagination = { page: page + 1, perPage: pageSize };
    const parameters = { ...backendPagination, searchBy, states };
    const url = `/api/v0/branches/${branchId}/payments/invoices`;
    const [data, headers] = await this.get(url, parameters);
    return [data, Number.parseInt(headers.get("Total-Count"))];
  }
}

export default InvoicesApiV0;
