import { makeObservable, observable } from "mobx";

import moment from "moment";
import { GeoPoint } from "~/common/models";

/**
 * Метаданные завершения сканирования объекта.
 *
 * {
 *   "id": 33,
 *   "datetime": "2023-09-29T17:35:42.487000+00:00",
 *   "point": [
 *       54.8833547,
 *       38.075246
 *   ],
 *   "stationsCount": 30,
 *   "mileage": 126
 * }
 *
 */
export default class ScanningFinished {
  // данные
  @observable id;
  @observable datetime = null;
  @observable geoPoint = null;
  @observable stationsCount = 0; // количество станций при завершении объекта
  @observable mileage = 0; // показания одометра при завершении объекта

  // связанные
  @observable plan = null;

  constructor(plan, props) {
    makeObservable(this);

    const { id, datetime, point, stationsCount, mileage } = props;

    this.id = id;
    this.datetime = moment(datetime);
    this.geoPoint = new GeoPoint(point[0], point[1]);
    this.stationsCount = stationsCount;
    this.mileage = mileage;

    this.plan = plan;
  }
}
