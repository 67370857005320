import React, { useCallback, useMemo, useState } from "react";

import { Dialog, DialogTitle } from "@mui/material";

import { Notify } from "~/common/components";
import Translator from "~/components/Translator";
import Form from "~/components/Form";

/**
 * Диалог добавления счёта в договор.
 */
const CreateInvoiceDialog = ({ contract, isOpen, onClose }) => {
  const [isNotifyOpen, setIsNotifyOpen] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const { branchStore, invoiceStore } = contract.store.root;
  const [created, setCreated] = useState(false);

  // закрыть диалог
  const handleClose = useCallback(
    (e, reason) => {
      if (reason === "backdropClick") return;
      !!onClose && onClose();
    },
    [onClose]
  );

  // TODO: здесь нужно учесть isPending стора
  // отправить форму
  const handleSubmit = useCallback(
    async (data) => {
      setIsPending(true);
      const created = await invoiceStore.createInvoice(contract, data);
      setCreated(created);
      setIsNotifyOpen(true);
      setIsPending(false);
      !!onClose && onClose();
    },
    [contract, invoiceStore, onClose]
  );

  // Ошибки отправки формы
  const formErrors = [];

  const config = useMemo(() => {
    return {
      fields: [
        {
          name: "number",
          title: "Number",
          type: "string",
          isRequired: true,
          validate: true,
        },
        {
          name: "currency",
          type: "hidden",
          viewConfig: "input",
          initialValue: branchStore.branch && branchStore.branch.currency,
        },
        {
          name: "methodId",
          fakeName: "method",
          title: "Payment method",
          type: "select",
          canCreate: true,
          isRequired: true,
          isReadOnly: false,
          validate: true,
          loading: invoiceStore.isPending,
          options: invoiceStore.paymentMethods,
        },
        {
          name: "amountWOVAT",
          title: "Amount without VAT",
          type: "number",
          viewConfig: "input",
          inputMode: "decimal",
          min: 1,
          step: 0.01,
          startAdornment: branchStore.branch && branchStore.branch.currency,
          isRequired: true,
          isReadOnly: false,
          validate: true,
        },
        {
          name: "VAT",
          title: "VAT",
          type: "number",
          viewConfig: "input",
          inputMode: "decimal",
          min: 1,
          step: 0.01,
          startAdornment: "%",
          isReadOnly: true,
          initialValue: "0",
          countFunction: (data) => {
            if (!data.methodId) return "";
            const method = invoiceStore.getPaymentMethod(data.methodId);
            return method.vat;
          },
        },
        {
          name: "amountCounter",
          title: "Amount with VAT",
          type: "number",
          viewConfig: "input",
          inputMode: "decimal",
          min: 1,
          step: 0.01,
          startAdornment: branchStore.branch && branchStore.branch.currency,
          isReadOnly: true,
          isRequired: true,
          initialValue: "0",
          countFunction: (data) => {
            if (!(data.methodId && data.amountWOVAT)) {
              return 0;
            }
            const method = invoiceStore.getPaymentMethod(data.methodId);
            const vat = method ? method.vat : 0;
            const amountNumber = Number(data.amountWOVAT);
            const vatCount = (amountNumber * vat) / 100;
            return (amountNumber + vatCount).toFixed(2);
          },
        },
        {
          name: "amount",
          type: "hidden",
          viewConfig: "input",
          initialValue: "0",
          countFunction: (data) => {
            if (!(data.methodId && data.amountWOVAT)) {
              return 0;
            }
            const method = invoiceStore.getPaymentMethod(data.methodId);
            const vat = method ? method.vat : 0;
            const amountNumber = Number(data.amountWOVAT);
            const vatCount = (amountNumber * vat) / 100;
            return (amountNumber + vatCount).toFixed(2);
          },
        },
      ],
      submitText: "Create",
      cancelText: "Cancel",
    };
  }, [branchStore.branch, invoiceStore]);

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} disableEscapeKeyDown>
        <DialogTitle>
          <Translator text={"Creating invoice for"} after={contract.number} />
        </DialogTitle>
        <Form
          isPending={isPending}
          errors={formErrors}
          config={config}
          onSubmit={handleSubmit}
          onCancel={handleClose}
        />
      </Dialog>
      <Notify isOpen={isNotifyOpen} onClose={() => setIsNotifyOpen(false)}>
        <Translator text={"Invoice created"} after={created.number} />
      </Notify>
    </>
  );
};

export default CreateInvoiceDialog;
