import { makeObservable, observable, computed } from "mobx";

class Money {
  @observable currency = null;
  @observable amount = null;

  constructor({ amount, currency }) {
    this.amount = Number.parseFloat(amount);
    this.currency = currency;

    makeObservable(this);
  }

  @computed get asJson() {
    return { amount: this.amount, currency: this.currency };
  }
}

export default Money;
