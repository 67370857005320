import { observable } from "mobx";

import moment from "moment";
import Money from "./Money";
import Document from "./Document";

class SalaryExpense {
  @observable id;

  @observable branch;
  @observable store;

  constructor(data, branch, store) {
    const { id, date, value, documents, description } = data;

    this.id = id;
    this.value = new Money({ amount: value.amount, currency: value.currency });
    this.date = moment(date);
    this.description = description;
    this.documents = documents.map((d) => new Document(d));


    this.branch = branch;
    this.store = store;
  }
}

export default SalaryExpense;