import React, { useCallback } from "react";

import Form from "../../../../components/Form";

/**
 *  Форма завершения сканирования.
 */
const FinishScanForm = (props) => {
  const { askOdometerReadings } = props;

  const fields = [
    {
      name: "stationsCount",
      title: "Stations count",
      type: "number",
      viewConfig: "input",
      isRequired: true,
      isReadOnly: false,
      validate: true,
      min: 1,
      step: 1,
    },
  ];

  // Спросить ли про одометр
  if (askOdometerReadings) {
    fields.push({
      name: "mileage",
      title: "Odometer reading",
      type: "number",
      viewConfig: "input",
      isRequired: true,
      isReadOnly: false,
      validate: true,
      initialValue: "payload",
      min: 0,
      step: 1,
    });
  }

  const config = {
    fields,
    submitText: "Finish",
    cancelText: "Continue",
    formTitle: "Finish scanning",
  };

  // отправить форму
  const onSubmit = useCallback(
    (values) => {
      props.onSubmit && props.onSubmit(values);
    },
    [props]
  );

  // закрыть форму без отправки
  const onClose = useCallback(() => {
    props.onClose && props.onClose();
  }, [props]);

  return (
    <Form
      config={config}
      onSubmit={onSubmit}
      onCancel={onClose}
      initialValues={{ stationsCount: 0, mileage: 0 }}
      errors={{ mileage: props.errors["detail"] }}
    />
  );
};

export default FinishScanForm;
