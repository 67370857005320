import React, { useMemo } from "react";
import { observer } from "mobx-react";

import { Box } from "@mui/material";

import Translator from "../../components/Translator";

/**
 * Сетка расписания.
 */
const ScheduleScale = observer(({ start, end, workHours }) => {
  const days = useMemo(() => {
    const realEnd = end.clone().startOf("day").set("hour", workHours.to);
    const cursor = start.clone().startOf("day").set("hour", workHours.from);

    const days = [];
    let hours = [];
    while (cursor <= realEnd) {
      const isFirstDay = !days.length;
      const isFirst = isFirstDay && !hours.length;
      hours.push(
        <Box
          key={`${cursor.format("HH:mm")}`}
          sx={{
            height: "2rem",
            border: "0.0625rem solid rgba(192,192,192,0.6)",
            borderBottom: "none",
            borderTop: isFirst ? "none" : null,
            p: "0.25rem",
            display: "flex",
            boxSizing: "borderBox",
            flexShrink: 0,
            fontSize: "0.625rem",
            alignItems: "flex-start",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          {cursor.format("HH:mm")}
        </Box>
      );
      cursor.add(1, "hour");
      if (cursor.hour() > workHours.to) {
        days.push(
          <Box key={`${cursor.toISOString()}`} sx={{ flexShrink: 0, display: "flex", flexDirection: "row" }}>
            <Box
              sx={{
                borderTop: !isFirstDay ? "0.0625rem solid rgba(192,192,192,0.6)" : null,
                flexShrink: 0,
                display: "flex",
                width: "2rem",
                flexDirection: "column",
                pb: "0.5rem",
                alignItems: "flex-end",
                fontSize: "1.25rem",
                fontWeight: "bold",
                writingMode: "vertical-lr",
                textOrientation: "mixed",
                transform: "rotate(-180deg)",
              }}
            >
              <Translator date={cursor.clone()} format={"DD.MM"} />
            </Box>
            <Box sx={{ flexShrink: 0, display: "flex", flexDirection: "column" }}>{[...hours]}</Box>
          </Box>
        );
        hours = [];
        cursor.set("hour", workHours.from).add(1, "day");
      }
    }
    return days;
  }, [start, end, workHours]);

  return (
    <Box
      sx={{
        flexDirection: "column",
        flexShrink: 0,
        borderBottom: "0.0625rem solid rgba(192,192,192,0.6)",
      }}
    >
      <Box
        sx={{
          height: "2rem",
          border: "0.0625rem solid rgba(192,192,192,0.6)",
          borderTop: "none",
          p: "1.25rem",
          display: "flex",
          flexShrink: 0,
          alignItems: "center",
          justifyContent: "center",
        }}
      ></Box>
      {days}
    </Box>
  );
});

export default ScheduleScale;
