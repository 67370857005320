import { makeObservable, observable } from "mobx";

/**
 * Точка геолокации.
 */
export default class GeoPoint {
  @observable lat = 0.0; // широта = latitude
  @observable lon = 0.0; // долгота = longitude

  constructor(lat, lon) {
    makeObservable(this);
    this.lat = lat;
    this.lon = lon;
  }
}
