import { computed, makeObservable, observable } from "mobx";
import moment from "moment";
import ReportColumn from "./ReportColumn";

class ReportMeta {
  @observable
  store = null;
  @observable
  name = null;
  @observable
  id = null;
  @observable
  value = null;

  constructor(props, store) {
    makeObservable(this);

    this.store = store;
    this.id = props.id;
    this.name = props.name;
    this.value = props.value;

    this.column = new ReportColumn(props, this.store, 0);
  }

  @computed
  get output() {
    let value = this.value;
    if (this.column.fieldType === "date") {
      value = moment(this.value).utcOffset(0);
    }

    return {
      value,
      type: this.column.fieldType,
      name: this.name,
    };
  }
}

export default ReportMeta;
