import React, { useState, useCallback } from "react";
import { observer } from "mobx-react";

import { Button, Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ScannerIcon from "@mui/icons-material/Scanner";

import ScannerShiftDetails from "./ScannerShiftDetails";

/**
 * Кнопка доступа к карточке информафии о сканере в рабочей смене.
 */
const ScannerShiftButton = observer(({ scanner, shift }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Открыть диалог
  const handleOpen = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  // Закрыть диалог
  const handleClose = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  // Закроем диалог если нажали Escape
  const onDialogClose = useCallback(
    (event, reason) => {
      if (reason === "escapeKeyDown") handleClose();
    },
    [handleClose]
  );

  return (
    <>
      {/* Диалог который открывает кнопка */}
      <Dialog open={isDialogOpen} maxWidth="xxl" fullWidth disableEscapeKeyDown={false} onClose={onDialogClose}>
        <DialogTitle sx={{ display: "flex", flexDirection: "row", alignItems: "end" }}>
          <ScannerIcon fontSize="large" sx={{ marginRight: 1 }} />
          {scanner.label}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ minHeight: "80vh" }}>{shift && <ScannerShiftDetails shift={shift} />}</DialogContent>
      </Dialog>
      {/* Сама кнопка */}
      <Button onClick={handleOpen} variant="outlined" size="small" startIcon={<ScannerIcon />}>
        {scanner.label}
      </Button>
    </>
  );
});

export default ScannerShiftButton;
