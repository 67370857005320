/**
 * Строка "умного поиска."
 *
 * Использует debounce технику через хук из
 * https://usehooks-ts.com/react-hook/use-debounce
 * чтобы отложить вызов обработчика изменения значения.
 *
 * Делать strip значения.
 */

import { Box, TextField } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useCallback, useEffect, useState } from "react";
import { useDebounce } from "usehooks-ts";

const SearchField = ({ label, onSearch }) => {
  const [value, setValue] = useState("");
  const debouncedValue = useDebounce(value, 500);

  const onChange = useCallback((event) => {
    setValue(event.target.value);
  }, []);

  useEffect(() => {
    if (!!onSearch) onSearch(debouncedValue.trim());
  }, [onSearch, debouncedValue]);

  return (
    <Box sx={{ padding:0, margin:0, display: "flex", flex: 1, flexDirection: "row", alignItems: "center", minWidth: "40rem" }}>
      <SearchOutlinedIcon color="info" fontSize="large" sx={{ marginRight: 1 }} />
      <TextField label={label} fullWidth={true} onChange={onChange} />
    </Box>
  );
};

export default SearchField;
