import React, { useState, useCallback } from "react";
import { observer } from "mobx-react";

import {
  Box,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";
import Translator from "../../../components/Translator";

import ContractExpenses from "./ContractExpenses";

/**
 * Виджет расходов для карточки контракта.
 */
const ExpensesWidget = observer(({ contract, showAddContractExpenseDialog }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = useCallback(() => setIsModalVisible(true), []);
  const hideModal = useCallback(() => setIsModalVisible(false), []);

  // Диалог который откроется по клику на виджет
  const ExpensesInfoDialog = styled(Dialog)({ ".MuiPaper-root": { maxWidth: "60vw" } });

  const canAddExpenses = true;
  return (
    <Box sx={{ flex: 1, flexShrink: 0, mt: 0, mb: 0, mr: 1, ml: 1 }}>
      <Card
        sx={{
          p: "0 0 0 0.5rem",
          m: "0.5rem 0 0 0",
          display: "flex",
          overflow: "visible",
          flex: 1,
          height: "5rem",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "center",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <ContractExpenses onClick={showModal} contract={contract} isTiny={true} />
          <Tooltip arrow title={<Translator text={"Add contract expense"} />}>
            {/* Нужна обрёртка над кнопкой внутри тултипа */}
            <Box>
              <IconButton
                key={"addExpenses"}
                variant="contained"
                color="info"
                disabled={!canAddExpenses}
                onClick={showAddContractExpenseDialog}
              >
                <MoneyOffIcon fontSize="large" />
              </IconButton>
            </Box>
          </Tooltip>
        </Box>
      </Card>

      {/* Диалог просмотра полной диаграммы расходов. */}
      <ExpensesInfoDialog open={isModalVisible} onClose={hideModal}>
        <DialogTitle sx={{ display: "flex", dlexDirection: "row", justifyContent: "space-between" }}>
          <Translator text="Contract expenses" />
          <IconButton onClick={hideModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ContractExpenses contract={contract} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="info"
            disabled={!canAddExpenses}
            onClick={showAddContractExpenseDialog}
            startIcon={<MoneyOffIcon />}
          >
            <Translator text={"Add contract expense"} />
          </Button>
        </DialogActions>
      </ExpensesInfoDialog>
    </Box>
  );
});

export default ExpensesWidget;
