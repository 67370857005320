import React from "react";

import { useTheme } from "@emotion/react";

import { Zoom, Fab } from "@mui/material";
import { Add } from "@mui/icons-material";

/**
 * Floating Action Button.
 */
const FAB = ({ onClick }) => {

  const theme = useTheme();
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  return <Zoom
    in={true}
    timeout={transitionDuration}
    style={{
      transitionDelay: `${transitionDuration.exit}ms`,
    }}
    unmountOnExit
  >
    <Fab
      onClick={onClick}
      key="fab"
      sx={{
        position: "fixed",
        bottom: 80,
        right: 32,
      }}
      color="primary"
      aria-label="Add"
    >
      <Add />
    </Fab>
  </Zoom>;
};


export default FAB;