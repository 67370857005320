/**
 * Контекстное меню смены.
 *
 * @param {*} param0
 * @returns
 */

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import MoneyOffCsredIcon from "@mui/icons-material/MoneyOffCsred";

/**
 * Контекстное меню рабочей смены сканироващика.
 */
const ScannerShiftContextMenu = ({ isOpen, position, handleClose, downloadTripCertificate, addShiftExpense }) => {
  const [x, y] = position;
  const isVisible = !!x && !!y;
  return (
    <Menu
      // open={isVisible}
      open={isOpen}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={isVisible ? { top: y, left: x } : undefined}
      componentsProps={{
        root: {
          onContextMenu: (e) => {
            e.preventDefault();
            handleClose();
          },
        },
      }}
    >
      <MenuItem onClick={addShiftExpense}>
        <ListItemIcon>
          <MoneyOffCsredIcon fontSize="small" />
        </ListItemIcon>
        Add shift expense …
      </MenuItem>
      <MenuItem onClick={downloadTripCertificate}>
        <ListItemIcon>
          <CloudDownloadIcon fontSize="small" />
        </ListItemIcon>
        Download trip certificate
      </MenuItem>
    </Menu>
  );
};

export default ScannerShiftContextMenu;
