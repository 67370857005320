import React from "react";

import { Box } from "@mui/material";

/**
 *  Общая обертка над любыми модулем.
 */
export default function Module(props) {
  return (
    <Box
      sx={{
        flexDirection: "column",
        display: "flex",
        flex: 1,
        padding: 1,
        flexWrap: "nowrap",
        overflow: "auto",
      }}
    >
      {props.children}
    </Box>
  );
}
