import React, { useMemo } from "react";
import { observer } from "mobx-react";
import {
  Box,
  Card,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useTheme,
} from "@mui/material";
import { ResponsivePie } from "@nivo/pie";

import Translator from "../../components/Translator";
import { Work } from "@mui/icons-material";
import ExpenseListItem from "../../components/ExpenseListItem";

const ShiftExpenses = observer(({ shift, isTiny }) => {
  const theme = useTheme();

  const { pieData, expensesArray, expensesTotal, currency } = shift;

  const CenteredMetric = ({ centerX, centerY }) => {
    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: "1.5rem",
          fontWeight: 600,
          fill: theme.palette.text.primary,
        }}
      >
        {<Translator number={expensesTotal} currency={currency} />}
      </text>
    );
  };

  const items = useMemo(() => {
    const items = [];
    expensesArray.forEach((item) => {
      items.push(
        <ExpenseListItem
          key={`${item.id}`}
          icon={<Work sx={{ mr: "0.25rem" }} color="success" />}
          item={item}
        />
      );
    });
    return items;
  }, [expensesArray]);

  if (isTiny) {
    return (
      <ListItem dense={true} sx={{ p: 0 }}>
        <ListItemAvatar>
          <Box sx={{ width: "3rem", height: "3rem" }}>
            <ResponsivePie
              data={pieData}
              innerRadius={0.4}
              padAngle={0}
              cornerRadius={0}
              tooltip={({ datum }) => {
                return (
                  <Card>
                    <ListItem>
                      <ListItemText
                        primary={datum.label}
                        secondary={
                          <Translator
                            number={datum.value}
                            currency={currency}
                          />
                        }
                      />
                    </ListItem>
                  </Card>
                );
              }}
              layers={["arcs"]}
            />
          </Box>
        </ListItemAvatar>
        <ListItemText
          primary={<Translator number={expensesTotal} currency={currency} />}
          secondary={<Translator text="Total shift expenses" />}
        />
      </ListItem>
    );
  }

  return (
    <Box>
      <Box sx={{ height: "30rem", position: "relative", margin: "0 auto" }}>
        <ResponsivePie
          data={pieData}
          margin={{ top: 10, right: 10, bottom: 100, left: 10 }}
          innerRadius={0.6}
          padAngle={2}
          cornerRadius={5}
          activeOuterRadiusOffset={8}
          arcLabel={(data) => {
            return <Translator number={data.value} currency={currency} />;
          }}
          arcLinkLabel={(data) => {
            return <Translator text={data.id} />;
          }}
          tooltip={({ datum }) => {
            return (
              <Card>
                <ListItem>
                  <ListItemText
                    primary={datum.label}
                    secondary={
                      <Translator number={datum.value} currency={currency} />
                    }
                  />
                </ListItem>
              </Card>
            );
          }}
          layers={["arcs", "arcLabels", "legends", CenteredMetric]}
          arcLabelsTextColor={theme.palette.text.primary}
          arcLinkLabelsTextColor={theme.palette.text.primary}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: 56,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: "#999",
              itemDirection: "left-to-right",
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#000",
                  },
                },
              ],
            },
          ]}
        />
      </Box>
      <Divider />
      <List dense={true}>{items}</List>
    </Box>
  );
});

export default ShiftExpenses;
