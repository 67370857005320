import React, { Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { Box, Button, Dialog, Typography, Tabs, Tab, Tooltip, Fab } from "@mui/material";
import { DataGrid } from "~/components/grid";

import useStores from "~/hooks";

import Translator from "../components/Translator";
import Form from "../components/Form";
import Preloader from "../components/Preloader";
import moment from "moment";
import { Download, FilterList } from "@mui/icons-material";
import ReportMeta from "../modules/reports/ReportMeta";

/**
 * Отчёт архитектора.
 */
const ArchitectReport = observer(() => {
  const { employeesStore, branchStore, architectReportStore } = useStores();
  const { branch } = branchStore;
  const { isPending: isReportPending, report } = architectReportStore;
  const { modellingManagersArray, isPending } = employeesStore;

  useLayoutEffect(() => {
    if (!branch) return;
    employeesStore.fetchEmployees(branch.id);
  }, [branch, employeesStore]);

  const [open, setOpen] = useState(false);

  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [architectId, setArchitectId] = useState();

  const onOpenDialog = useCallback(() => {
    setOpen(true);
  }, []);

  const onCloseDialog = useCallback(
    (e, reason) => {
      reason !== "backdropClick" && setOpen(false);
    },
    [setOpen]
  );

  useEffect(() => {
    if (!branch) return;
    if (!(architectId && from && to)) return;
    architectReportStore.getReport(branch.id, architectId, from, to);
    onCloseDialog();
  }, [branch, architectId, from, to, architectReportStore, onCloseDialog]);

  const onDownloadExcel = useCallback(() => {
    if (!branch) return;
    architectReportStore.downloadReport(branch.id, architectId, from, to);
  }, [branch, architectId, from, to, architectReportStore]);

  const onSubmit = useCallback((data) => {
    setArchitectId(data.architectId);
    setFrom(moment(data.from, "L").utc(true));
    setTo(moment(data.to, "L").utc(true));
  }, []);

  const config = useMemo(() => {
    const fields = [];
    fields.push({
      name: "architectId",
      fakeName: "architect",
      title: "Architect",
      type: "select",
      canCreate: false,
      isRequired: true,
      isReadOnly: false,
      validate: true,
      loading: employeesStore.isPending,
      options: modellingManagersArray,
      initialValue: architectId,
    });

    fields.push({
      name: "from",
      title: "From",
      type: "date",
      isRequired: true,
      isReadOnly: false,
      validate: true,
      initialValue: (from && from.toDate()) || moment().startOf("month"),
    });
    fields.push({
      name: "to",
      title: "To",
      type: "date",
      isRequired: true,
      isReadOnly: false,
      validate: true,
      initialValue: (to && to.toDate()) || moment().endOf("month"),
    });

    return {
      formTitle: "Date range",
      formText: "Select date range to view the report data.",
      cancelText: "Hide",
      submitText: "Get report",
      fields,
    };
  }, [from, to, employeesStore.isPending, modellingManagersArray, architectId]);

  const meta = useMemo(() => {
    if (isReportPending || isPending || !report) {
      return null;
    }

    const metaArray = [];
    report.meta.forEach((metaItem) => {
      metaArray.push(<ReportMeta key={metaItem.name} data={metaItem} />);
    });

    return (
      <Box
        sx={{
          flexDirection: "column",
          display: "flex",
          padding: 1,
        }}
      >
        {report.name && (
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Typography variant="h4" sx={{ flexGrow: 1 }}>
              <Translator text={report.name} />
            </Typography>
            <Tooltip title={<Translator text={"Download"} />}>
              <Button
                fullWidth={true}
                variant="outlined"
                startIcon={<Download />}
                sx={{ maxWidth: "8rem" }}
                color="primary"
                onClick={onDownloadExcel}
              >
                <Translator text={"Download"} />
              </Button>
            </Tooltip>
          </Box>
        )}
        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>{metaArray}</Box>
      </Box>
    );
  }, [isReportPending, isPending, report, onDownloadExcel]);

  const [currentTab, setCurrentTab] = useState(0);

  const onChangeTab = useCallback((e, value) => {
    setCurrentTab(value);
  }, []);

  const sections = useMemo(() => {
    if (isReportPending || isPending || !report) {
      return null;
    }

    const tabs = [];
    const panels = [];
    report.sections.forEach((sectionData, index) => {
      const totals = [];
      sectionData.totalsArray.forEach((total, index) => {
        let value = <Translator text={total.value} />;
        if (total.type === "datetime") {
          value = <Translator datetime={total.value} />;
        } else if (total.type === "number") {
          value = <Translator numberOfDigits={2} number={total.value} />;
        }
        totals.push(
          <Typography key={`total-${index}`} sx={{ textAlign: "right" }} variant="h6">
            <Translator text={total.name} />: {value}
          </Typography>
        );
      });

      tabs.push(<Tab label={<Translator text={sectionData.name} />} />);
      if (currentTab === index) {
        panels.push(
          <Box
            key={`${index}`}
            sx={{
              flexDirection: "column",
              display: "flex",
              padding: 1,
              overflow: "visible",
              flexShrink: 0,
              flex: 1,
            }}
          >
            <DataGrid
              sx={{
                ".MuiDataGrid-columnHeader": {
                  background: "rgba(128,128,128,0.1)",
                },
              }}
              autoHeight={false}
              hideFooter={true}
              rows={sectionData.valueArray}
              columns={sectionData.columnsConfig}
              autoPageSize={false}
              pagination={false}
              pageSize={sectionData.valueArray.length}
            />
            <Box
              sx={{
                flexDirection: "column",
                display: "flex",
                padding: 1,
              }}
            >
              {totals}
            </Box>
          </Box>
        );
      }
    });

    const sections = (
      <Fragment>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={currentTab} onChange={onChangeTab} aria-label="basic tabs example">
            {tabs}
          </Tabs>
        </Box>
        {panels}
      </Fragment>
    );

    return sections;
  }, [isReportPending, isPending, report, currentTab, onChangeTab]);

  if (!branch) return;

  return (
    <Box
      sx={{
        flexDirection: "column",
        display: "flex",
        flex: 1,
        padding: 1,
        maxHeight: "100%",
        maxWidth: "100%",
        overflow: "auto",
        paddingBottom: "6rem",
      }}
    >
      {!meta && (
        <Button onClick={onOpenDialog}>
          <Translator text={"Choose report parameters"} />
        </Button>
      )}
      {meta}
      {sections}
      <Fab
        onClick={onOpenDialog}
        key="fab"
        sx={{
          position: "fixed",
          bottom: 32,
          right: 32,
        }}
        color="primary"
        aria-label="FilterList"
      >
        <FilterList />
      </Fab>
      <Dialog open={open} onClose={onCloseDialog}>
        {!(isReportPending || isPending) && (
          <Form config={config} onSubmit={onSubmit} onCancel={onCloseDialog} initialValues={{ branchId: branch.id }} />
        )}
        <Preloader isPending={isReportPending || isPending} />
      </Dialog>
    </Box>
  );
});

export default ArchitectReport;
