import React, { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react";

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Dialog,
  Divider,
  Tooltip,
  Typography,
  IconButton,
  ButtonGroup,
} from "@mui/material";
import CampaignIcon from "@mui/icons-material/Campaign";
import { Add, Edit, ExpandMore, HistoryRounded } from "@mui/icons-material";

import useStores from "~/hooks";

import { ContractHistoryDialog } from "./";

import ClientWidget from "../../clients/ClientWidget";
import ManagerWidget from "../../employees/ManagerWidget";

import Translator from "~/components/Translator";
import Form from "~/components/Form";
import StateButton from "../StateButton";

import FileList from "../../../components/FileList";

import { CardSkeleton, PaymentsBar, ModellingCost, ExpensesWidget } from "./";

import { AddingContractExpenseDialog } from "~/modules/expenses/components/dialogs";

import FacilitiesWidget from "./FacilitiesWidget";
import { CreateFacilityDialog } from "~/modules/facilities/components";
import { CreateInvoiceDialog } from "~/modules/invoices/components";
import { EditContractDialog } from "~/modules/contracts/components/dialogs";
import ContractLink from "./buttons/ContractLink";
/**
 * Карточка контракта на рабочей доске контрактов.
 */
const ContractCard = observer(({ contract }) => {
  const { rootStore, invoiceStore } = useStores();

  // Контроль над диалогом добавления контракта
  const [isAddContractExpenseDialogOpen, setIsAddContractExpenseDialogOpen] = useState(false);
  const openDialogExpenses = useCallback(
    () => setIsAddContractExpenseDialogOpen(true),
    [setIsAddContractExpenseDialogOpen]
  );

  // Контроль над диалогом редактирования контракта
  const [isEditContractDialogOpen, setIsEditContractDialogOpen] = useState(false);
  const openEditContractDialog = useCallback(() => setIsEditContractDialogOpen(true), [setIsEditContractDialogOpen]);

  // Контроль над диалогом отображения истории контракта
  const [isContractHistoryDialogOpen, setIsContractHistoryDialogOpen] = useState(false);
  const openContractHistoryDialog = useCallback(
    () => setIsContractHistoryDialogOpen(true),
    [setIsContractHistoryDialogOpen]
  );

  // Контроль над диалогом добавления объекта
  const [isCreateFacilityDialogOpen, setIsCreateFacilityDialogOpen] = useState(false);
  const openCreateFacilityDialog = useCallback(
    () => setIsCreateFacilityDialogOpen(true),
    [setIsCreateFacilityDialogOpen]
  );

  // Контроль над диалогом добавления счёта
  const [isCreateInvoiceDialogOpen, setIsCreateInvoiceDialogOpen] = useState(false);
  const openCreateInvoiceDialog = useCallback(() => setIsCreateInvoiceDialogOpen(true), [setIsCreateInvoiceDialogOpen]);

  const {
    client,
    manager,
    modellingManager,
    number,
    modellingCost,
    status,
    date,
    description,
    isPending,
    totalArea,
    isError,
    isWaiting,
    canSetScanningDate,
    canSetModellingManager,
    addModelingManagerFormConfig,
    addScannerFormConfig,
    documents,
    downloadInvoiceFormConfig,
  } = contract;

  const changeStatusButtons = useMemo(() => {
    const buttons = [];
    let cancelledButton = null;

    if (status && status.next && status.next.length) {
      status.next.forEach((target) => {
        const button = (
          <StateButton
            state={target}
            key={target}
            isError={isError}
            contract={contract}
            sx={{
              maxWidth: 120, 
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          />
        );

        if (target === "Cancelled") {
          cancelledButton = (
            <StateButton
              state={target}
              key={`${target}-cancelled`}
              isError={isError}
              contract={contract}
              sx={{
                backgroundColor: "error.main",
                color: "white",
                "&:hover": {
                  backgroundColor: "error.dark", 
                },
              }}
            />
          );
        } else {
          buttons.push(button);
        }
      });
    }

    return (
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, justifyContent: "flex-end", width: "100%" }}>
        {buttons}
        {cancelledButton && (
          <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%", mt: 1 }}>
            {cancelledButton}
          </Box>
        )}
      </Box>
    );
  }, [isError, status, contract]);





  const [newInvoiceId, setNewInvoiceId] = useState("");
  const [formKind, setFormKind] = useState("facility");
  const [open, setOpen] = useState(false);
  const [isModalPending, setModalPending] = useState(false);

  const onOpenDialogModelingManager = useCallback(() => {
    setFormKind("modelling-manager");
    setOpen(true);
  }, [setOpen]);

  const onCloseDialog = useCallback(
    (e, reason) => {
      reason !== "backdropClick" && setOpen(false);
    },
    [setOpen]
  );

  const [formErrors, setFormErrors] = useState({});

  // const historyButton = useMemo(() => {
  //   return (
  //     // <Box>
  //       {/* Кнопка редактирования контракта */}
  //       <>
  //       <Tooltip arrow title={<Translator text={"Edit contract"} />}>
  //         <IconButton onClick={openEditContractDialog} color={"info"} sx={{ cursor: "pointer" }}>
  //           <Edit />
  //         </IconButton>
  //       </Tooltip>
  //       {/* Кнопка отображения истории контракта */}
  //       <Tooltip arrow title={<Translator text={"View contract history"} />}>
  //         <IconButton onClick={openContractHistoryDialog} sx={{ cursor: "pointer" }} color="info">
  //           <HistoryRounded />
  //         </IconButton>
  //       </Tooltip>
  //       </>
  //     // </Box>
  //   );
  // }, [openContractHistoryDialog, openEditContractDialog]);

  const onSubmit = useCallback(
    async (data) => {
      let result = false;
      try {
        setModalPending(true);
        setFormErrors({});
        // TODO: вынести в отдельный компонент
        if (formKind === "invoiceDownload") {
          await invoiceStore.downloadInvoice(data.newInvoiceId);
        } else if (formKind === "edit") {
          result = await contract.editContract(data);
          if (result && result.property) {
            setFormErrors({
              [result.property]: result.msg,
            });
          }
        } else if (formKind === "scanner") {
          if (data.facilityId && data.scannerId) {
            contract.addScannerAsync(data);
          } else {
            setModalPending(false);
            return;
          }
        } else if (formKind === "modelling-manager") {
          contract.setModelingManager(data);
        }
      } catch (error) {
        rootStore.setError("error", null, error && error.message);
      }
      setModalPending(false);
      if (formKind !== "edit" || result === true) {
        setOpen(false);
        setNewInvoiceId("");
      }
    },
    [contract, formKind, rootStore, invoiceStore]
  );

  const formConfigs = useMemo(() => {
    return {
      invoiceDownload: downloadInvoiceFormConfig,
      "modelling-manager": addModelingManagerFormConfig,
    };
  }, [addModelingManagerFormConfig, downloadInvoiceFormConfig]);

  const formConfig = useMemo(() => {
    return formConfigs[formKind];
  }, [formKind, formConfigs]);

  const [isDocumentsVisible, setIsDocumentsVisible] = useState(false);

  const toggleIsDocumentsVisible = useCallback(() => {
    setIsDocumentsVisible(!isDocumentsVisible);
  }, [isDocumentsVisible, setIsDocumentsVisible]);

  let cardColor = null;
  if (isError) cardColor = "error";
  if (isWaiting) cardColor = "info";
  return (
    <Card color={cardColor} sx={{ m: "0.5rem", ml: 0, overflow: "initial", display: "flex", flexDirection: "column" }}>
      {/* TODO: вынести в компонент */}
      {contract.actionNote && (
        <CardContent sx={{ p: 0 }}>
          <Typography
            sx={{
              p: "0.5rem",
              borderTopLeftRadius: "0.25rem",
              borderTopRightRadius: "0.25rem",
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              fontSize: "1rem",
              backgroundColor: "#fff2cc",
              display: "flex",
              flexDirection: "row",
              alignItems: "top",
            }}
          >
            <CampaignIcon sx={{ mr: 1 }} />
            <Translator text={contract.actionNote} />
          </Typography>
        </CardContent>
      )}
      <CardHeader
        color={cardColor}
        title={
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Box>
              <ContractLink contractId={contract.id} contractNumber={`# ${number}`} />
            </Box>
            <Box>
              <Tooltip arrow title={<Translator text="Contract date" />}>
                <Translator date={date} />
              </Tooltip>
            </Box>
          </Box>
        }
        sx={{ p: 1, pb: 0, m: 0, mr: 1 }}
      />

      {/* Contract description */}
      <CardContent sx={{ m: 0, p: 1, pt: 0, pb: 0 }}>{description}</CardContent>

      {/* Client and Payments */}
      <CardContent sx={{ m: 0, p: 1, pt: 0, pb: 0.25 }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <ClientWidget key={`client-${client.id}`} client={client} />
          <ManagerWidget manager={manager} />
        </Box>
        <PaymentsBar key={"payment"} contract={contract} openCreateInvoiceDialog={openCreateInvoiceDialog} />
        <ManagerWidget
          key={`modeling-manager-${modellingManager?.id}`}
          manager={modellingManager}
          role="modelling-manager"
        />
      </CardContent>
      {/* <Box sx={{ flex: 1, flexShrink: 0 }}>
            <ModellingCost amount={modellingCost.amount} currency={modellingCost.currency} />
          </Box> */}

      {/* Расходы */}
      <ExpensesWidget contract={contract} showAddContractExpenseDialog={openDialogExpenses} />

      {/* Объекты */}
      <FacilitiesWidget contract={contract} openCreateContractFacilityDialog={openCreateFacilityDialog} />

      {/* Documents */}
      {!!documents.length && (
        <Card sx={{ m: "0.5rem" }}>
          <CardActions onClick={toggleIsDocumentsVisible}>
            <Typography sx={{ m: "0.25rem" }}>
              <Translator text={"Documents"} />
            </Typography>
            <Chip label={documents.length} />
            <ExpandMore
              sx={{
                transform: `rotate(${isDocumentsVisible ? 180 : 0}deg)`,
                marginLeft: "auto",
              }}
              aria-expanded={isDocumentsVisible}
              aria-label={<Translator text={"Show documents"} />}
            />
          </CardActions>
          <Collapse in={isDocumentsVisible} timeout="auto" unmountOnExit>
            <Divider />
            <FileList type={"documents"} files={documents} />
          </Collapse>
        </Card>
      )}
      {canSetModellingManager && (
        <CardActions>
          <Button
            key={"setRenderer"}
            variant="outlined"
            startIcon={<Add />}
            color="info"
            onClick={onOpenDialogModelingManager}
          >
            <Translator text={"Add modeling manager"} />
          </Button>
        </CardActions>
      )}
      <CardActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <Box>
          <Tooltip arrow title={<Translator text={"Edit contract"} />}>
            {/* Здесь нужна обертка из-за тултипа. Без неё будет ругань в случае disabled button */}
            <Box>
              <IconButton onClick={openEditContractDialog} color={"info"} sx={{ cursor: "pointer" }}>
                <Edit />
              </IconButton>
            </Box>
          </Tooltip>
          {/* Кнопка отображения истории контракта */}
          <Tooltip arrow title={<Translator text={"View contract history"} />}>
            {/* Здесь нужна обертка из-за тултипа. Без неё будет ругань в случае disabled button */}
            <Box>
              <IconButton onClick={openContractHistoryDialog} sx={{ cursor: "pointer" }} color="info">
                <HistoryRounded />
              </IconButton>
            </Box>
          </Tooltip>
        </Box>
        <ButtonGroup>{changeStatusButtons}</ButtonGroup>
      </CardActions>
      <Dialog sx={{ ".MuiPaper-root": { maxWidth: "60vw" } }} open={open} onClose={onCloseDialog}>
        <Form
          isPending={isModalPending}
          config={formConfig}
          onSubmit={onSubmit}
          onCancel={onCloseDialog}
          errors={formErrors}
          initialValues={{ contractId: contract.id, newInvoiceId }}
        />
      </Dialog>

      {/* Диалог отображения истории контракта */}
      <ContractHistoryDialog
        contract={contract}
        isOpen={isContractHistoryDialogOpen}
        onClose={() => setIsContractHistoryDialogOpen(false)}
      />

      {/* Диалог добавления расходов к договору */}
      <AddingContractExpenseDialog
        contract={contract}
        isOpen={isAddContractExpenseDialogOpen}
        onClose={() => setIsAddContractExpenseDialogOpen(false)}
      />
      {/* Диалог добавления объекта к договору */}
      <CreateFacilityDialog
        contract={contract}
        isOpen={isCreateFacilityDialogOpen}
        onClose={() => setIsCreateFacilityDialogOpen(false)}
      />
      {/* Диалог добавления инвойса к договору */}
      <CreateInvoiceDialog
        contract={contract}
        isOpen={isCreateInvoiceDialogOpen}
        onClose={() => setIsCreateInvoiceDialogOpen(false)}
      />
      {/* Диалог редактирования контракта */}
      <EditContractDialog
        contract={contract}
        isOpen={isEditContractDialogOpen}
        onClose={() => setIsEditContractDialogOpen(false)}
      />
    </Card>
  );
});

export default ContractCard;
