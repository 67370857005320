import { useMemo } from "react";
import { observer } from "mobx-react";

import { Box, Typography } from "@mui/material";
import { Engineering, AccountCircle, AccessTime, LooksOne, LooksTwo, Speed } from "@mui/icons-material";

import Translator from "../../../components/Translator";
import { Fields } from "~/components";
import { Gallery } from "~/modules/photos/components";

import useStores from "~/hooks";
import ShiftMovementsGrid from "./ShiftMovementsGrid";

/**
 * Карточка рабочей смены.
 *
 * @param {*} shift Экземпляр модели смены.
 * @returns
 */
const CarShiftDetails = observer(({ shift }) => {
  const { languageStore: ls } = useStores();

  const shiftStartedAt = useMemo(
    () => ls.translate({ datetime: shift.startedAt, variant: "compact" }),
    [ls, shift.startedAt]
  );

  const shiftFinishedAt = useMemo(
    // TODO: Вынести локализацию даты в ls.translate
    () => ls.translate({ datetime: shift.finishedAt, variant: "compact" }),
    [ls, shift.finishedAt]
  );

  // формируем набор фотографий для галереи
  const photos = useMemo(
    () => [...shift.startCarPhotos, ...shift.finishCarPhotos],
    [shift.startCarPhotos, shift.finishCarPhotos]
  );

  // формируем текстовую аннотацию к текущей фото галереи
  const annotationFunc = (currentIndex) => {
    if (currentIndex >= shift.startCarPhotos.length) return "Shift finish";
    return "Shift start";
  };

  const shiftMileage = useMemo(() => {
    if (shift.finishMeta) {
      return shift.finishMeta.mileage - shift.startMeta.mileage;
    }
    return;
  }, [shift.finishMeta, shift.startMeta]);

  return (
    <Box sx={{ padding: 1 }}>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Engineering sx={{ marginRight: 1 }} />
        <Typography variant="h6">
          <Translator text="Working shift" />
          &nbsp;#{shift.id}
        </Typography>
      </Box>
      <Box sx={{ mt: 1, display: "flex", flexDirection: "row" }}>
        {/* Информация о смене */}
        <Box sx={{ display: "flex", flexDirection: "row", mr: 1, minWidth: "50%" }}>
          <Box id="car-shift-deatils_fields-and-grid" sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <Box id="car-shift-deatils_fields" sx={{ display: "flex", flexDirection: "row" }}>
              <Box>
                <Fields.InfoField label="Employee" icon={<AccountCircle />} value={shift.employee.fullName} />
              </Box>
              <Box>
                <Box>
                  <Fields.InfoField label="Started at" icon={<AccessTime />} value={shiftStartedAt} />
                  <Fields.InfoField label="Finished at" icon={<AccessTime />} value={shiftFinishedAt} />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Fields.InfoField
                    label="Start mileage"
                    value={ls.translate({ number: shift.startMeta.mileage })}
                    icon={<LooksOne />}
                  />
                  <Fields.InfoField
                    label="Finish mileage"
                    value={ls.translate({ number: shift.finishMeta?.mileage })}
                    icon={<LooksTwo />}
                  />
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Fields.InfoField
                      label="Shift Mileage"
                      value={ls.translate({ number: shiftMileage })}
                      icon={<Speed />}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <ShiftMovementsGrid shift={shift} />
          </Box>
        </Box>
        {/* Галерея */}
        <Gallery photos={photos} annotationFunc={annotationFunc} />
      </Box>
    </Box>
  );
});

export default CarShiftDetails;
