import React from "react";

import { Box, useTheme, useMediaQuery } from "@mui/material";

/**
 * Лого системы на главном тулбаре.
 */
export default function ToolbarLogo() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // На мобильном не показывает лого
  if (isMobile) return;

  return (
    <Box
      sx={{
        width: "9rem",
        height: "4rem",
        backgroundImage: "url(/main_logo_with_text_v2.svg)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "contain",
      }}
    />
  );
}
