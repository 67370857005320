import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { Button } from "@mui/material";
import { FileDownload } from "@mui/icons-material";

import useStores from "~/hooks";

import Translator from "../../components/Translator";

const DownloadInvoice = observer(({ invoiceId }) => {
  const { invoiceStore } = useStores();

  const onDownloadInvoice = useCallback(() => {
    invoiceStore.downloadInvoice(invoiceId);
  }, [invoiceId, invoiceStore]);

  return (
    <Button
      key={"downloadInvoice"}
      variant="outlined"
      startIcon={<FileDownload />}
      sx={{ flex: 1 }}
      color="info"
      onClick={onDownloadInvoice}
    >
      <Translator text={"Download invoice"} />
    </Button>
  );
});

export default DownloadInvoice;
