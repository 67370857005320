import { action, computed, makeObservable, observable } from "mobx";

/**
 * Статус контракта.
 */
class Status {
  @observable store = null;
  @observable id = "";
  @observable slug = "";
  // TODO: Ездит строчкой, а должен ездить объектом
  @observable next = [];

  constructor(props, store) {
    makeObservable(this);
    this.store = store;
    this.id = `${props.label}`;
    this.slug = props.slug;
    this.next = props.next || [];
  }

  @computed get label() {
    return this.id;
  }

  @computed get nextSet() {
    return new Set(this.next);
  }

  // TODO: а оно надо?
  @computed get errorItemsCount() {
    let count = 0;
    this.items.forEach((contract) => {
      if (contract.isError) {
        count += 1;
      }
    });
    return count;
  }

  // -----
  /**
   * Является ли этот статус конечным. Такие статусы мы не показываем на доске.
   * Конечный статус это статус без следующих состояний.
   */
  @computed get isLeaf() {
    return this.next.length === 0;
  }
}

export default Status;
