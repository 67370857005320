import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { Box, Chip, Divider, Typography } from "@mui/material";

import useStores from "~/hooks";
import Translator from "../../components/Translator";
import Preloader from "../../components/Preloader";
import RenderingCard from "./RenderingCard";

const ModellingCards = observer(() => {
  const { facilityStore } = useStores();
  const { isPending, isPendingRenderingPlans, facilitiesToPlanModelling } = facilityStore;
  const isPendingPlans = useMemo(() => {
    return isPending || isPendingRenderingPlans;
  }, [isPending, isPendingRenderingPlans]);

  const cards = useMemo(() => {
    if (isPendingPlans) {
      return null;
    }
    return facilitiesToPlanModelling.map((facility) => {
      return <RenderingCard key={facility.id} facility={facility} />;
    });
  }, [facilitiesToPlanModelling, isPendingPlans]);

  return (
    <Box
      sx={{
        flexDirection: "column",
        display: "flex",
        padding: "0.5rem",
        pt: "1rem",
        overflow: "hidden",
      }}
    >
      <Typography variant="h5">
        <Translator text="Facilities to plan" />
        <Chip sx={{ m: "0.5rem" }} label={facilitiesToPlanModelling.length} />
      </Typography>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          pt: "0.5rem",
          pb: "0.5rem",
          pr: "6rem",
          overflowX: "auto",
          flexWrap: "nowrap",
        }}
      >
        {!isPendingPlans && cards}
        <Preloader key="preloader" isPending={isPendingPlans} />
      </Box>
    </Box>
  );
});

export default ModellingCards;
