import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { Box, ListItemText, Divider, ListItemButton, List, ListItemIcon, Drawer, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Logout } from "@mui/icons-material";

import Translator from "~/components/Translator";
import useStores from "~/hooks";

/**
 * Боковая панель навигации. Боковое меню. Основное меню. Слева в Workspace со списком
 * модулей.
 */
const Sidebar = observer(({ open, currentView, sidebarWidth, modules, rootStore, isMobile }) => {
  const { workspaceStore, authStore } = useStores();

  const StyledDrawer = styled(Drawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: sidebarWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...{
      overflowX: "hidden",
      width: `calc(${theme.spacing(7)} + 1px)`,
      [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
      },
      "& .MuiDrawer-paper": {
        overflowX: "hidden",
        width: `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up("sm")]: {
          width: `calc(${theme.spacing(8)} + 1px)`,
        },
      },
    },
  }));

  const SidebarHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    sx: {
      position: "relative",
      padding: 0,
      marginRight: "-0.125rem",
    },
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const setCurrentView = useCallback(
    (slug) => {
      workspaceStore.setCurrentView(slug);
    },
    [workspaceStore]
  );

  return (
    <StyledDrawer variant="permanent" color="primary" open={open}>
      {/* SidebarHeader Занимает место чтобы было видно все модули */}
      <SidebarHeader />
      <Divider />
      <List>
        {Object.values(modules).map((m) => {
          const { slug, title, icon } = m;
          return (
            <Tooltip arrow placement="right" key={slug} title={title}>
              {/* Нужна обёртка на кнопкой в тултипе иначе будет warning */}
              <Box>
                <ListItemButton
                  key={slug}
                  selected={workspaceStore.currentViewSlug === slug}
                  sx={{ minHeight: 48, justifyContent: "initial" }}
                  onClick={() => setCurrentView(slug)}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: "auto", justifyContent: "center" }}>{icon}</ListItemIcon>
                </ListItemButton>
              </Box>
            </Tooltip>
          );
        })}
      </List>
      <Box sx={{ flex: 1 }} />
      <List>
        <ListItemButton
          key={"logout"}
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => authStore.logout()}
        >
          <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center" }}>
            <Logout />
          </ListItemIcon>
          <ListItemText primary={<Translator text="Logout" />} sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </List>

      {/* <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          p: 0,
          overflow: "hidden",
        }}
      >
        <DrawerHeader sx={{ flexShrink: 0 }} />
        <Box sx={{ display: "flex", flexGrow: 1, overflow: "hidden" }}>
          {!isPendingCritical && views[currentView]}
          <Preloader isPending={isPendingCritical} />
        </Box>
      </Box> */}
    </StyledDrawer>
  );
});

export default Sidebar;
