import React, { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import useStores from "~/hooks";
import { Add } from "@mui/icons-material";
import FacilityCard from "../modules/facilities/FacilityCard";
import { Masonry } from "@mui/lab";
import Translator from "../components/Translator";

const Facilities = observer(() => {
  const { facilityStore } = useStores();
  const { facilityIdsArray } = facilityStore;

  const facilitiesRender = useMemo(() => {
    const array = [];
    facilityIdsArray.forEach((facilityId) => {
      array.push(<FacilityCard key={facilityId} id={facilityId} />);
    });
    return array;
  }, [facilityIdsArray]);

  const fabStyle = {
    position: "fixed",
    bottom: 32,
    right: 32,
  };

  const [open, setOpen] = useState(false);

  const onOpenDialog = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const onCloseDialog = useCallback(
    (e, reason) => {
      if (reason === "backdropClick") return;
      setOpen(false);
    },
    [setOpen]
  );

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const data = new FormData(e.currentTarget);
    setOpen(false);
  };

  const ValidationTextField = styled(TextField)({
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 2,
      padding: "4px !important", // override inline-style
    },
  });

  return (
    <Box
      sx={{
        flexDirection: "column",
        display: "flex",
        flex: 1,
        padding: 1,
      }}
    >
      <Masonry columns={4} spacing={1}>
        {facilitiesRender}
      </Masonry>
      <Fab key="fab" sx={fabStyle} color="primary" aria-label="Add">
        <Add onClick={onOpenDialog} />
      </Fab>
      <Dialog open={open} onClose={onCloseDialog}>
        <Box component="form" onSubmit={onSubmit}>
          <DialogTitle>
            <Translator text={"Create new facility"} />
          </DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText>
              <Translator text={"To create new facility fill in the form below"} />
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="title"
              name="title"
              label={<Translator text={"Title"} />}
              fullWidth
            />
            <ValidationTextField
              margin="dense"
              id="area"
              name="area"
              label={<Translator text={"Area"} />}
              inputProps={{
                type: "text",
                inputMode: "numeric",
                pattern: "[0-9]+(.[0-9]+)?",
              }}
              fullWidth={true}
              required={true}
            />
            <TextField
              autoFocus
              margin="dense"
              id="address"
              label={<Translator text={"Address"} />}
              name="address"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button type="submit">
              <Translator text={"Submit"} />
            </Button>
            <Button onClick={onCloseDialog}>
              <Translator text={"Cancel"} />
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
});

export default Facilities;
