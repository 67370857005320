import { useCallback } from "react";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { DateRangePicker as Picker, LocalizationProvider } from "@mui/x-date-pickers-pro";

/** Пикер выбора диапазона дат. */
const DateRangePicker = ({ onAccept }) => {
  const handleAccept = useCallback((value) => onAccept && onAccept(value), [onAccept]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Picker
        sx={{ ml: 1 }}
        dateAdapter={AdapterMoment}
        disableFuture
        reduceAnimations
        allowSameDateSelection
        currentMonthCalendarPosition={2}
        localeText={{ start: "From", end: "To" }}
        onAccept={handleAccept}
      />
    </LocalizationProvider>
  );
};

export default DateRangePicker;
