import { action, computed, makeObservable, observable } from "mobx";
import Report from "../models/Report";

class ArchitectReportStore {
  @observable rootStore = null;
  @observable api = null;
  @observable report = null;
  @observable pending = false;
  @observable pendingDownload = false;

  constructor(rootStore) {
    makeObservable(this);

    this.rootStore = rootStore;
    this.api = this.rootStore.api;
  }

  /**
   * Запросить данные репортра.
   */
  @action async getReport(branchId, scannerId, from, to) {
    this.setPending(true);
    const report = await this.api.getArchitectReport({ branchId, scannerId, from, to });
    this.processReport(report);
    this.setPending(false);
  }

  /**
   * Скачать репорт из системы.
   */
  @action async downloadReport(branchId, scannerId, from, to) {
    this.setPendingDownload(true);
    try {
      const report = await this.api.downloadArchitectReport({
        branchId,
        scannerId,
        from,
        to,
      });
      if (report) {
        const blob = await report.blob();
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = href;
        link.download = report.headers
          .get("content-disposition")
          .replace("attachment; ", "")
          .replace('filename="', "")
          .replace('"', "");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.warn(error);
    }
    this.setPendingDownload(false);
  }

  @action processReport(report) {
    if (!report) return;
    this.report = new Report(report, this);
  }

  @action setPending(pending = false) {
    this.pending = pending;
  }

  @action setPendingDownload(pending = false) {
    this.pendingDownload = pending;
  }

  @computed get isPending() {
    return this.pending;
  }

  @computed get isPendingDownload() {
    return this.pendingDownload;
  }
}

export default ArchitectReportStore;
