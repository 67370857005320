import React, { useMemo, useState, useCallback } from "react";
import { observer } from "mobx-react";

import { Dialog } from "@mui/material";

import useStores from "~/hooks";
import { Notify } from "~/common/components";
import Translator from "~/components/Translator";
import Form from "~/components/Form";

/**
 * Диалог добавления объекта к договору.
 */
const CreateFacilityDialog = observer(({ isOpen, onClose, contract }) => {
  const [isNotifyOpen, setIsNotifyOpen] = useState(false);

  const { facilityStore } = useStores();

  // закрыть диалог
  const handleClose = useCallback(
    (e, reason) => {
      if (reason === "backdropClick") return;
      !!onClose && onClose();
    },
    [onClose]
  );

  // TODO: здесь нужно учесть isPending стора
  // отправить форму
  const handleSubmit = useCallback(
    async (data) => {
      await facilityStore.createFacility(contract, data);
      setIsNotifyOpen(true);
      !!onClose && onClose();
    },
    [contract, facilityStore, setIsNotifyOpen, onClose]
  );

  const config = useMemo(() => {
    return {
      fields: [
        {
          name: "contractId",
          type: "hidden",
          viewConfig: "input",
          initialValue: "payload",
        },
        {
          name: "name",
          title: "Name",
          type: "text",
          viewConfig: "input",
          isRequired: true,
          isReadOnly: false,
          validate: true,
        },
        {
          name: "area",
          title: "Area",
          type: "number",
          viewConfig: "input",
          inputMode: "decimal",
          endAdornment: "m2",
          min: 1,
          step: 0.01,
          isRequired: true,
          isReadOnly: false,
          validate: true,
        },
        {
          name: "kind",
          fakeName: "kindName",
          title: "Kind",
          type: "select",
          canCreate: false,
          isRequired: true,
          isReadOnly: false,
          validate: true,
          options: facilityStore.kindsArrayForSelect,
        },
        {
          name: "description",
          title: "Description",
          type: "text",
          multiline: true,
          isRequired: false,
          isReadOnly: false,
          validate: false,
        },
        // {
        //   name: "address",
        //   title: "Address",
        //   type: "text",
        //   viewConfig: "input",
        //   isRequired: false,
        //   isReadOnly: false,
        //   validate: false,
        // }
      ],
      submitText: "Submit",
      cancelText: "Cancel",
      formTitle: "Create new facility",
    };
  }, [facilityStore]);

  // Ошибки отправки формы
  const formErrors = [];

  return (
    <>
      <Dialog sx={{ ".MuiPaper-root": { maxWidth: "60vw" } }} open={isOpen} onClose={handleClose}>
        <Form
          isPending={facilityStore.isPending}
          config={config}
          onSubmit={handleSubmit}
          onCancel={handleClose}
          errors={formErrors}
          initialValues={{ contractId: contract.id }}
        />
      </Dialog>
      <Notify isOpen={isNotifyOpen} onClose={() => setIsNotifyOpen(false)}>
        <Translator text={"Facility created"} />
      </Notify>
    </>
  );
});

export default CreateFacilityDialog;
