import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";

import {
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Delete, FileOpen } from "@mui/icons-material";

import useStores from "~/hooks";
import Preloader from "./Preloader";

const FilePreview = observer(({ file, index, deleteByIndex, fileType }) => {
  const { rootStore } = useStores();
  const [fileData, setFileData] = useState(null);

  const isPreviewable = useMemo(() => {
    if (
      file.type === "application/pdf" ||
      (file.file &&
        file.file.mimetype &&
        file.file.mimetype === "application/pdf")
    ) {
      return true;
    } else if (
      (file.type && file.type.split("/")[0] === "image") ||
      (file.file &&
        file.file.mimetype &&
        file.file.mimetype.split("/")[0] === "image")
    ) {
      return true;
    }
    return false;
  }, [file]);

  const [isPending, setIsPending] = useState(false);

  const getFile = useCallback(
    async (id) => {
      if (id) {
        setIsPending(true);
        let fileData = null;
        if (fileType === "documents") {
          fileData = await rootStore.getDocument(id);
        }
        if (fileType === "photos") {
          fileData = await rootStore.getPhoto(id);
        }
        let imageBlob = null;
        if (fileData) {
          imageBlob = await fileData.blob();
        }
        if (imageBlob) {
          setFileData(imageBlob);
        }
        setIsPending(false);
        return imageBlob;
      }
      return false;
    },
    [setFileData, setIsPending]
  );

  useEffect(() => {
    if (!fileData && isPreviewable && file && file.file) {
      getFile(file.id);
    }
  }, [file.id, file.file, fileData, isPreviewable, getFile]);

  const onClickDelete = useCallback(() => {
    deleteByIndex(index);
  }, [index, deleteByIndex]);

  const name = useMemo(() => {
    if (!!file.file) {
      return file.file.name;
    }
    return file.name;
  }, [file]);

  const objectUrl = useMemo(() => {
    if (!!file.file && fileData) {
      return URL.createObjectURL(fileData);
    }
    return (file && !file.file && URL.createObjectURL(file)) || null;
  }, [file, fileData]);

  const component = useMemo(() => {
    if (!!file.file) {
      return file.file.mimetype === "application/pdf" ? "iframe" : "img";
    }
    return file.type === "application/pdf" ? "iframe" : "img";
  }, [file]);

  return (
    <Card sx={{ m: "0.25rem", width: "15rem" }}>
      {isPreviewable && !isPending && (
        <CardMedia
          component={component}
          sx={{ height: "10rem" }}
          image={objectUrl}
          alt={name}
        />
      )}
      {isPreviewable && isPending && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "10rem",
          }}
        >
          <Preloader isPending={isPending} size={"7rem"} />
        </Box>
      )}
      {!isPreviewable && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FileOpen color="info" sx={{ fontSize: "7rem", height: "10rem" }} />
        </Box>
      )}
      <CardContent sx={{ paddingBottom: "0.5rem !important" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              flex: 1,
              mr: "0.25rem",
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <Tooltip
              arrow
              title={<Typography variant="body">{name}</Typography>}
            >
              <Typography
                sx={{ flex: 1, textOverflow: "ellipsis", overflow: "hidden" }}
                variant="body2"
                color="text.secondary"
              >
                {name}
              </Typography>
            </Tooltip>
          </Box>
          <IconButton onClick={onClickDelete} color="error" aria-label="remove">
            <Delete />
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );
});

export default FilePreview;
