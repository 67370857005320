import React from "react";
import { observer } from "mobx-react";

import { Box, Tooltip, Typography } from "@mui/material";

import CorporateFareIcon from "@mui/icons-material/CorporateFare";

const ClientWidget = observer(({ client }) => {
  if (!client) return null;

  const { name, contact } = client;
  const tooltip = (
    <Box sx={{ flexDirection: "row", display: "flex" }}>
      <Box sx={{ flexDirection: "column", display: "flex", mr: 0.5, mt: 0.5 }}>
        <CorporateFareIcon color="primary" />
      </Box>
      <Box>
        {name && <Typography sx={{ fontWeight: 500 }}>{name}</Typography>}
        {contact && <Typography variant="body2">{contact}</Typography>}
      </Box>
    </Box>
  );
  return (
    <Box sx={{ flexDirection: "row", display: "flex", mb: 0.5, mt: 1 }}>
      <Box sx={{ flexDirection: "column", display: "flex", mr: 0.5 }}>
        <Tooltip arrow title={tooltip}>
          <CorporateFareIcon color="primary" />
        </Tooltip>
      </Box>
      <Typography sx={{ fontWeight: 500 }} variant="body">
        {name}
      </Typography>
      {/* )} */}
    </Box>
  );
});

export default ClientWidget;
