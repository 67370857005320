import { makeObservable, observable, action } from "mobx";

/**
 * Метаданные конца смены.
 */
class ShiftFinishMetaData {
  @observable mileage;
  @observable returnMileage;
  @observable shift;

  constructor({ carMileage: mileage, returnMileage }) {
    makeObservable(this);
    this.mileage = mileage;
    this.returnMileage = returnMileage;
  }

  /**
   * Установить ссылку на смену.
   *
   * @param {*} shift смена
   */
  @action setShift(shift) {
    this.shift = shift;
  }
}

export default ShiftFinishMetaData;
