import React, { Fragment } from "react";
import { observer } from "mobx-react";

import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import Translator from "./Translator";

const BarChartItem = observer(({ item, i }) => {
  const theme = useTheme();
  const { percent, currency, date, amount, type } = item;
  let color = theme.palette.success.light;

  if (type === "overpayed") {
    color = theme.palette.info.light;
  } else if (type === "credit") {
    color = theme.palette.warning.light;
  }
  return (
    <Box
      sx={{
        height: "2rem",
        width: `${percent}%`,
        p: "0.125rem",
        boxSizing: "border-box",
        paddingLeft: i === 0 ? "0.125rem" : "0",
        display: "flex",
      }}
    >
      <Tooltip
        arrow
        title={
          <Fragment>
            <Typography sx={{ fontSize: "0.75rem" }}>
              {date && <Translator date={date} />}
            </Typography>
            {type === "overpayed" && (
              <Typography sx={{ fontSize: "0.75rem" }}>
                <Translator text={"Overpayed"} />{": "}
              </Typography>
            )}
            {type === "credit" && (
              <Typography sx={{ fontSize: "0.75rem" }}>
                <Translator text={"To pay left"} />{": "}
              </Typography>
            )}
            <Typography sx={{ fontSize: "0.75rem" }}>
              <Translator number={amount} currency={currency} />
            </Typography>
          </Fragment>
        }
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "0.25rem",
            overflow: "hidden",
            backgroundColor: color,
          }}
        >
          <Typography
            sx={{
              overflow: "hidden",
              margin: "0 0.25rem",
              textOverflow: "ellipsis",
              fontSize: "0.75rem",
              color: theme.palette.primary.contrastText,
            }}
          >
            <Translator number={amount} currency={item.currency} />
          </Typography>
        </Box>
      </Tooltip>
    </Box>
  );
});

export default BarChartItem;
