import { makeObservable, observable, action } from "mobx";

/**
 * Метаданные начала смены.
 */
class ShiftStartMetaData {
  @observable mileage;
  @observable shift;

  constructor({ carMileage: mileage }) {
    makeObservable(this);
    this.mileage = mileage;
  }

  /**
   * Установить ссылку на смену.
   *
   * @param {*} shift смена
   */
  @action setShift(shift) {
    this.shift = shift;
  }
}

export default ShiftStartMetaData;
