import React, { useCallback, useMemo } from "react";
import { observer } from "mobx-react";

import moment from "moment";

import { Box, Chip, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { Add } from "@mui/icons-material";
import MoreTimeIcon from "@mui/icons-material/MoreTime";

import { ScannerOutlined, ViewInArOutlined } from "@mui/icons-material";
import { Translator } from "~/components";
import FacilityIcon from "./FacilityIcon";
import { FacilityChip } from "./bricks";

/**
 * Отображение объекта на карточке конракта.
 */
const FacilityOnContractCard = observer(({ facility, status, openCreateScanningPlanDialog }) => {
  const isScanningPlanned = !!facility.scanningPlan;

  // const serviceIconsByKind = {
  //   scanning: <ScannerOutlined />,
  //   modelling: <ViewInArOutlined />,
  // };

  // Открыть диалог с установкой facility
  const handleClick = useCallback(() => {
    openCreateScanningPlanDialog(facility);
  }, [openCreateScanningPlanDialog, facility]);

  const color = useMemo(() => {
    if (!facility.scanningPlan) {
      return "red";
    }
    if (!facility.scanningPlan?.isFinished) {
      return "orange";
    }
    if (!facility.scanningPlan?.result) {
      return "blue";
    }
    return "green";
  }, [facility.scanningPlan]);

  return (
    <Box
      sx={{
        flexDirection: "row",
        justifyContent: "flex-start",
        display: "flex",
        flex: 1,
        width: "100%",
        overflow: "hidden",
        ml: 0,
        mb: 1,
      }}
    >
      {/* TODO: вынести в компонент FacilityChip */}
      {/* <FacilityChip facility={facility} /> */}
      <Chip
        sx={{ p: "0.5rem", borderRadius: "4px" }}
        color={color}
        size="medium"
        icon={<FacilityIcon kind={facility.kind} />}
        label={
          <>
            <Translator number={facility.area} /> <Translator text="m2" />
          </>
        }
      />
      <Box sx={{ display: "flex", mt: 0.75, ml: 0.5, flex: 1, width: "100%", alignContent: "center" }}>
        {facility.name}
      </Box>
      {/*  */}
      <Box sx={{ pr: 0.5 }}>
        {!isScanningPlanned && (
          <Tooltip title={<Translator text={"Add scanner"} />}>
            {/* Нужна обрёртка над кнопкой внутри тултипа */}
            <Box>
              <IconButton color="info" onClick={handleClick}>
                <MoreTimeIcon />
              </IconButton>
            </Box>
          </Tooltip>
        )}
        {isScanningPlanned && (
          <Box
            sx={{
              display: "flex",
              mt: 0.75,
              ml: 0.5,
              mr: 0.5,
              flex: 1,
              width: "100%",
              alignContent: "center",
              fontWeight: "bold",
            }}
          >
            <Tooltip title={facility.scanningPlan?.employee.name}>
              <Box>
                <Translator date={facility.scanningPlan.start} />
              </Box>
            </Tooltip>
          </Box>
        )}
      </Box>
    </Box>
  );
});

export default FacilityOnContractCard;

// {
/* <Box
sx={{
  flexDirection: "row",
  display: "flex",
  alignItems: "flex-start",
  mt: 0.25,
  overflow: "hidden",
}}
><Box
  sx={{
    alignItems: "start",
    flexDirection: "row",
    display: "flex",
    mr: 0.5,
    flex: 1
  }}
> 
  
  

  </Box>; */
// }

//   {!isName && (
//     <Tooltip
//       arrow
//       title={
//         <Box
//           sx={{
//             flexDirection: "row",
//             display: "flex",
//             flexShrink: 0,
//           }}
//         >
//           <Box
//             sx={{
//               flexDirection: "column",
//               display: "flex",
//               mr: 0.5,
//               mt: 0.5,
//             }}
//           >
//             <FacilityIcon kind={facility.kind} />
//           </Box>
//           <Box sx={{ flex: 1, ml: "1rem" }}>
//             <Typography sx={{ whitespace: "nowrap" }} variant="body2">
//               {facility.name}
//             </Typography>
//             {/* {facility.address && <Typography variant="body">{facility.address}</Typography>} */}
//             {/* {facility.area && ( */}
//             {/* <Typography variant="h5"> */}
//             {/* <Translator number={facility.area} /> <Translator text="m2" /> */}
//             {/* </Typography> */}
//             {/* )} */}
//             {/* {facility.description && <Typography variant="body">{facility.description}</Typography>} */}
//           </Box>
//           <Box sx={{ flex: 1 }}>
//             {!!facility.services.length &&
//               facility.services.map((service) => {
//                 return (
//                   <Box key={service && service.uid} sx={{ flexDirection: "row", display: "flex" }}>
//                     <Box>
//                       <Typography
//                         key="employee"
//                         sx={{
//                           whitespace: "nowrap",
//                           textTransform: "capitalize",
//                         }}
//                         variant="h6"
//                       >
//                         {service.kind}
//                       </Typography>
//                       {service.employeeFullName && (
//                         <Typography key="employee" sx={{ whitespace: "nowrap" }} variant="body2">
//                           {service.employeeFullName}
//                         </Typography>
//                       )}
//                       {service.hasRaw && (
//                         <Typography key="raw" sx={{ whitespace: "nowrap" }} variant="body2">
//                           <a href={service.result.rawCloud}>
//                             <Translator text={"Scanning result"} />
//                           </a>
//                         </Typography>
//                       )}
//                       {service.hasLinked && (
//                         <Typography key="linked" sx={{ whitespace: "nowrap" }} variant="body2">
//                           <a href={service.result.linkedCloud}>
//                             <Translator text={"Linking result"} />
//                           </a>
//                         </Typography>
//                       )}
//                       {service.hasResult && service.kind === "modelling" && (
//                         <Typography key="modelled" sx={{ whitespace: "nowrap" }} variant="body2">
//                           <a href={service.result.url}>
//                             <Translator text={"Modelling result"} />
//                           </a>
//                         </Typography>
//                       )}
//                     </Box>
//                   </Box>
//                 );
//               })}
//           </Box>
//         </Box>
//       }
//     ></Tooltip>
//   )}

// {!isFull && !isSmall && !!status && (
//   <Box
//     sx={{
//       alignItems: "center",
//       flexDirection: "row",
//       display: "flex",
//       ml: 0.5,
//     }}
//   >
//     {!!facility.services.length &&
//       facility.services.map((service) => {
//         let color = "info";
//         if (service.isStarted) {
//           color = "warning";
//         }
//         if (service.hasRaw && status === "Measuring") {
//           color = "success";
//         }
//         if (service.hasLinked && service.kind === "scanning") {
//           color = "success";
//         }
//         if (service.hasResult && service.kind === "modelling") {
//           color = "success";
//         }
//         return (
//           <Tooltip
//             key={service.uid}
//             arrow
//             title={
//               <Box sx={{ flexDirection: "row", display: "flex" }}>
//                 <Box>
//                   {service.employeeFullName && (
//                     <Typography key="employee" sx={{ whitespace: "nowrap" }} variant="body2">
//                       {service.employeeFullName}
//                     </Typography>
//                   )}
//                   <Translator key="plan" text={"Plan"} />{" "}
//                   {service.start && (
//                     <Typography key="ps" sx={{ whitespace: "nowrap" }} variant="body2">
//                       <Translator text={"Start:"} /> <Translator datetime={service.start} />
//                     </Typography>
//                   )}
//                   {service.finish && (
//                     <Typography key="pf" sx={{ whitespace: "nowrap" }} variant="body2">
//                       <Translator text={"Finish:"} /> <Translator datetime={service.finish} />
//                     </Typography>
//                   )}
//                   {(service.started || service.finished) && <Translator key="fact" text={"Fact"} />}
//                   {service.started && (
//                     <Typography key="fs" sx={{ whitespace: "nowrap" }} variant="body2">
//                       <Translator text={"Start:"} />{" "}
//                       <Translator datetime={moment(service.started.datetime).utc()} />
//                     </Typography>
//                   )}
//                   {service.finished && (
//                     <Typography key="ff" sx={{ whitespace: "nowrap" }} variant="body2">
//                       <Translator text={"Finish:"} />{" "}
//                       <Translator datetime={moment(service.finished.datetime).utc()} />
//                     </Typography>
//                   )}
//                   {service.hasRaw && (
//                     <Typography key="raw" sx={{ whitespace: "nowrap" }} variant="body2">
//                       <a href={service.result.rawCloud}>
//                         <Translator text={"Scanning result"} />
//                       </a>
//                     </Typography>
//                   )}
//                   {service.hasLinked && (
//                     <Typography key="linked" sx={{ whitespace: "nowrap" }} variant="body2">
//                       <a href={service.result.linkedCloud}>
//                         <Translator text={"Linking result"} />
//                       </a>
//                     </Typography>
//                   )}
//                   {service.hasResult && service.kind === "modelling" && (
//                     <Typography key="modelled" sx={{ whitespace: "nowrap" }} variant="body2">
//                       <a href={service.result.url}>
//                         <Translator text={"Modelling result"} />
//                       </a>
//                     </Typography>
//                   )}
//                 </Box>
//               </Box>
//             }
//           >
//             <Chip
//               sx={{
//                 p: "0.25rem",
//                 m: "0 0.25rem",
//                 "& .MuiChip-label": {
//                   pr: 0,
//                 },
//               }}
//               key={service.kind}
//               color={color}
//               size="small"
//               varian="outlined"
//               icon={serviceIconsByKind[service.kind]}
//             />
//           </Tooltip>
//         );
//       })}
//   </Box>
// )}
// </Box>
// {(isFull || isDescr) && (
// <Box
//   sx={{
//     flexDirection: "row",
//     display: "flex",
//     mt: "0.5rem",
//   }}
// >
//   <Box>
//     {facility.address && <Typography variant="body">{facility.address}</Typography>}
//     {facility.description && <Typography variant="body">{facility.description}</Typography>}
//   </Box>
// </Box>
// )}
// </Box>
// );
