// components/FacilityInfoCard.js
import { observer } from "mobx-react";
import { Box, Typography as T } from "@mui/material";
import { ContractButton } from "~/modules/contracts/components";
import FacilityIcon from "./FacilityIcon";
import ContractLink from "~/modules/contracts/components/buttons/ContractLink";
import { useEffect } from "react";

const FacilityInfoCard = observer(({ facility }) => {
  useEffect(() => {
    console.log(facility);
  });
  return (
    <Box sx={{ padding: 1, borderRadius: 4, zIndex: 1000 }}> {/* z-index ниже */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FacilityIcon kind={facility.kind} sx={{ color: "#1177d3" }} />
          <T variant="h6" sx={{ color: "#1177d3", marginLeft: 1 }}>
            {facility.label}
          </T>
        </Box>

        <ContractLink contractId={facility.contractInfo.id} contractNumber={facility.contractInfo.number} />
        </Box>
      <T variant="body1">{facility.description}</T>
    </Box>
  );
});

export default FacilityInfoCard;
