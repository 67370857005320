import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { Box, Typography } from "@mui/material";
import Translator from "../../components/Translator";

const GantHeader = observer(({ startOfPeriod, endofPeriod }) => {
  const days = useMemo(() => {
    const days = [];
    const start = startOfPeriod.clone().startOf("day");
    const end = endofPeriod.clone().endOf("day");
    const cursor = start.clone().startOf("day").set("hour", 12);
    while (cursor <= end) {
      // const key = cursor.unix();
      days.push(
        <Box
          // key={{key}}
          sx={{
            display: "flex",
            alignItems: "center",
            flex: 1,
            flexShrink: 0,
            justifyContent: "center",
            borderRight: (theme) => {
              return `0.0625rem solid ${theme.palette.grey[300]}`;
            },
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 600 }}>
            <Translator date={cursor.clone()} variant="compact" />
          </Typography>
        </Box>
      );
      cursor.add(1, "days");
    }
    return days;
  }, [startOfPeriod, endofPeriod]);

  return (
    <Box
      sx={{
        flexDirection: "row",
        display: "flex",
        minHeight: "2rem",
        borderTop: (theme) => {
          return `0.0625rem solid ${theme.palette.grey[300]}`;
        },
        borderBottom: (theme) => {
          return `0.0625rem solid ${theme.palette.grey[300]}`;
        },
      }}
    >
      <Box
        sx={{
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "15rem",
          borderRight: (theme) => {
            return `0.0625rem solid ${theme.palette.grey[300]}`;
          },
          borderLeft: (theme) => {
            return `0.0625rem solid ${theme.palette.grey[300]}`;
          },
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          overflow: "hidden",
        }}
      >
        {days}
      </Box>
    </Box>
  );
});

export default GantHeader;
