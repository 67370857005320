import React, { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import FacilityRender from "../facilities/FacilityRender";
import { Close, Edit, FileDownload, FileOpen } from "@mui/icons-material";
import Form from "../../components/Form";
import moment from "moment";
import Translator from "../../components/Translator";

const GantLinePLan = observer(({ facility, plan, startOfPeriod, endOfPeriod }) => {
  const { editPlanFormConfig, resultFormConfig } = facility;

  const [formType, setFormType] = useState("edit");
  const [fileVisible, setFileVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isFormPending, setIsFormPending] = useState(false);

  const onOpenModal = useCallback(() => {
    setFormType("edit");
    setModalVisible(true);
  }, [setModalVisible, setFormType]);

  const onSetResult = useCallback(() => {
    setFormType("result");
    setModalVisible(true);
  }, [setFormType, setModalVisible]);

  const onCloseModal = useCallback(
    (e, reason) => {
      if (reason === "backdropClick") return;
      setModalVisible(false);
    },
    [setModalVisible]
  );

  const onSetShow = useCallback(() => {
    setFileVisible(true);
  }, [setFileVisible]);

  const onCloseShow = useCallback(() => {
    setFileVisible(false);
  }, [setFileVisible]);

  const onSubmit = useCallback(
    async (data) => {
      setIsFormPending(true);
      if (formType === "edit") {
        await facility.editModellingPlan({
          ...data,
          start: moment(data.start, "L").format("YYYY-MM-DD"),
          finish: moment(data.finish, "L").format("YYYY-MM-DD"),
        });
      } else if (formType === "result") {
        await facility.setModellingPlanResult(data);
      }
      setIsFormPending(false);
      onCloseModal();
    },
    [onCloseModal, formType, facility]
  );

  const left = useMemo(() => {
    return (plan.start && (moment(plan.start).diff(startOfPeriod, "days") * 100) / 7) || 0;
  }, [plan.start, startOfPeriod]);

  const right = useMemo(() => {
    return (plan.finish && (endOfPeriod.diff(moment(plan.finish), "days") * 100) / 7) || 0;
  }, [plan.finish, endOfPeriod]);

  const color = useMemo(() => {
    let color = "blue";
    if (plan.isStarted) color = "orange";
    if (plan.isFinished) color = "green";
    return color;
  }, [plan]);

  return (
    <Box
      key={facility.id}
      sx={{
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          marginLeft: left > 0 ? `${left}%` : "-0.25rem",
          marginRight: right > 0 ? `${right}%` : "-0.25rem",
          display: "flex",
          flexDirection: "row",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            m: "0.125rem",
            alignItems: "center",
            padding: "0 0.5rem",
            border: (theme) => {
              return `0.0625rem solid ${theme.palette[color].main}`;
            },
            backgroundColor: (theme) => {
              return `${theme.palette[color].light}`;
            },
            color: (theme) => {
              return `${theme.palette[color].contrastText}`;
            },
            boxSizing: "border-box",
            borderRadius: "0.25rem",
            display: "flex",
            flex: 1,
            flexDirection: "row",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexShrink: 0,
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <FacilityRender isMinimal={false} isSmall={true} facility={facility} />
          </Box>
          {!plan.result && (
            <IconButton onClick={onSetResult} aria-label="fingerprint" color="inherit">
              <FileOpen />
            </IconButton>
          )}
          {!!plan.result && (
            <IconButton onClick={onSetShow} aria-label="fingerprint" color="inherit">
              <FileDownload />
            </IconButton>
          )}
          <IconButton onClick={onOpenModal} aria-label="fingerprint" color="inherit">
            <Edit />
          </IconButton>
        </Box>
      </Box>
      <Dialog open={modalVisible} onClose={onCloseModal}>
        <Form
          initialValues={{
            planId: `${plan.id}`,
            modellerId: `${plan.modeller.id}`,
            start: moment(plan.start).toDate(),
            finish: moment(plan.finish).toDate(),
          }}
          isPending={isFormPending}
          config={formType === "edit" ? editPlanFormConfig : resultFormConfig}
          onSubmit={onSubmit}
          onCancel={onCloseModal}
        />
      </Dialog>
      <Dialog maxWidth="sm" open={fileVisible} onClose={onCloseShow}>
        <DialogTitle>
          <Typography variant="h5">
            <Translator text={"Scan result"} />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body">
            <Translator text={"Scan result link is presented below"} />
          </Typography>
        </DialogContent>
        <DialogActions>
          {!!plan.result && (
            <Button
              sx={{
                overflowWrap: "anywhere",
                mr: "0.25rem",
              }}
              variant="outlined"
              href={plan.result.url}
              target="blank"
              color={"info"}
              startIcon={<FileOpen color={"info"} />}
            >
              <Typography variant="body">{plan.result.url}</Typography>
            </Button>
          )}
          <Button
            variant="outlined"
            onClick={onCloseShow}
            target="blank"
            color={"error"}
            startIcon={<Close color={"error"} />}
          >
            <Typography variant="body">Close</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
});

export default GantLinePLan;
