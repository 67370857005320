import React, { useState, useCallback, useMemo } from "react";
import { observer } from "mobx-react";

import moment from "moment";

import { FormDialog } from "~/components/dialogs";
import { Notify, Translator } from "~/components";

import useStores from "~/hooks";

/**
 * Диалог добавления расхода бранча.
 */
const AddingBranchExpenseDialog = observer(({ isOpen, onClose, branch }) => {
  const { expenseStore } = useStores();

  const [isNotifyOpen, setIsNotifyOpen] = useState(false);

  const isPending = expenseStore.isPending;
  const pendingText = expenseStore.pendingText;

  const config = useMemo(() => {
    if (!branch) return {};

    return {
      fields: [
        {
          name: "amount",
          title: "Expense amount",
          type: "number",
          viewConfig: "input",
          inputMode: "decimal",
          startAdornment: branch.currency,
          min: 1,
          step: 0.01,
          isRequired: true,
          isReadOnly: false,
          validate: true,
        },
        {
          name: "date",
          title: "Date",
          type: "date",
          disablePast: false,
          disableFuture: false,
          isRequired: true,
          isReadOnly: false,
          validate: true,
        },
        {
          name: "categoryId",
          fakeName: "category",
          title: "Category",
          type: "select",
          isRequired: true,
          isReadOnly: false,
          validate: true,
          loading: false,
          options: expenseStore.getCategories(branch.id, "branch"),
        },
        {
          name: "description",
          title: "Description",
          type: "text",
          multiline: true,
          isRequired: false,
          isReadOnly: false,
          validate: false,
        },
        {
          id: "documents",
          name: `documents`,
          title: "Documents",
          fileType: "documents",
          type: "file",
          multiple: true,
          min: 1,
          max: 4,
          isRequired: true,
          isReadOnly: false,
          validate: true,
        },
      ],
      submitText: "Add",
      cancelText: "Cancel",
    };
  }, [expenseStore, branch]);

  const handleSubmit = useCallback(
    async (data) => {
      const result = await expenseStore.createExpense(
        { ...data, date: moment(data.date, "L").format("YYYY-MM-DD") },
        branch
      );
      if (result) {
        onClose();
        setIsNotifyOpen(true);
      }
    },
    [branch, onClose, setIsNotifyOpen, expenseStore]
  );

  return (
    <>
      <FormDialog
        title={"Adding branch expense"}
        formConfig={config}
        isVisible={isOpen}
        closeDialog={onClose}
        handleSubmit={handleSubmit}
        isPending={isPending}
        pendingText={pendingText}
      />
      <Notify isOpen={isNotifyOpen} onClose={() => setIsNotifyOpen(false)}>
        <Translator text={"Branch expense added"} />
      </Notify>
    </>
  );
});

export default AddingBranchExpenseDialog;
