import { useMemo } from "react";
import { observer } from "mobx-react";
import { DataGrid } from "~/components/grid";
import { Translator as T } from "~/components";

import useStores from "~/hooks";

/**
 * Таблица перемещений внутри смены.
 */
const ShiftMovementsGrid = observer(({ shift }) => {
  const { languageStore: ls } = useStores();

  const getRowId = (row) => row.no;

  const columns = useMemo(
    () => [
      { field: "no", width: 50, headerName: ls.translate({ text: "#" }) },
      {
        field: "contractNumber",
        headerName: ls.translate({ text: "Contract number" }),
        width: 150,
        type: "string",
        renderCell: ({ row }) => <T text={row.contractNumber} />,
      },
      { field: "from", minWidth: 150, flex: 1, headerName: ls.translate({ text: "From location" }) },
      {
        field: "departure",
        headerName: ls.translate({ text: "Departure" }),
        width: 150,
        type: "datetime",
        renderCell: ({ row }) => <T datetime={row.departure} variant="compact" />,
      },
      { field: "to", minWidth: 150, flex: 1, headerName: ls.translate({ text: "To location" }) },
      {
        field: "arrival",
        headerName: ls.translate({ text: "Arrival" }),
        width: 150,
        type: "datetime",
        renderCell: ({ row }) => <T datetime={row.arrival} variant="compact" />,
      },

      {
        field: "mileage",
        width: 75,
        headerName: ls.translate({ text: "Mileage" }),
        type: "number",
      },
    ],
    [ls]
  );

  return <DataGrid getRowId={getRowId} columns={columns} rows={shift.pathSegments} autoHeight hideFooter />;
});

export default ShiftMovementsGrid;
