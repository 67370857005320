import { computed, makeObservable, observable } from "mobx";

class Scanner {
  @observable branch;
  @observable store;
  @observable id;
  @observable name;
  @observable serial;

  constructor(props, branch) {
    makeObservable(this);

    this.branch = branch;
    this.store = this.branch.store;
    this.id = `${props.id}`;
    this.name = props.name;
    this.serial = props.serial;
  }

  @computed get label() {
    return `${this.name} (${this.serial})`;
  }

  @computed get group() {
    return this.name;
  }

  @computed get value() {
    return this.id;
  }
}

export default Scanner;
