import { computed, makeObservable, observable, toJS } from "mobx";

// TODO: переименовать в Employee
class Manager {
  @observable store = null;
  @observable id = null;
  @observable firstname = null;
  @observable lastname = null;
  @observable email = null;
  @observable roles = [];

  constructor(props, store) {
    makeObservable(this);

    this.store = store;
    this.id = `${props.id}`;
    this.firstname = props.firstname;
    this.lastname = props.lastname;
    this.email = props.email;
    this.roles = props.roles || [];
  }

  @computed get fullName() {
    return `${this.lastname} ${this.firstname}`;
  }

  @computed get name() {
    return `${this.lastname} ${this.firstname.charAt(0)}.`;
  }

  @computed get label() {
    return this.fullName;
  }

  @computed get value() {
    return this.id;
  }

  @computed get rolesSet() {
    return new Set(toJS(this.roles));
  }

  /**
   * Массив объектов сканирования для пользователя.
   */
  @computed get scanPlanFacilities() {
    if (!this.isScanner) return [];
    const facilities = this.store.rootStore.facilityStore.facilitiesByUserId.get(this.id);
    if (!facilities) return [];
    return facilities;
  }

  @computed get modellingPlanFacilities() {
    if (!this.isRenderer) return [];
    const facilities = [];
    this.store.rootStore.facilityStore.facilitiesArray.forEach((facility) => {
      if (facility.modellingPlans && facility.modellingPlans.length) {
        facility.modellingPlans.forEach((plan) => {
          if (plan.modeller.id === this.id) {
            facilities.push(facility);
          }
        });
      }
    });
    return facilities;
  }

  @computed get isRenderer() {
    return this.rolesSet.has("modeller");
  }

  @computed get isScanner() {
    return this.rolesSet.has("scanner");
  }

  @computed get isManager() {
    return this.rolesSet.has("sales-manager");
  }

  @computed get isModelingManager() {
    return this.rolesSet.has("modelling-manager");
  }
}

export default Manager;
