import React from "react";
import { observer } from "mobx-react";
import { Box } from "@mui/material";

import useStores from "~/hooks";

import Schedule from "../modules/contracts/Schedule";

const ScannerSchedule = observer(() => {
  const { rootStore } = useStores();
  const { id } = rootStore;

  return (
    <Box
      sx={{
        flexDirection: "column",
        display: "flex",
        flex: 1,
        padding: "0.5rem",
        pt: "1rem",
        overflow: "hidden",
      }}
    >
      <Schedule role={"scanner"} employeeId={id} />
    </Box>
  );
});

export default ScannerSchedule;
