import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Box, Fab, Toolbar, Zoom } from "@mui/material";
import { Add } from "@mui/icons-material";
import { DataGrid } from "~/components/grid";
import { useTheme } from "@emotion/react";

import SearchField from "../../components/SearchField";
import useStores from "~/hooks";
import { Preloader, Translator } from "~/components";
import { CreateClientDialog } from "./components/dialogs";

/**
 * Таблица клиентов.
 */
const ClientsTable = observer(() => {
  const { clientStore, branchStore, rootStore } = useStores();
  const { branch } = branchStore;
  const [isPending, setIsPending] = useState(false);
  const { clientsArray } = clientStore;

  const [searchBy, setSearchBy] = useState("");

  // до отрисовки запросим первую страницу клиентов
  useEffect(() => {
    if (!branch) return;
    const init = async () => {
      setIsPending(true);
      await clientStore.fetchClientsForBranch(branch.id);
      setIsPending(false);
    };
    init();
  }, [branch, clientStore]);

  const [isCreateClientDialogOpen, setIsCreateClientDialogOpen] = useState(false);
  const openCreateClientDialog = useCallback(() => {
    setIsCreateClientDialogOpen(true);
    // console.log("setIsCreateClientDialogOpen");
  }, [setIsCreateClientDialogOpen]);
  const closeCreateClientDialog = useCallback(
    (e, reason) => {
      if (reason === "backdropClick") return;
      setIsCreateClientDialogOpen(false);
    },
    [setIsCreateClientDialogOpen]
  );

  if (isPending) return <Preloader isPending={true} label="Loading clients" />;

  const columns = [
    { field: "id", headerName: "ID", type: "number", width: 90 },
    {
      field: "name",
      headerName: <Translator text="Name" />,
      width: 500,
    },
    {
      field: "description",
      headerName: <Translator text="Description" />,
      width: 750,
    },
    // {
    //   field: "processingContractsCount",
    //   headerName: <Translator text="Processing" />,
    //   width: 100,
    //   type: "number",
    // },
    // {
    //   field: "closedContractsCount",
    //   headerName: <Translator text="Closed" />,
    //   width: 100,
    //   type: "number",
    // },
    // {
    //   field: "cancelledContractsCount",
    //   headerName: <Translator text="Cancelled" />,
    //   width: 100,
    //   type: "number",
    // },
    // {
    //   field: "payed",
    //   headerName: <Translator text="Payed" />,
    //   width: 150,
    //   type: "number",
    // },
    // {
    //   field: "debt",
    //   headerName: <Translator text="Debt" />,
    //   width: 150,
    //   type: "number",
    // },
  ];

  const theme = useTheme();
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  // Делаем поиск
  const onSearch = (value) => {
    if (value.length > 2) {
      setSearchBy(value);
    } else {
      setSearchBy("");
    }
  };

  // TODO: унести это на бекенд
  // const regex = new RegExp(searchBy, "i");
  // const clients = clientsArray.filter((c) => {
  //   return c.name.match(regex) || c.description.match(regex);
  // });

  const clients = clientsArray;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", flex: 1, padding: 1 }}>
      <Toolbar sx={{ marginBottom: 1 }}>
        <SearchField label={<Translator text={"Search client"} />} onSearch={onSearch} />
      </Toolbar>
      <DataGrid rows={clients} columns={columns} autoPageSize pagination loading={isPending} rowsPerPageOptions={[5]} />
      <Zoom
        in={true}
        timeout={transitionDuration}
        style={{ transitionDelay: `${transitionDuration.exit}ms` }}
        unmountOnExit
      >
        <Fab
          key="fab"
          size={"medium"}
          sx={{ position: "fixed", bottom: 86, right: 32 }}
          color="primary"
          aria-label="Add"
          onClick={openCreateClientDialog}
        >
          <Add />
        </Fab>
      </Zoom>
      <CreateClientDialog isOpen={isCreateClientDialogOpen} onClose={closeCreateClientDialog} />
    </Box>
  );
});

export default ClientsTable;
