import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { Box } from "@mui/material";
import moment from "moment";

/**
 * Сетка планирования.
 */
const ScaleGridBox = observer(({ index, onItemClick, newEmployeeId, time }) => {
  const onClick = useCallback(() => {
    onItemClick({ time, newEmployeeId });
  }, [onItemClick, time, newEmployeeId]);

  const disabled = time <= moment();

  return (
    <Box
      sx={{
        height: "2rem",
        cursor: disabled ? "default" : "pointer",
        borderTop: index === 0 ? "none" : "0.0625rem solid rgba(192,192,192,0.6)",
        display: "flex",
        boxSizing: "borderBox",
        flexShrink: 0,
        backgroundColor: (theme) => {
          return disabled ? "rgba(192,192,192,0.2)" : null;
        },
        "&:hover": {
          backgroundColor: (theme) => {
            return disabled ? "rgba(192,192,192,0.4)" : theme.palette.info.light;
          },
        },
      }}
      onClick={disabled ? undefined : onClick}
    ></Box>
  );
});

export default ScaleGridBox;
