import { makeObservable, observable } from "mobx";

/**
 * Результат моделирования.
 */
export default class ModellingResult {
  @observable id;
  @observable url = "";

  @observable plan;

  /**
   * {
   *  "id": 9,
   *  "url": "https://scanm2.it/it"
   * }
   * @param {ModellingPlan} plan план моделирования
   * @param {object} props свойства объекта результата моделирования
   */
  constructor(plan, props) {
    makeObservable(this);

    const { id, url } = props;
    this.id = id;
    this.url = url;

    this.plan = plan;
  }
}
