import React, { useCallback } from "react";
import CheckIcon from "@mui/icons-material/Check";
import Alert from "@mui/material/Alert";

import { Snackbar } from "@mui/material";

/**
 * Компонет натификации пользователя.
 *
 * Всплывёт вверху по центру, сообщит пользователю children.
 */
const Notify = ({ children, isOpen, onClose, autoHideDuration }) => {
  const closeCallback = useCallback(() => {
    if (onClose) onClose();
  }, [onClose]);

  return (
    <Snackbar
      ContentProps={{ sx: { background: "#b6d7a8", color: "#333", fontSize: "1.125rem", border: "1px solid #93c47d" } }}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={isOpen}
      autoHideDuration={autoHideDuration || 2000}
      onClose={closeCallback}
      message={children}
    >
      <Alert onClose={closeCallback} severity="success" variant="filled" sx={{ width: "100%", m: 2 }}>
        {children}
      </Alert>
    </Snackbar>
  );
};

export default Notify;
