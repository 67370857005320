import { action, computed, makeObservable, observable, runInAction } from "mobx";
import Client from "./models/Client";

import { ClientsApiV0 } from "~/modules/clients/api";

/**
 * Стор клиентов.
 */
class ClientStore {
  @observable rootStore = null;
  @observable api = null;
  @observable clientsMap = new Map();
  @observable pendingClientsMap = new Map();
  @observable pending = false;
  @observable fetchClientsForBranchAbortController = null;

  constructor(rootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.api = new ClientsApiV0(rootStore.authStore);
  }

  /**
   * Добавить клиента в стор создав его из данных.
   *
   * @param {object} data данные клиента
   * @returns Client созданного и привязанного к стору клиента.
   */
  @action addClient(data) {
    const client = new Client(data, this);
    this.clientsMap.set(`${client.id}`, client);
    this.pendingClientsMap.delete(client.id);
    return client;
  }

  /**
   * Создать нового клиента.
   *
   * @param {Branch} branch филиал
   * @param {object} data данные
   * @returns
   */
  @action async createClient(branch, data) {
    this.setPending(true);
    let clientData;
    try {
      clientData = await this.api.createClient(branch.id, data);
    } catch (error) {
      this.rootStore.setError("error", null, error && error.message);
    }
    const client = this.addClient(clientData);
    this.setPending(false);
    return client;
  }

  /**
   * Запросить список клиентов филиала.
   *
   * @param {Branch} branch экземпляр текущего бранча
   * @returns
   */
  @action async fetchClientsForBranch(branch) {
    if (!branch) return;
    this.setPending(true);
    const clientsData = await this.api.getClientsForBranch(branch);
    runInAction(() => {
      this.clientsMap.clear();
      clientsData.forEach((item) => {
        this.addClient(item);
      });
      this.setPending(false);
    });

    // if (branch) {
    //   if (this.fetchClientsForBranchAbortController) {
    //     this.fetchClientsForBranchAbortController.abort();
    //     this.fetchClientsForBranchAbortController = null;
    //   }
    //   try {
    //     this.setPending(true);
    //     this.clientsMap.clear();
    //     const clientsData = await this.fetchClientsForBranchWithAbortControll(branch);
    //     clientsData.forEach((item) => {
    //       this.addClient(item);
    //     });
    //   } catch (error) {
    //     console.warn(error);
    //   }
    // }
  }

  @action getClientById(id) {
    return this.clientsMap.get(`${id}`);
  }

  // @action async getClientByIdAsync(id) {
  //   if (this.pendingClientsMap.get(`${id}`)) {
  //     return this.pendingClientsMap.get(`${id}`);
  //   }
  //   const promise = this.fetchClientByIdAsync(id);
  //   this.pendingClientsMap.set(`${id}`, promise);
  //   return promise;
  // }

  // @action async fetchClientByIdAsync(id) {
  //   const clientData = await this.api.getClientById(id);
  //   const client = this.addClient(clientData);
  //   return client;
  // }

  @action setPending(pending = false) {
    this.pending = pending;
  }

  // @action async fetchClientsForBranchWithAbortControll(branch) {
  //   if (branch) {
  //     const error = new Error("Fetch aborted by the user", "AbortError");
  //     this.fetchClientsForBranchAbortController = new AbortController();
  //     this.fetchClientsForBranchAbortController.signal.addEventListener("abort", () => {
  //       throw error;
  //     });
  //     if (this.fetchClientsForBranchAbortController.signal.aborted) {
  //       throw error;
  //     }
  //     return await this.api.getClientsForBranch(branch, this.rootStore.isLocal);
  //   }
  // }

  @computed
  get currentBranchId() {
    return this.rootStore.branchStore.currentBranchId;
  }

  @computed
  get isPending() {
    return this.pending;
  }

  @computed get clientsArray() {
    return Array.from(this.clientsMap.values());
  }

  // @computed
  // get clientIdsArray() {
  //   return Array.from(this.clientsMap.keys());
  // }
}

export default ClientStore;
