import { observer } from "mobx-react";

import { TextField, InputAdornment } from "@mui/material";

import useStores from "~/hooks";

/**
 * Readonly field as TextField in standard variant.
 */
const InfoField = observer(({ label, value, icon }) => {
  const { languageStore: ls } = useStores();
  label = ls.translate({ text: label });
  const inputProps = {
    readOnly: true,
  };
  if (!!icon) {
    inputProps["startAdornment"] = <InputAdornment position="start">{icon}</InputAdornment>;
  }
  return (
    <TextField
      sx={{
        width: "25ch",
        m: 1,
        "& .MuiInputLabel-root": { fontSize: "12pt" },
        "& .MuiInput-input": { fontWeight: 500 },
      }}
      variant="standard"
      label={label}
      defaultValue={value}
      InputProps={inputProps}
    />
  );
});

export default InfoField;
