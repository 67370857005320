import React, { useMemo, useState, useCallback } from "react";
import { observer } from "mobx-react";

import useStores from "~/hooks";

import { FormDialog } from "~/components/dialogs";
import { Translator, Notify } from "~/components";

/**
 * Диалог добавления ссылки на результат сканирования.
 */
const UploadingRawResultDialog = observer(({ plan, isOpen, onClose }) => {
  const [backendError, setBackendError] = useState("");
  const [isNotifyOpen, setIsNotifyOpen] = useState(false);

  const { scanningPlansStore } = useStores();

  const isPending = scanningPlansStore.isPending;
  const pendingText = scanningPlansStore.pendingText;

  const handleSubmit = useCallback(
    async (data) => {
      await scanningPlansStore.uploadScanningRawResultUrl(plan, data["rawCloud"]);
      setIsNotifyOpen(true);
      onClose();
    },
    [plan, onClose, scanningPlansStore]
  );

  const config = useMemo(() => {
    return {
      fields: [
        {
          name: "rawCloud",
          title: "Url",
          type: "url",
          pattern: `http(s)*://.+\..+`, // eslint-disable-line
          viewConfig: "input",
          isRequired: true,
          isReadOnly: false,
          validate: true,
        },
      ],
      submitText: "Submit",
      cancelText: "Cancel",
    };
  }, []);

  if (!plan) return;

  return (
    <>
      <FormDialog
        title={"Adding raw result"}
        formConfig={config}
        isVisible={isOpen}
        closeDialog={onClose}
        handleSubmit={handleSubmit}
        isPending={isPending}
        errorText={backendError}
        pendingText={pendingText}
      />
      <Notify isOpen={isNotifyOpen} onClose={() => setIsNotifyOpen(false)}>
        <Translator text={"Raw result added"} />
      </Notify>
    </>
  );
});

export default UploadingRawResultDialog;
