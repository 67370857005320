import { computed, makeObservable, observable } from "mobx";
import moment from "moment";

class ContractHistoryEntry {
  @observable
  store = null;
  @observable
  contract = null;

  @observable
  datetime = null;
  @observable
  reason = null;
  @observable
  type = null;
  @observable
  userId = null;

  @observable
  index = null;

  @observable
  state = null;

  constructor(props, index, contract) {
    makeObservable(this);

    this.contract = contract;
    this.store = this.contract.store;

    const { history, state } = props;

    this.datetime = moment(history.datetime);
    this.reason = history.reason;
    this.type = history.type;
    this.userId = history.userId;

    this.index = index;

    this.state = state;
  }

  @computed get previous() {
    return (
      (this.contract.historyRecords.length > this.index + 1 && this.contract.historyRecords[this.index + 1]) || null
    );
  }

  @computed get key() {
    return this.datetime.toISOString();
  }

  @computed
  get user() {
    return (
      this.store.rootStore.employeesStore.getEmployeeById(this.userId) || {
        fullName: "Unknown user",
      }
    );
  }

  @computed
  get branch() {
    const branch = this.store.rootStore.branchStore.getBranchById(this.state.branchId);
    const label = (branch && branch.label) || "Unknown branch";
    const changed = !this.previous || this.state.branchId !== this.previous.state.branchId;
    return {
      has: !!this.state.branchId,
      label,
      changed,
    };
  }

  @computed
  get client() {
    const client = this.store.rootStore.clientStore.getClientById(this.state.clientId);
    const label = (client && client.label) || "Unknown client";
    const changed = !this.previous || this.state.clientId !== this.previous.state.clientId;
    return {
      has: !!this.state.clientId,
      label,
      changed,
    };
  }

  @computed
  get status() {
    const label = this.state.state;
    const changed = !this.previous || this.state.state !== this.previous.state.state;
    return {
      has: !!this.state.state,
      label,
      changed,
    };
  }

  @computed
  get number() {
    const label = this.state.number;
    const changed = !this.previous || this.state.number !== this.previous.state.number;
    return {
      has: !!this.state.number,
      label,
      changed,
    };
  }

  @computed
  get date() {
    const label = this.state.date;
    const changed = !this.previous || this.state.date !== this.previous.state.date;
    return {
      has: !!this.state.date,
      label,
      changed,
    };
  }

  @computed
  get description() {
    const label = this.state.description;
    const changed = !this.previous || this.state.description !== this.previous.state.description;
    return {
      has: !!this.state.description,
      label,
      changed,
    };
  }

  @computed
  get value() {
    const amount = this.state.value && this.state.value.amount;
    const currency = this.state.value && this.state.value.currency;
    const changed =
      !this.previous ||
      !this.previous.state.value ||
      this.state.value.amount !== this.previous.state.value.amount ||
      this.state.value.currency !== this.previous.state.value.currency;
    return {
      has: !!amount,
      amount,
      currency,
      changed,
    };
  }

  @computed
  get rawCloud() {
    const label = this.state.rawCloud;
    const changed = !this.previous || this.state.rawCloud !== this.previous.state.rawCloud;
    return {
      has: !!this.state.rawCloud,
      deleted: !this.state.rawCloud && !!this.previous && !!this.previous.state.rawCloud,
      label,
      changed,
    };
  }

  @computed
  get createdAt() {
    const label = this.state.createdAt;
    const changed = !this.previous || this.state.createdAt !== this.previous.state.createdAt;
    return {
      has: !!this.state.createdAt,
      label,
      changed,
    };
  }

  @computed
  get linkedCloud() {
    const label = this.state.linkedCloud;
    const changed = !this.previous || this.state.linkedCloud !== this.previous.state.linkedCloud;

    return {
      has: !!this.state.linkedCloud,
      deleted: !this.state.linkedCloud && !!this.previous && !!this.previous.state.linkedCloud,
      label,
      changed,
    };
  }

  @computed
  get linkedAt() {
    const label = this.state.linkedAt;
    const changed = !this.previous || this.state.linkedAt !== this.previous.state.linkedAt;
    return {
      has: !!this.state.linkedAt,
      label,
      changed,
    };
  }
}

export default ContractHistoryEntry;
