import { makeObservable, observable, action, runInAction } from "mobx";

import Resizer from "react-image-file-resizer";

import PhotoApiV0 from "./api/PhotosApiV0";

/** Стор фотографий.
 *
 * Умеет ходить на бекенд за блобами картинок.
 */
export default class PhotoStore {
  @observable api;
  @observable isPending;

  constructor(root) {
    makeObservable(this);
    this.root = root;
    this.isPending = false;

    this.api = new PhotoApiV0(this.root.authStore);
  }

  resizeImage(file) {
    return new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        1024,
        1024,
        "JPEG",
        100,
        0,
        (uri) => resolve(uri),
        "file"
      );
    });
  }

  /**
   * Creates promise of file upload.
   * 
   * @param {*} file 
   * @returns 
   */
  async uploadPhotoPromise(file) {
    const resized = await this.resizeImage(file);
    return this.api.uploadPhoto(resized);
  }

  /**
   * Uploads files 1 by 1.
   * 
   * @param {[File]} files array of files for upload
   * @returns array of files ids
   */
  async uploadPhotos(files = []) {
    runInAction(() => (this.isPending = true));
    const promises = [];
    for (const file of files) {
      promises.push(this.uploadPhotoPromise(file));
    }
    const uploadResults = await Promise.all(promises);
    runInAction(() => (this.isPending = false));
    return uploadResults.map((x) => x.id);
  }

  /**
   * Загрузить blob фотографии с бекенда.
   *
   * @param {number} id идентификатор фотографии
   * @returns
   */
  @action async fetchPhotoBlob(id) {
    runInAction(() => (this.isPending = true));
    const photo = await this.api.getPhoto(id);
    const blob = await photo.blob();
    runInAction(() => (this.isPending = false));
    return blob;
  }
}
