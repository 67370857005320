import { action, computed, observable, makeObservable } from "mobx";

class File {
  @observable name = "";
  @observable size = 0;
  @observable mimetype = "";

  constructor(props) {
    makeObservable(this);

    const { name, size, mimetype } = props;
    this.name = name;
    this.size = size;
    this.mimetype = mimetype;
  }

  /**
   * Creates new File instance
   */
  static create(props) {
    return new File(props);
  }
}

export default File;
