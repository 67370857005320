import React, { useMemo, useCallback, useLayoutEffect } from "react";
import { observer } from "mobx-react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";

import Preloader from "~/components/Preloader";
import { Translator } from "../../../components";
import useStores from "~/hooks";

/**
 * Переключатель филиалов.
 */
const BranchSwitcher = observer(() => {
  const { workspaceStore, branchStore, invoiceStore, authStore } = useStores();
  const { theme } = workspaceStore;
  const { branch, isPending, branches } = branchStore;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // // TODO: это нужно делать после логина вне зависимости от того есть компонент или нет.
  // useLayoutEffect(() => {
  //   if (!authStore.token) return;
  //   const init = async () => {
  //     await branchStore.getBranches();
  //   };
  //   if (branch) return;
  //   init(); // только если нет бранча и есть токен
  // }, [branchStore, invoiceStore, branch, authStore]);

  const changeBranch = useCallback((event) => branchStore.setBranch(event.target.value), [branchStore]);

  const menuItems = useMemo(() => {
    return branches.map((item) => (
      <MenuItem key={item.id} value={item.id}>
        {item.icon} {!isMobile && item.name}
      </MenuItem>
    ));
  }, [branches, isMobile]);

  const branchId = useMemo(() => branch?.id || "", [branch]);

  return (
    <>
      {!isPending && (
        <FormControl
          sx={{
            m: 0,
            p: "0 0.25rem",
            position: "relative",
            maxHeight: "2.5rem",
          }}
        >
          <InputLabel id="select-branch-label">
            <Translator text="Branch" />
          </InputLabel>
          <Select
            sx={{ maxHeight: "2.5rem" }}
            labelid={"select-branch-label"}
            label={<Translator text="Branch" />}
            value={branchId}
            onChange={changeBranch}
          >
            {menuItems}
          </Select>
        </FormControl>
      )}
      <Preloader sx={{ maxWidth: "4rem" }} color="warning" size="2rem" isPending={isPending} />
    </>
  );
});

export default BranchSwitcher;
