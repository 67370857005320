import { Close, Download, FilePresent } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useState } from "react";

import useStores from "~/hooks";
import Preloader from "./Preloader";
import Translator from "./Translator";

const FileView = observer(({ file, type, isPreviewVisible, onHidePreview, noPreload }) => {
  const { rootStore } = useStores();

  const [fileData, setFileData] = useState(null);
  const [isPending, setIsPending] = useState(null);

  const isPreviewable = useMemo(() => {
    if (
      file &&
      file.file &&
      file.file.mimetype &&
      (file.file.mimetype === "application/pdf" || file.file.mimetype.split("/")[0] === "image")
    ) {
      return true;
    }
    return false;
  }, [file]);

  const getFile = useCallback(
    async (id) => {
      if (id) {
        setIsPending(true);
        let fileData = null;
        if (type === "documents") {
          fileData = await rootStore.getDocument(id);
        }
        if (type === "photos") {
          fileData = await rootStore.getPhoto(id);
        }
        let imageBlob = null;
        if (fileData) {
          imageBlob = await fileData.blob();
        }
        if (imageBlob) {
          setFileData(imageBlob);
        }
        setIsPending(false);
        return imageBlob;
      }
      return false;
    },
    [setFileData, setIsPending]
  );

  useEffect(() => {
    if ((!noPreload || (isPreviewVisible && !fileData && !isPending)) && !fileData && isPreviewable && file) {
      getFile(file.id);
    }
  }, [file, isPreviewable, getFile, noPreload, isPreviewVisible, fileData, isPending]);

  const onSaveFile = useCallback(async () => {
    if (!!file && !isPending) {
      let data = fileData;
      if (!data) {
        data = await getFile(file.id);
      }
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      const url = URL.createObjectURL(data);
      a.href = url;
      a.download = file.file.name;
      a.click();
    }
  }, [fileData, file]);

  if (!file || !file.file) {
    return null;
  }

  return (
    <Dialog open={isPreviewVisible} maxWidth={"xl"} fullWidth={true} onClose={onHidePreview}>
      <DialogContent>
        <Card>
          {fileData && !isPending && isPreviewable && (
            <CardMedia
              sx={{ minHeight: "75vh", flex: 1, flexShrink: 0 }}
              component={file.file.mimetype === "application/pdf" ? "iframe" : "img"}
              src={URL.createObjectURL(fileData)}
              alt={file.file.name}
            />
          )}
          {((!fileData && !isPending) || !isPreviewable) && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FilePresent color="info" sx={{ fontSize: "5rem" }} />
            </Box>
          )}
          <Preloader size={"4rem"} isPending={isPending} sx={{ marginTop: "3rem" }} />
          <CardContent>
            <Typography sx={{ fontSize: "0.75rem" }}>
              <Translator text="File name" />:
            </Typography>
            <Typography>{file.file.name}</Typography>
            <Divider />
            <Typography sx={{ fontSize: "0.75rem" }}>
              <Translator text="Created" />:
            </Typography>
            <Typography>
              <Translator variant="compact" datetime={file.createdAt} />
            </Typography>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button startIcon={<Download />} onClick={onSaveFile}>
          <Translator text="Download" />
        </Button>
        <Button color="error" startIcon={<Close />} onClick={onHidePreview}>
          <Translator text="Close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default FileView;
