import ApiBase from "~/api/ApiBase";

/**
 * API клиент оборудования версии 0.
 */
class EquipmentApiV0 extends ApiBase {
  /**
   * Запросить сканеры доступные в филиале.
   *
   * @param {number} branchId идентификатор филиала
   */
  async fetchBranchScanners(branchId) {
    const url = `api/v0/branches/${branchId}/equipment/scanners`;
    const [data] = await this.get(url);
    return data;
  }

  /**
   *  Запросить автомобили доступные в филиале.
   *
   * @param {number} branchId идентификатор филиала
   * @returns
   */
  async fetchBranchCars(branchId) {
    const url = `api/v0/branches/${branchId}/equipment/cars`;
    const [data] = await this.get(url);
    return data;
  }
}

export default EquipmentApiV0;
