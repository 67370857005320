import React, { useCallback, useMemo } from "react";
import { observer } from "mobx-react";
import { Box, Tooltip, Typography } from "@mui/material";
import Translator from "../../components/Translator";

import useStores from "~/hooks";

import Preloader from "../../components/Preloader";
import ScaleGridBox from "./ScaleGridBox";
import { Delete, Scanner } from "@mui/icons-material";
import SchedulePlan from "./SchedulePlan";

/**
 * Колонка расписания.
 *
 * Вертикальный элемент.
 */

// TODO: разнести в разные компоненты колонку для сканирования и
// колонку для моделирования
const ScheduleColumn = observer(
  ({ employeeId, employee, planStart, planFinish, start, end, role, workHours, isPending, onItemClick }) => {
    const { rootStore } = useStores();
    const { permissionsSet } = rootStore.authStore;

    // TODO: убрать из User, вынести в scanningPlansStore?
    const { scanPlanFacilities, renderPlanFacilities } = employee;

    const numHours = useMemo(() => {
      return Number(end.diff(start, "day") + 1) * (workHours.to - workHours.from + 1);
    }, [start, end, workHours]);

    const grid = useMemo(() => {
      const grid = [];
      const realEnd = end.clone().startOf("day").set("hour", workHours.to);
      const cursor = start.clone().startOf("day").set("hour", workHours.from);
      let index = 0;
      while (cursor <= realEnd) {
        grid.push(
          <ScaleGridBox
            key={`grid-${index}-${employee.id}`}
            index={index}
            onItemClick={onItemClick}
            newEmployeeId={employee.id}
            time={cursor.clone()}
          />
        );
        cursor.add(1, "hour");
        index += 1;
        if (cursor.hour() > workHours.to) {
          cursor.set("hour", workHours.from).add(1, "day");
        }
      }
      return grid;
    }, [start, end, workHours, employee, onItemClick]);

    const onClear = useCallback(() => {
      onItemClick({ time: null });
    }, [onItemClick]);

    const plans = useMemo(() => {
      let array = [];
      if (role === "scanner") {
        array = scanPlanFacilities;
      }
      if (role === "modeller") {
        array = renderPlanFacilities;
      }
      return array.map((facility) => {
        const { scanningPlan, renderPlan } = facility;

        let plan = null;
        if (role === "scanner") {
          plan = scanningPlan;
        }
        if (role === "modeller") {
          plan = renderPlan;
        }

        if (plan) {
          const canDeletePlan = permissionsSet.has(`facilities.delete_facilityscanningplan`) && !plan.isStarted;

          const top =
            plan.start.diff(start, "days") * (workHours.to - workHours.from + 1) + (plan.start.hour() - workHours.from);
          const height =
            plan.finish.diff(plan.start, "days") * (workHours.to - workHours.from + 1) +
            (plan.finish.hour() - plan.start.hour());
          // console.log(top, height, plan.id, plan.facility.id, canDeletePlan);
          return (
            // TODO: сюда нужно передать время и считать остальное внутри
            <SchedulePlan key={plan.id} plan={plan} height={2 * height} top={2 * top} canDeletePlan={canDeletePlan} />
          );
        }
      });
    }, [scanPlanFacilities, renderPlanFacilities, role, permissionsSet, start, workHours.from, workHours.to]);

    // Этот код выполняется при создании нового плана
    const newPlan = useMemo(() => {
      if (employeeId !== employee.id || !planStart || !planFinish) {
        return null;
      }
      const top =
        planStart.diff(start, "days") * (workHours.to - workHours.from + 1) + (planStart.hour() - workHours.from);
      const height =
        planFinish.clone().endOf("day").diff(planStart, "days") * (workHours.to - workHours.from + 1) +
        (planFinish.hour() - planStart.hour());

      return (
        <Box
          key={"new-plan"}
          sx={{
            position: "absolute",
            height: `${2 * height}rem`,
            top: `${2 * top}rem`,
            width: "100%",
            display: "flex",
            flexShrink: 0,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            onClick={onClear}
            sx={{
              position: "absolute",
              top: "0.125rem",
              left: "0.125rem",
              right: "0.125rem",
              bottom: "0.125rem",
              flexWrap: "wrap",
              p: "0.125rem",
              display: "flex",
              borderRadius: "0.5rem",
              cursor: "pointer",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: (theme) => {
                return theme.palette.success.light;
              },
              ":hover": {
                backgroundColor: (theme) => {
                  return theme.palette.error.light;
                },
                ".scan-icon-holder": {
                  display: "none",
                },
                ".delete-icon-holder": {
                  display: "block",
                },
              },
              ".scan-icon-holder": {
                display: "block",
              },
              ".delete-icon-holder": {
                display: "none",
              },
            }}
          >
            <Scanner className="scan-icon-holder" sx={{ width: "1.5rem", height: "1.5rem" }} color="action" />
            <Delete className="delete-icon-holder" sx={{ width: "1.5rem", height: "1.5rem" }} color="action" />
          </Box>
        </Box>
      );
    }, [planStart, planFinish, employeeId, employee.id, start, onClear, workHours.from, workHours.to]);

    return (
      <Box
        sx={{
          flexDirection: "column",
          flexShrink: 0,
          minWidth: "12rem",
          flex: 1,
          display: "flex",
          borderBottom: "0.0625rem solid rgba(192,192,192,0.6)",
        }}
      >
        <Box
          sx={{
            height: "2rem",
            borderBottom: "0.0625rem solid rgba(192,192,192,0.6)",
            borderRight: "0.0625rem solid rgba(192,192,192,0.6)",
            p: "1.25rem 0.5rem",
            display: "flex",
            flexShrink: 0,
            alignItems: "center",
            whiteSpace: "nowrap",
            justifyContent: "center",
            fontSize: "1.25rem",
            fontWeight: "bold",
          }}
        >
          <Translator text={employee.name} />
        </Box>
        <Box
          sx={{
            flexDirection: "column",
            flexShrink: 0,
            flex: 1,
            borderRight: "0.0625rem solid rgba(192,192,192,0.6)",
            position: "relative",
            overflow: "visible",
            height: `${numHours}rem`,
            display: "flex",
          }}
        >
          <Preloader key="preloader" isPending={isPending} />
          {!isPending && grid}
          {!isPending && plans}
          {!isPending && newPlan}
        </Box>
        <Box
          sx={{
            height: "2rem",
            borderBottom: "0.0625rem solid rgba(192,192,192,0.6)",
            borderRight: "0.0625rem solid rgba(192,192,192,0.6)",
            p: "1.25rem 0.5rem",
            display: "flex",
            flexShrink: 0,
            alignItems: "center",
            whiteSpace: "nowrap",
            justifyContent: "center",
            fontSize: "1.25rem",
            fontWeight: "bold",
          }}
        >
          <Translator text={employee.name} />
        </Box>
      </Box>
    );
  }
);

export default ScheduleColumn;
