import { useMemo } from "react";
import { observer } from "mobx-react";

import { Box, Typography } from "@mui/material";
import { Engineering, AccountCircle, AccessTime } from "@mui/icons-material";

import Translator from "../../../components/Translator";
import { Fields } from "~/components";

import useStores from "~/hooks";
import ShiftLocationsGrid from "./ShiftMovementsGrid";

/**
 * Карточка рабочей смены на общественном транспорте.
 *
 * @param {*} shift Экземпляр модели смены.
 * @returns
 */
const PublicTransportShiftDetails = observer(({ shift }) => {
  const { languageStore: ls } = useStores();

  const shiftStartedAt = useMemo(
    () => ls.translate({ datetime: shift.startedAt, variant: "compact" }),
    [ls, shift.startedAt]
  );

  const shiftFinishedAt = useMemo(
    () => ls.translate({ datetime: shift.finishedAt, variant: "compact" }),
    [ls, shift.finishedAt]
  );

  return (
    <Box sx={{ padding: 1 }}>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Engineering sx={{ marginRight: 1 }} />
        <Typography variant="h6">
          <Translator text="Working shift" />
          &nbsp;#{shift.id}
        </Typography>
      </Box>
      {/* Информация о смене */}
      <Box sx={{ display: "flex", flex: 1, flexDirection: "column", mr: 0, minWidth: "50%" }}>
        <Box id="car-shift-deatils_fields" sx={{ display: "flex", flexDirection: "row", flex: 1 }}>
          <Box>
            <Fields.InfoField label="Employee" icon={<AccountCircle />} value={shift.employee.fullName} />
          </Box>
          <Box>
            <Box>
              <Fields.InfoField label="Started at" icon={<AccessTime />} value={shiftStartedAt} />
              <Fields.InfoField label="Finished at" icon={<AccessTime />} value={shiftFinishedAt} />
            </Box>
          </Box>
        </Box>
        {/* Таблица перемещений внутри смены. */}
        <ShiftLocationsGrid shift={shift} />
      </Box>
    </Box>
  );
});

export default PublicTransportShiftDetails;
