import React, { useState, useCallback, useMemo } from "react";
import { observer } from "mobx-react";
import moment from "moment";

import { Dialog, DialogTitle } from "@mui/material";

import useStores from "~/hooks";
import Form from "~/components/Form";
import Preloader from "../../../components/Preloader";
import Translator from "../../../components/Translator";
import { Notify } from "~/common/components";
/**
 * Диалог изменения коммерческого предложения.
 */
const EditCommercialOfferDialog = observer(({ offer, isVisible, setIsVisible }) => {
  const { branchStore, clientStore, commercialOffersStore } = useStores();
  const [isPending, setIsPending] = useState(false);
  const [isNotifyOpen, setIsNotifyOpen] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const { branch } = branchStore;

  // Редактировать offer
  const handleSubmit = useCallback(
    (data) => {
      // TODO: ai: а вот эти превращения значений должны быть в филде!
      const date = moment(data.date, "L").format("YYYY-MM-DD");
      const value = {
        amount: Number(data.value),
        currency: branch.currency,
      };
      data = { ...data, value, date };
      async function edit(data) {
        setIsPending(true);
        await commercialOffersStore.editOffer(branch, offer, data);
        setIsPending(false);
        setIsNotifyOpen(true);
        setIsVisible(false);
      }
      edit(data);
    },
    [commercialOffersStore, offer, setIsVisible, branch]
  );

  // Закрывать диалог только по клику в кнопку и никак не реагировать на клик в фон
  const closeDialog = useCallback(
    (e, reason) => {
      if (reason === "backdropClick") return;
      // на закрытии формы удалим номер т.к. его не использовали
      setIsVisible(false);
    },
    [setIsVisible]
  );

  const clientsOptions = useMemo(
    () =>
      clientStore.clientsArray.map((client) => {
        return { id: client.id, label: client.name, value: client.id };
      }),
    [clientStore.clientsArray]
  );

  const config = useMemo(() => {
    return {
      submitText: "Save",
      cancelText: "Cancel",
      fields: [
        {
          name: "changeReason",
          title: "Change reason",
          type: "string",
          isRequired: true,
          isReadOnly: false,
          isPending: isPending,
        },
        {
          name: "numberVisible",
          title: "Number",
          type: "string",
          isRequired: true,
          isReadOnly: true,
          initialValue: offer?.number,
          isPending: isPending,
        },
        {
          name: "number",
          type: "hidden",
          initialValue: offer?.number,
        },
        {
          name: "date",
          title: "Date",
          type: "date",
          disablePast: false,
          disableFuture: true,
          isRequired: true,
          isReadOnly: false,
          validate: true,
          initialValue: offer?.date,
        },
        {
          name: "clientId",
          fakeName: "client",
          title: "Client",
          type: "autocomplete",
          withCreate: true,
          isRequired: true,
          isReadOnly: false,
          validate: true,
          loading: clientStore.isPending,
          options: clientsOptions,
          initialValue: `${offer?.client?.id}`,
        },
        {
          name: "clientName",
          title: "Client name",
          type: "text",
          multiline: false,
          isRequired: true,
          isReadOnly: false,
          validate: true,
          revealFunction: (values) => values["clientId"] === "create new",
        },
        {
          name: "clientDescription",
          title: "Client description",
          type: "text",
          multiline: true,
          isRequired: false,
          isReadOnly: false,
          validate: true,
          revealFunction: (values) => values["clientId"] === "create new",
        },
        // TODO: ai: А такой должен быть MoneyField!
        {
          name: "value",
          title: "Value without VAT",
          type: "number",
          viewConfig: "input",
          inputMode: "decimal",
          min: 1,
          step: 0.01,
          startAdornment: branch?.currency,
          isRequired: true,
          isReadOnly: false,
          validate: true,
          initialValue: offer?.value.amount,
        },
        {
          name: "crmLink",
          title: "CRM link",
          type: "url",
          multiline: false,
          isRequired: true,
          isReadOnly: false,
          validate: true,
          initialValue: offer?.crmLink,
        },
        {
          name: "description",
          title: "Description",
          type: "text",
          multiline: true,
          isRequired: false,
          isReadOnly: false,
          validate: false,
          initialValue: offer?.description,
        },
        {
          id: "documents",
          name: "documents",
          title: "Documents",
          type: "file",
          multiple: true,
          isRequired: false,
          isReadOnly: false,
          validate: true,
          initialValue: offer?.documents,
        },
      ],
    };
  }, [offer, isPending, clientsOptions, clientStore.isPending, branch?.currency]);

  return (
    <>
      <Dialog open={isVisible} onClose={closeDialog}>
        <DialogTitle sx={{ display: "flex", dlexDirection: "row", justifyContent: "space-between" }}>
          <Translator text="Editing commercial offer" />
          <Preloader isPending={isPending} size={"2rem"} sx={{ justifyContent: "end" }} />
        </DialogTitle>
        <Form
          config={config}
          onSubmit={handleSubmit}
          onCancel={closeDialog}
          isPending={isPending}
          errors={formErrors}
        />
      </Dialog>
      <Notify isOpen={isNotifyOpen} onClose={() => setIsNotifyOpen(false)}>
        <Translator text={"Commercial offer changed."} />
      </Notify>
    </>
  );
});

export default EditCommercialOfferDialog;
