import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";

import { Box } from "@mui/material";

import useStores from "~/hooks";

import { DataGrid, Translator, DocumentAsIcon } from "~/components";
import ContractLink from "../contracts/components/buttons/ContractLink";
/**
 *  Таблица с расходами.
 */
const ContractExpensesGrid = observer((props) => {
  // const { searchBy, categories, scopes } = props;

  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 20 });

  const { expenseStore, languageStore, branchStore } = useStores();
  const { branch } = branchStore;

  useEffect(() => {
    const effect = async () => {
      await expenseStore.fetchContractExpenses(branch, paginationModel);
    };
    if (!branch) return;
    effect();
  }, [branch, expenseStore, paginationModel]);

  const columns = useMemo(
    () => [
      {
        field: "id",
        headerName: languageStore.translate({ text: "ID" }),
        type: "number",
        width: 100,
      },
      {
        field: "createdAt",
        headerName: languageStore.translate({ text: "Created at" }),
        type: "date",
        width: 150,
        valueGetter: (value) => value?.toDate(),
        renderCell: ({ row }) => <Translator date={row.createdAt} variant="compact" />,
      },
      {
        field: "value",
        valueGetter: (value) => value.amount,
        headerName: languageStore.translate({ text: "Value" }),
        type: "number",
        width: 150,
        renderCell: ({ row }) => <Translator number={row.value.amount} currency={row.value.currency} />,
      },
      {
        field: "category",
        valueGetter: (value) => value?.name,
        headerName: languageStore.translate({ text: "Category" }),
        type: "string",
        width: 200,
        renderCell: ({ row }) => <Translator text={row.category?.name} />,
      },
      {
        field: "author",
        valueGetter: (value) => value?.name,
        headerName: languageStore.translate({ text: "Author" }),
        type: "string",
        width: 200,
        renderCell: ({ row }) => row.author?.name,
      },
      {
        field: "contract",
        headerName: languageStore.translate({ text: "Contract" }),
        valueGetter: (value) => value?.number,
        type: "string",
        width: 200,
        renderCell: ({ row }) => (
          <ContractLink contractId={row.contract?.id} contractNumber={row.contract?.number} />
        ),
      },
      
      {
        field: "documents",
        headerName: languageStore.translate({ text: "Documents" }),
        width: 750,
        renderCell: ({ row }) => (
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
            {row.documents.map((doc) => (
              <DocumentAsIcon document={doc} key={doc.id} type="documents" isCompact={true} />
            ))}
          </Box>
        ),
      },
    ],
    [languageStore]
  );

  return (
    <DataGrid
      columns={columns}
      rows={expenseStore.expensesArray}
      rowCount={expenseStore.count}
      loading={expenseStore.isPending}
      pagination={true}
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
    />
  );
});

export default ContractExpensesGrid;
