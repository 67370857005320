import { observer } from "mobx-react";
import { Box } from "@mui/material";

/**
 * Компонент отображения плана сканирования в расписании.
 */
const FacilityScanningPlan = observer(({ plan }) => {
  return (
    <Box sx={{ display: "flex", flex: 1, flexDirection: "row", fontSize: "1.25rem" }}>
      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
        <Box sx={{ mr: 1 }}>{plan.start.format("HH:mm")}</Box>
        <Box>{plan.finish.format("HH:mm")}</Box>
      </Box>
      <Box sx={{ flex: 1, justifyContent: "center" }}>
        <Box sx={{ fontWeight: "bold" }}>{plan.facility.name}</Box>
        <Box sx={{ flex: 1, justifyContent: "center" }}>{plan.facility.description}</Box>
      </Box>
      <Box
        sx={{
          mr: 0.5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>{plan.started?.datetime.format("HH:mm")}</Box>
        <Box>{plan.finished?.datetime.format("HH:mm")}</Box>
      </Box>

      {/* <Box sx={{ display: "flex", flex: 1, flexDirection: "row" }}> */}
      {/* </Box> */}
    </Box>
  );
});

export default FacilityScanningPlan;
