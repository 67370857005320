import { observer } from "mobx-react";

import { Toolbar as MuiToolbar } from "@mui/material";

/**
 * Тулбар.
 */
const Toolbar = observer(({ children }) => {
  return (
    <MuiToolbar disableGutters sx={{ marginTop: 1 }}>
      {children}
    </MuiToolbar>
  );
});

export default Toolbar;
