import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import ListItemIcon from "@mui/material/ListItemIcon";
import EditIcon from "@mui/icons-material/Edit";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import Translator from "../../../components/Translator";

/**
 * Контекстное меню таблицы коммерческих предложений.
 *
 * @returns
 */
const CommercialOfferContextMenu = ({
  offer,
  position,
  handleClose,
  openChangeStateDialog,
  openEditCommercialOfferDialog,
  openCreateContractDialog,
}) => {
  const [x, y] = position;
  const isVisible = !!x && !!y;

  return (
    <Menu
      open={isVisible}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={isVisible ? { top: y, left: x } : undefined}
      slotProps={{
        root: {
          onContextMenu: (e) => {
            e.preventDefault();
            handleClose();
          },
        },
      }}
    >
      <MenuItem onClick={openChangeStateDialog} disabled={offer?.state === "accepted"}>
        <ListItemIcon>
          <UpgradeIcon />
        </ListItemIcon>
        <Translator text={"Update state..."} />
      </MenuItem>
      <MenuItem onClick={openEditCommercialOfferDialog} disabled={offer?.state === "accepted"}>
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        <Translator text={"Edit commercial offer..."} />
      </MenuItem>
      <MenuItem onClick={openCreateContractDialog} disabled={offer?.state === "accepted"}>
        <ListItemIcon>
          <RequestPageIcon />
        </ListItemIcon>
        <Translator text={"Accept and start contract..."} />
      </MenuItem>
    </Menu>
  );
};

export default CommercialOfferContextMenu;
