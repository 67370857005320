import { makeObservable, observable, computed } from "mobx";

import moment from "moment";
import Money from "./Money";
import Document from "./Document";

import { UserInfo } from "~/modules/employees/data/models";
import Category from "./Category";

/** 
 * Расход филиала.
 * 
 * 
 */
class BranchExpense {
  @observable id;
  @observable value;
  @observable date;
  @observable description;
  @observable documents = [];
  @observable author;
  @observable category;


  //
  @observable branch;
  @observable store;

  constructor(data, branch, store) {
    makeObservable(this);

    const { id, value, date, description, documents, author, category } = data;

    this.id = id;
    this.value = new Money({ amount: value.amount, currency: value.currency });
    this.date = moment(date);
    this.description = description;
    this.documents = documents.map((d) => new Document(d));

    const knownAuthor = store.root.employeesStore.getEmployeeById(author.id);
    if (!knownAuthor) {
      this.author = new UserInfo(author);
    } else {
      this.author = knownAuthor;
    }

    this.category = new Category({ ...category, scopes: ["branch"] });

    this.branch = branch;
    this.store = store;
  }
}

export default BranchExpense;