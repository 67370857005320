import ApiBase from "~/api/ApiBase";

export default class ScanningsApiV0 extends ApiBase {

  /**
   * Запросить объекты запланированные к сканированию.
   * 
   * @param {*} branchId 
   * @param {*} employeeId 
   * @param {*} withFinished 
   * @param {*} withResult 
   * @param {*} from 
   * @param {*} to 
   */
  async getFacilitiesPlannedForScanning(branchId, employeeId, withFinished, withResult, from, to) {

    const parameters = {
      from: from.utc(true).toISOString(),
      to: to.utc(true).toISOString(),
      withFinished,
      withResult
    };
    const url = `api/v0/branches/${branchId}/scanners/${employeeId}/facilities`;
    const [data] = await this.get(url, parameters);
    return data;
  }

  /**
   * Положить сссылку на грязное облако к сканированию.
   * 
   * @param {integer} planId идентификатор плана сканированя
   * @param {string} rawCloud ссылка на грязное облако
   * @returns 
   */
  async postScanningPlanRawResult(planId, rawCloud) {
    const url = `api/v0/facilities/scanning/plans/${planId}/result`;
    const payload = { rawCloud };
    const [data] = await this.post(url, payload);
    return data;
  }

  /**
   * Положить ссылку на сшитое облако к плану сканирования.
   * 
   * @param {integer} planId идентификатор плана сканированя
   * @param {string} rawCloud ссылка на грязное облако
   * @returns 
   */
  async postScanningPlanLinkedResult(planId, linkedCloud) {
    const url = `api/v0/facilities/scanning/plans/${planId}/result`;
    const payload = { linkedCloud };
    const [data] = await this.post(url, payload);
    return data;
  }
}