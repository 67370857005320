import { action, computed, makeObservable, observable, toJS } from "mobx";
import moment from "moment";

import Expense from "~/models/Expense";
import Translator from "~/components/Translator";
import ContractHistoryEntry from "./ContractHistoryEntry";

class Service {
  @observable store = null;
  @observable id = null;
  @observable contractId = null;
  @observable facilityId = null;
  @observable employeeId = null;
  @observable managerId = null;
  @observable modellerId = null;
  @observable kind = null;
  @observable description = null;
  @observable status = null;
  @observable started = null;
  @observable finished = null;
  @observable start = null;
  @observable finish = null;
  @observable result = null;

  @observable facilityExpensesMap = new Map();

  @observable history = [];
  @observable pendingHistory = false;
  @observable pendingExpenses = false;

  @observable pending = false;

  constructor(props, store) {
    makeObservable(this);

    this.store = store;
    this.api = this.store.api;
    this.id = `${props.id}`;
    this.update(props);
  }

  @action
  update(props) {
    if (props.contractId !== undefined) {
      this.contractId = `${props.contractId}`;
    }
    if (props.facilityId !== undefined) {
      this.facilityId = `${props.facilityId}`;
    }
    if (props.employeeId !== undefined) {
      this.employeeId = `${props.employeeId}`;
    }
    if (props.managerId !== undefined) {
      this.managerId = `${props.managerId}`;
    }
    if (props.modellerId !== undefined) {
      this.modellerId = `${props.modellerId}`;
    }
    if (props.kind) {
      this.kind = props.kind;
    }
    if (props.description) {
      this.description = props.description;
    }
    if (props.status) {
      this.status = props.status;
    }
    if (props.started) {
      this.started = props.started;
    }
    if (props.finished) {
      this.finished = props.finished;
    }

    if (props.start) {
      this.start = moment(props.start).utc(true);
    }
    if (props.finish) {
      this.finish = moment(props.finish).utc(true);
    }
    if (props.result) {
      this.result = props.result;
    }
  }

  @action
  async addResult(data, type) {
    try {
      let response = null;
      if (!this.hasResult) {
        response = await this.store.api.setScanResult(data);
      } else {
        response = await this.store.api.setLinkingResult({
          ...toJS(this.result),
          ...data,
        });
      }
      if (response) {
        this.update({ result: response });
      }
    } catch (error) {
      console.warn(error);
    }
  }

  @action
  setHistory(array) {
    this.history.clear();
    array.forEach((item, index) => {
      this.history.push(new ContractHistoryEntry(item, index, this));
    });
  }

  @action
  async getHistory() {
    if (this.result && this.result.id) {
      this.setPendingHistory(true);
      const history = await this.api.getResultHistory(this.result.id);
      this.setHistory(history);
      this.setPendingHistory(false);
    }
  }

  @action
  async getExpenses() {
    this.setPendingExpenses(true);
    const expensesData = await this.api.getFacilityExpenses(this.facilityId);
    this.processExpenses(expensesData);
    this.setPendingExpenses();
  }

  @action
  addExpense(itemData) {
    const expense = new Expense(itemData, this.store);
    if (expense) {
      this.facilityExpensesMap.set(expense.id, expense);
    }
  }

  @action
  processExpenses(data) {
    this.facilityExpensesMap.clear();

    if (data && data.length) {
      data.forEach((itemData) => {
        this.addExpense(itemData);
      });
    }
  }

  @action
  setPendingHistory(pending = false) {
    this.pendingHistory = pending;
  }

  @action
  setPending(pending = false) {
    this.pending = pending;
  }

  @action
  setPendingExpenses(pending = false) {
    this.pendingExpenses = pending;
  }

  @action
  async setFacilityExpenses(data) {
    const { amount, categoryId } = data;
    let documentIds = undefined;
    if (data.documents) {
      documentIds = await this.store.rootStore.uploadDocuments(data.documents);
    }
    const expense = await this.api.setFacilityExpenses({
      facilityId: this.facilityId,
      documents: documentIds,
      value: { amount, currency: this.currentBranch.currency },
      categoryId,
    });
    this.addExpense(expense);
  }

  @computed
  get uid() {
    return `${this.kind}-${this.id}`;
  }

  @computed
  get isPending() {
    return this.pending;
  }

  @computed
  get isPendingExpenses() {
    return this.pendingHistory;
  }

  @computed
  get isPendingHistory() {
    return this.pendingHistory;
  }

  @computed
  get employee() {
    return this.store.rootStore.employeesStore.getEmployeeById(this.employeeId);
  }

  @computed
  get modeller() {
    return this.store.rootStore.employeesStore.getEmployeeById(this.modellerId);
  }

  @computed
  get employeeFullName() {
    if (this.modeller) {
      return this.modeller.fullName;
    }
    return this.employee && this.employee.fullName;
  }

  @computed
  get facility() {
    return this.store.rootStore.facilityStore.getFacilityById(this.facilityId);
  }

  @computed
  get isLinking() {
    return this.facility.contractState === "Linking";
  }

  @computed
  get label() {
    return this.kind;
  }

  @computed
  get isStarted() {
    if (this.kind === "modelling") {
      return this.start.startOf("day") <= moment();
    }
    return !!this.started;
  }

  @computed
  get isFinished() {
    if (this.kind === "modelling") {
      return this.hasResult;
    }
    return !!this.finished;
  }

  @computed
  get hasRaw() {
    return !!(this.result && this.result.rawCloud);
  }

  @computed
  get hasFinish() {
    return !!(this.result && this.result.finish);
  }

  @computed
  get hasLinked() {
    return !!(this.result && this.result.linkedCloud);
  }

  @computed
  get hasResult() {
    return !!this.result;
  }

  @computed
  get value() {
    return this.id;
  }

  @computed
  get currentBranch() {
    return this.store.rootStore.branchStore.currentBranch;
  }

  @computed
  get expensesArray() {
    return Array.from(this.facilityExpensesMap.values());
  }

  @computed
  get expensesByCategory() {
    const object = {};
    this.expensesArray.forEach((expense) => {
      if (expense.valueNumber) {
        if (object[expense.categoryId]) {
          object[expense.categoryId].value = object[expense.categoryId].value + expense.valueNumber;
        } else {
          object[expense.categoryId] = {
            id: expense.categoryName,
            label: <Translator text={expense.categoryName} />,
            value: expense.valueNumber,
          };
        }
      }
    });
    return object;
  }

  @computed
  get expensesTotal() {
    let amount = 0;
    this.facilityExpensesMap.forEach((item) => {
      if (item && item.valueNumber) {
        amount += item.valueNumber;
      }
    });
    return amount;
  }

  @computed
  get currency() {
    return this.currentBranch && this.currentBranch.currency;
  }

  @computed
  get pieData() {
    return Array.from(Object.values(this.expensesByCategory));
  }

  @computed
  get uploadLinkingResultFormConfig() {
    const fields = [];
    fields.push({
      name: "planId",
      type: "hidden",
      viewConfig: "input",
      initialValue: this.id,
    });
    fields.push({
      name: "linkedCloud",
      title: "Url",
      type: "url",
      pattern: `http(s)*://.+\..+`, // eslint-disable-line
      viewConfig: "input",
      isRequired: true,
      isReadOnly: false,
      validate: true,
    });

    return {
      submitText: "Submit",
      cancelText: "Cancel",
      formTitle: "Add linking result",
      fields,
    };
  }

  @computed
  get scannigExpensesFormConfig() {
    const fields = [];
    fields.push({
      name: "categoryId",
      fakeName: "category",
      title: "Choose expense kind",
      type: "select",
      isRequired: true,
      isReadOnly: false,
      validate: true,
      loading: this.currentBranch && this.currentBranch.isPendingExpensesForFacility,
      options: (this.currentBranch && this.currentBranch.facilityExpenseCategoriesArray) || [],
    });

    fields.push({
      name: "amount",
      title: "Expense amount",
      type: "number",
      viewConfig: "input",
      inputMode: "decimal",
      startAdornment: this.currentBranch && this.currentBranch.currency,
      min: 1,
      step: 0.01,
      isRequired: true,
      isReadOnly: false,
      validate: true,
    });

    fields.push({
      id: "documents",
      name: `documents`,
      title: "Documents",
      fileType: "documents",
      type: "file",
      multiple: true,
      isRequired: false,
      isReadOnly: false,
      validate: true,
    });

    return {
      submitText: "Ok",
      cancelText: "Cancel",
      formTitle: "Set scanning expenses",
      formText: "To set scanning expenses fill the form below",
      fields,
    };
  }
}

export default Service;
