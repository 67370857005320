import { ApiBase } from "~/api";

/**
 * API филиалов.
 */
export default class BranchesApiV0 extends ApiBase {
  /**
   * Получить список доступных филиалов.
   */
  async getBranches() {
    const url = "/api/v0/branches";
    const [data] = await this.get(url);
    return data;
  }
}
