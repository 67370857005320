import { action, computed, makeObservable, observable, toJS } from "mobx";
import moment from "moment";

class Expense {
  @observable store = null;
  @observable id = null;
  @observable authorId = null;
  @observable categoryId = null;
  @observable contractId = null;
  @observable facilityId = null;
  @observable documentIds = [];
  @observable documents = [];
  @observable value = null;
  @observable date = null;

  @observable pendingDocuments = false;

  constructor(props, store) {
    makeObservable(this);

    this.store = store;
    this.branchStore = this.store.root.branchStore;
    this.id = `${props.id}`;
    this.authorId = `${props.authorId}`;
    this.categoryId = `${props.categoryId}`;
    this.contractId = `${props.contractId}`;
    this.facilityId = `${props.facilityId}`;
    this.documentIds = props.documents;
    this.value = props.value;
    if (props.createdAt) {
      this.date = moment(props.createdAt);
    }

    this.loadDocumentsInfo(); // Никаких асинхронных вызовов в конструкторах быть не должно
  }

  @action async loadDocumentsInfo() {
    this.setPendingDocuments(true);
    const documentsInfo = await this.store.rootStore.getDocumentsInfo(this.documentIds);

    if (documentsInfo.length) {
      this.setDocumentsInfo(documentsInfo);
    }
    this.setPendingDocuments(false);
  }

  @action
  setDocumentsInfo(documentsInfo) {
    this.documents = documentsInfo;
  }

  @action
  setPendingDocuments(pending = false) {
    this.pendingDocuments = pending;
  }

  @computed
  get documentsArray() {
    return [];
    return toJS(this.documents);
  }

  @computed
  get valueNumber() {
    return (this.value && this.value.amount && Number(this.value.amount)) || 0;
  }

  @computed
  get isPendingDocuments() {
    return this.pendingDocuments;
  }

  @computed
  get category() {
    return (
      this.categoryId &&
      this.branchStore.currentBranch &&
      this.branchStore.currentBranch.allExpenseCategoriesMap.get(this.categoryId)
    );
  }

  @computed
  get categoryName() {
    return (this.category && this.category.name) || "";
  }

  @computed
  get currency() {
    return (this.value && this.value.currency) || "";
  }
}

export default Expense;
