import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react";

import Translator from "~/components/Translator";
import { Box, Card, CardContent, CardHeader, Chip, Divider, Tooltip, Typography } from "@mui/material";
import {
  AddCircle,
  CalendarMonth,
  ChangeCircle,
  DocumentScanner,
  FileOpen,
  FilePresent,
  ManageAccounts,
  Numbers,
  Paid,
  Person,
  RemoveCircle,
  TextFields,
} from "@mui/icons-material";
import Preloader from "../../../components/Preloader";

const HistoryView = observer(({ contract, isDiff }) => {
  const { historyRecords } = contract;

  // на старте запросим историю контракта
  useEffect(() => {
    contract.retrieveHistory();
  }, [contract]);

  const items = useMemo(() => {
    const items = [];
    if (historyRecords === null) return items;

    historyRecords.forEach((entry) => {
      const {
        type,
        status,
        number,
        date,
        description,
        user,
        key,
        datetime,
        branch,
        client,
        value,
        rawCloud,
        createdAt,
        linkedCloud,
        linkedAt,
      } = entry;
      let icon = <AddCircle />;
      let color = "success";
      let tooltip = "Created";
      if (type === "~") {
        icon = <ChangeCircle />;
        color = "warning";
        tooltip = "Changed";
      } else if (type === "-") {
        icon = <RemoveCircle />;
        color = "error";
        tooltip = "Deleted";
      }
      items.push(
        <Box
          key={key}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <Tooltip
            arrow
            title={
              <Typography sx={{ whitespace: "nowrap" }} variant="body2">
                <Translator text={tooltip} />
              </Typography>
            }
          >
            <Chip
              color={color}
              sx={{
                p: "0.25rem",
                height: "2.5rem",
                width: "2.5rem",
                borderRadius: "2.5rem",
                m: "0.5rem",
                mt: "1rem",
                position: "relative",
                "& .MuiChip-label": {
                  pr: 0,
                },
                ":after": {
                  content: `""`,
                  width: "3rem",
                  position: "absolute",
                  height: 0,
                  borderBottom: "0.0625rem solid #dedede",
                  left: "100%",
                  top: "50%",
                },
              }}
              icon={icon}
            />
          </Tooltip>
          <Card sx={{ m: "0.5rem", flex: 1, position: "relative" }}>
            <CardHeader
              avatar={<ManageAccounts />}
              title={<Typography>{user.fullName}</Typography>}
              subheader={<Translator datetime={datetime} />}
              action={
                branch.has && (
                  <Chip
                    label={<Translator text={branch.label} />}
                    color={branch.changed ? color : "info"}
                    variant="outlined"
                  />
                )
              }
            />
            <CardContent>
              <Box key={key}>
                {(client.changed || !isDiff) && client.has && (
                  <Box
                    sx={{
                      m: "0.25rem 0",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Person color={client.changed ? color : "info"} sx={{ mr: "0.25rem" }} />
                    {client.label}
                  </Box>
                )}
                {(status.changed || !isDiff) && status.has && (
                  <Box
                    sx={{
                      m: "0.25rem 0",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <DocumentScanner color={status.changed ? color : "info"} sx={{ mr: "0.25rem" }} />
                    <Translator text={status.label} />
                  </Box>
                )}
                {(number.changed || !isDiff) && number.has && (
                  <Box
                    sx={{
                      m: "0.25rem 0",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Numbers color={number.changed ? color : "info"} sx={{ mr: "0.25rem" }} />
                    <Typography>{number.label}</Typography>
                  </Box>
                )}
                {(date.changed || !isDiff) && date.has && (
                  <Box
                    sx={{
                      m: "0.25rem 0",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <CalendarMonth color={date.changed ? color : "info"} sx={{ mr: "0.25rem" }} />
                    <Translator date={date.label} />
                  </Box>
                )}
                {(value.changed || !isDiff) && value.has && value.amount && (
                  <Box
                    sx={{
                      m: "0.25rem 0",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Paid color={value.changed ? color : "info"} sx={{ mr: "0.25rem" }} />
                    <Translator number={value.amount} currency={value.currency} />
                  </Box>
                )}
                {(description.changed || !isDiff) && description.has && <Divider sx={{ m: "1rem 0" }} />}
                {(description.changed || !isDiff) && description.has && (
                  <Box
                    sx={{
                      m: "0.25rem 0",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      maxWidth: "40rem",
                    }}
                  >
                    <TextFields color={description.changed ? color : "info"} sx={{ mr: "0.25rem" }} />
                    <Typography>{description.label}</Typography>
                  </Box>
                )}
                {(rawCloud.changed || createdAt.changed || !isDiff) && createdAt.has && (
                  <Typography>
                    <Translator text="Raw data" />
                  </Typography>
                )}
                {(rawCloud.changed || !isDiff) && (rawCloud.has || rawCloud.deleted) && (
                  <Box
                    sx={{
                      m: "0.25rem 0",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <FileOpen
                      color={rawCloud.changed ? (rawCloud.deleted ? "error" : color) : "info"}
                      sx={{ mr: "0.25rem" }}
                    />
                    <Typography
                      sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
                      color={rawCloud.deleted ? "error" : undefined}
                    >
                      <Translator text={rawCloud.deleted ? "Deleted" : rawCloud.label} />
                    </Typography>
                  </Box>
                )}
                {(createdAt.changed || !isDiff) && createdAt.has && (
                  <Box
                    sx={{
                      m: "0.25rem 0",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <CalendarMonth color={createdAt.changed ? color : "info"} sx={{ mr: "0.25rem" }} />
                    <Translator datetime={createdAt.label} />
                  </Box>
                )}
                {(linkedCloud.changed || linkedAt.changed || !isDiff) && linkedAt.has && (
                  <Typography>
                    <Translator text="Linked data" />
                  </Typography>
                )}

                {(linkedCloud.changed || !isDiff) && (linkedCloud.deleted || linkedCloud.has) && (
                  <Box
                    sx={{
                      m: "0.25rem 0",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <FilePresent
                      color={linkedCloud.changed ? (linkedCloud.deleted ? "error" : color) : "info"}
                      sx={{ mr: "0.25rem" }}
                    />
                    <Typography
                      sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
                      color={linkedCloud.deleted ? "error" : undefined}
                    >
                      <Translator text={linkedCloud.deleted ? "Deleted" : linkedCloud.label} />
                    </Typography>
                  </Box>
                )}
                {(linkedAt.changed || !isDiff) && linkedAt.has && (
                  <Box
                    sx={{
                      m: "0.25rem 0",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <CalendarMonth color={linkedAt.changed ? color : "info"} sx={{ mr: "0.25rem" }} />
                    <Translator datetime={linkedAt.label} />
                  </Box>
                )}
              </Box>
            </CardContent>
          </Card>
        </Box>
      );
    });
    return items;
  }, [historyRecords, isDiff]);

  if (historyRecords === null) return <Preloader isPending={true} />;

  return <Box>{items}</Box>;
});

export default HistoryView;
