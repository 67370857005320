import { action, computed, autorun, makeObservable, observable, runInAction, toJS } from "mobx";
import { AuthApiV0 } from "../api";

/**
 * Стор аутентификации и авторизации.
 */
export default class AuthStore {
  @observable token = "";
  @observable userId;
  @observable permissions;

  @observable api;
  @observable root;
  @observable pendingRequests = 0;

  constructor(root) {
    makeObservable(this);
    this.permissions = new Map();

    // Смотрим есть ли данные в localStorage
    this.token = localStorage.getItem("token") || "";
    const permissions = JSON.parse(localStorage.getItem("permissions")) || [];
    permissions.forEach((permission) => this.permissions.set(permission, 1));
    this.userId = localStorage.getItem("id") || -1;
    this.root = root;
    this.pendingRequests = 0;
    this.api = new AuthApiV0(this);

    // Эффект при смене токена
    autorun(() => {
      const effect = async () => {
        await this.root.branchStore.getBranches();
      };
      if (!this.root?.branchStore) return; // autorun может случится раньше чем будет стор
      if (this.token !== "") effect();
    });
  }

  /**
   * Вход пользователя в систему.
   */
  // TODO: Добавить обработку ошибок
  // TODO: Добавить хранение в localStorage
  @action async login({ login, password }) {
    // this.setError();
    runInAction(() => this.pendingRequests += 1);
    const [userData, token] = await this.api.login({ login, password });
    // Если токена не дали - удалим старый и данные, после выйдем
    if (!token) {
      localStorage.removeItem("token");
      localStorage.removeItem("permissions");
      localStorage.removeItem("id");
      return;
    }
    const { id, permissions } = userData;
    localStorage.setItem("id", id);
    localStorage.setItem("token", token);
    localStorage.setItem("permissions", JSON.stringify(permissions));
    runInAction(() => {
      this.token = token;
      this.userId = id;
      this.permissions.clear();
      permissions.forEach((permission) => this.permissions.set(permission, 1));
      this.pendingRequests--;
    });
  }

  /**
   * Выход пользователя из системы.
   */
  @action async logout() {
    await this.api.logout();
    this.removeCredentials();
    // runInAction(() => {
    //   this.token = "";
    //   this.userId = -1;
    //   this.permissions.clear();
    //   localStorage.removeItem("token");
    //   localStorage.removeItem("id");
    //   localStorage.removeItem("permissions");
    // });
  }

  @action removeCredentials() {
    this.token = "";
    this.userId = -1;
    this.permissions.clear();
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    localStorage.removeItem("permissions");
  }

  /**
   * Выполнен ли вход? Есть ли токен?
   */
  @computed get isLoggedIn() {
    return this.token !== "";
  }

  @computed get permissionsSet() {
    return new Set(toJS(this.permissions) || []);
  }

  /**
   * Если есть активные запросы - мы ждём. Нет активных запросов - не ждём.
   */
  @computed get isPending() {
    return this.pendingRequests > 0;
  }
}
