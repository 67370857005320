import React from "react";
import { observer } from "mobx-react";

import { Box, ListItem } from "@mui/material";
import Translator from "./Translator";
import FileList from "./FileList";

/**
 * Строка представляющая информацию о расходе.
 */
const ExpenseListItem = observer(({ icon, expense }) => {
  const { documents } = expense;

  return (
    <ListItem
      dense={true}
      sx={{
        m: 0,
        p: 0,
        overflow: "hidden",
        borderBottom: "0.0625rem solid transparent",
        borderBottomColor: (theme) => theme.palette.grey[400],
      }}
    >
      <Box sx={{ flexDirection: "column", overflow: "hidden", display: "flex", flex: 1 }}>
        <Box sx={{ flexDirection: "row", display: "flex" }}>
          <Box sx={{ mr: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            {icon}
          </Box>
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flex: 1,
              p: "0.25rem",
            }}
          >
            <Box sx={{ flex: 1, display: "flex", flexDirection: "row" }}>
              <Box sx={{ mr: 1, width: 150 }}>
                <Translator datetime={expense.createdAt} variant="compact" />
              </Box>
              <Box sx={{ mr: 1, width: 200 }}>
                <Translator text={expense.context.label} />
              </Box>
              <Box sx={{ mr: 1, width: 150 }}>
                <Translator text={expense.category.name} />
              </Box>
              <Box>{expense.author.name}</Box>
            </Box>
            <Box sx={{ display: "flex", width: 200, justifyContent: "end" }}>
              <FileList type={"documents"} files={documents} noName />
            </Box>
            <Box sx={{ minWidth: 100, display: "flex", justifyContent: "end" }}>
              <Translator number={expense.value.amount} currency={expense.value.currency} />
            </Box>
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
});

export default ExpenseListItem;
