import React from "react";
import { observer } from "mobx-react";

import { Box, useTheme, useMediaQuery } from "@mui/material";

/**
 * Имя модуля на главном экране.
 */
const ToolbarModuleName = observer(({ icon, title }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        ml: isMobile ? 0 : "2rem",
        fontSize: isMobile ? "1.25rem" : "2rem",
      }}
    >
      {title}
    </Box>
  );
});

export default ToolbarModuleName;
