import React from "react";
import { observer } from "mobx-react";

import { Box, Chip } from "@mui/material";

import { Translator } from "~/components";
import FacilityIcon from "../FacilityIcon";

/**
 * Чип объекта.
 *
 * Показывает его тип через иконку и площадь.
 */
const FacilityChip = observer(({ facility }) => {
  return (
    <Chip
      sx={{ p: "0.5rem", borderRadius: "0.25rem" }}
      size="medium"
      icon={<FacilityIcon kind={facility.kind} />}
      label={
        <>
          <Translator number={facility.area} /> <Translator text="m2" />
        </>
      }
    />
  );
});

export default FacilityChip;
