import { List } from "@mui/material";
import { observer } from "mobx-react";

import FileListItem from "./FileListItem";

const FileList = observer(({ files, noPreview, noDownload, type, noName }) => {
  return (
    <List dense={true}>
      {files.map((file) => {
        return (
          <FileListItem
            key={`${file.id}`}
            noName={noName}
            noPreview={noPreview}
            noDownload={noDownload}
            type={type}
            file={file}
          />
        );
      })}
    </List>
  );
});

export default FileList;
