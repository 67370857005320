import React from "react";
import { observer } from "mobx-react";

import { Translator } from "~/components";
import { Button } from "@mui/material";

import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";

/**
 * Кнопка открытия диалога загрузки исходного облака.
 */
const UploadRawResultButton = observer(({ onClick }) => {
  return (
    <Button variant="contained" startIcon={<CloudUploadOutlinedIcon />} onClick={onClick}>
      <Translator text="Set raw url" />
    </Button>
  );
});

export default UploadRawResultButton;
