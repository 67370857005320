import React, { useEffect, useMemo, useState } from "react";

import { observer } from "mobx-react";

import { Box } from "@mui/material";
import { DataGrid, Translator, DocumentAsIcon } from "~/components";
import useStores from "~/hooks";
import { FAB } from "~/components/buttons";
import { AddingBranchExpenseDialog } from "./components/dialogs";

/**
 * Таблица расходов филиала.
 */
const BranchExpensesGrid = observer((props) => {
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 20 });
  const [isAddingBranchExpenseDialogOpen, setIsAddingBranchExpenseDialogOpen] = useState(false);

  const { expenseStore, branchStore, languageStore } = useStores();
  const { branch } = branchStore;

  useEffect(() => {
    const effect = async () => {
      await expenseStore.fetchBranchExpenses(branch, paginationModel);
    };
    if (!branch) return;
    // if (paginationModel.pageSize < 1) return;
    effect();
  }, [branch, expenseStore, paginationModel]);

  const columns = useMemo(() => {
    return [
      {
        field: "id",
        headerName: languageStore.translate({ text: "ID" }),
        type: "number",
        width: 100,
      },
      {
        field: "date",
        headerName: languageStore.translate({ text: "Date" }),
        renderCell: ({ row }) => <Translator date={row.date} variant="compact" />,
        valueGetter: (value) => value?.toDate(),
        type: "date",
      },
      {
        field: "value",
        headerName: languageStore.translate({ text: "Value" }),
        valueGetter: (value) => value.amount,
        type: "number",
        width: 150,
        renderCell: ({ row }) => <Translator number={row.value.amount} currency={row.value.currency} />,
      },
      {
        field: "category",
        headerName: languageStore.translate({ text: "Category" }),
        valueGetter: (value) => value?.name,
        type: "string",
        width: 150,
        renderCell: ({ row }) => <Translator text={row.category?.name} />,
      },
      {
        field: "author",
        headerName: languageStore.translate({ text: "Employee" }),
        valueGetter: (value) => value?.name,
        type: "string",
        width: 150,
        renderCell: ({ row }) => <Translator text={row.author?.name} />,
      },
      {
        field: "description",
        headerName: languageStore.translate({ text: "Description" }),
        type: "string",
        width: 350,
      },
      {
        field: "documents",
        headerName: languageStore.translate({ text: "Documents" }),
        width: 250,
        renderCell: ({ row }) => (
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
            {row.documents.map((doc) => (
              <DocumentAsIcon document={doc} key={doc.id} type="documents" isCompact={true} />
            ))}
          </Box>
        ),
      },
    ];
  }, [languageStore]);

  return (
    <>
      <DataGrid
        columns={columns}
        rows={expenseStore.expenses}
        rowCount={expenseStore.count}
        loading={expenseStore.isPending}
        pagination={true}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />
      <FAB onClick={() => setIsAddingBranchExpenseDialogOpen(true)} />
      <AddingBranchExpenseDialog
        isOpen={isAddingBranchExpenseDialogOpen}
        onClose={() => setIsAddingBranchExpenseDialogOpen(false)}
        branch={branch}
      />
    </>
  );
});

export default BranchExpensesGrid;
