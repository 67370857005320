import { useState, useCallback, useEffect, useMemo } from "react";
import { observer } from "mobx-react";

import { Box, Typography, CardMedia, Button, ButtonGroup, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { NavigateNext, NavigateBefore, Download } from "@mui/icons-material";

import { Translator as T } from "~/components";
import Preloader from "~/components/Preloader";

import useStores from "~/hooks";

const DEFAULT_GALLERY_SIZE = "65vh";

const Gallery = observer(({ photos, annotationFunc = () => {}, size = DEFAULT_GALLERY_SIZE }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [blob, setBlob] = useState(null);

  const { photoStore } = useStores();

  // Структура данных текущего фото
  const photo = useMemo(() => photos[currentIndex], [photos, currentIndex]);

  // Выбрали фото по номеру
  const handleDirectPhotoButton = (event, newIndex) => {
    setCurrentIndex(newIndex);
  };

  // нажали "Вперёд"
  const handleNextButton = useCallback(() => {
    if (currentIndex + 1 === photos.length) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex((i) => i + 1);
    }
  }, [currentIndex, photos.length]);

  // нажали "Назад"
  const handlePrevButton = useCallback(() => {
    if (currentIndex === 0) {
      setCurrentIndex(photos.length);
    }
    setCurrentIndex((i) => i - 1);
  }, [currentIndex, photos.length]);

  const handleDownload = useCallback(() => {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    const url = URL.createObjectURL(blob);
    a.href = url;
    a.download = photo.file.name;
    a.click();
  }, [photo.file.name, blob]);

  // Грузим фото когда меняется currentIndex
  useEffect(() => {
    const getPhoto = async () => {
      const bytes = await photoStore.fetchPhotoBlob(photo["id"]);
      setBlob(bytes);
    };
    getPhoto();
  }, [photoStore, photo]);

  const PhotoPreloader = useMemo(
    () => <Preloader sx={{ minHeight: size, maxHeight: size }} isPending={true} />,
    [size]
  );

  const PreloaderOrCardMedia = () => {
    if (photoStore.isPending) return PhotoPreloader;
    if (!blob) return PhotoPreloader;
    return (
      <CardMedia
        sx={{ minHeight: size, maxHeight: size, flex: 1, flexShrink: 0, borderRadius: 1 }}
        component="img"
        src={URL.createObjectURL(blob)}
        img={blob}
        alt={photos[currentIndex].file.name}
      />
    );
  };

  const annotationText = annotationFunc(currentIndex);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", flex: 1, ml: 1 }}>
      {PreloaderOrCardMedia()}
      {/* Ряд кнопок с номерами фотографий */}
      <ToggleButtonGroup
        value={currentIndex}
        onChange={handleDirectPhotoButton}
        exclusive
        size="small"
        variant="outlined"
        fullWidth
        sx={{ mt: 1 }}
      >
        {photos.map((photo, index) => (
          <ToggleButton key={photo.id} value={index}>
            {index + 1}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {/* Кнопки "Вперёд" и "Назад" */}
      <ButtonGroup variant="outlined" disableElevation fullWidth sx={{ marginTop: 1 }}>
        <Button startIcon={<NavigateBefore />} onClick={handlePrevButton}>
          Previous
        </Button>
        <Button endIcon={<NavigateNext />} onClick={handleNextButton}>
          Next
        </Button>
      </ButtonGroup>
      <Box sx={{ mt: 1, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="body" sx={{ fontWeight: 500 }}>
          <T text={annotationText} />
        </Typography>
        <Button
          variant="contained"
          disableElevation
          disabled={photoStore.isPending || !photo} // скачать можно если не грузиться
          startIcon={<Download />}
          onClick={handleDownload}
        >
          <T text="Download" />
        </Button>
      </Box>
    </Box>
  );
});

export default Gallery;
