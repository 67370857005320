import { observer } from "mobx-react";

import { AppBar, Toolbar, IconButton, Box } from "@mui/material";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

import { styled } from "@mui/material/styles";
import { main } from "~/components";

import useStores from "~/hooks";
import { useCallback } from "react";
import { BranchSwitcher } from "~/modules/branches/components";
import { LangSwitcher } from "../../modules/langs/components";

/**
 * Верхнее меню системы.
 * Синяя полоску наверху Workspace.
 */
const ApplicationBar = observer(({ icon, title, theme, mode }) => {
  const { workspaceStore } = useStores();

  // Переключить цветовую схему
  const toggleMode = useCallback(() => {
    workspaceStore.toggleColorMode();
  }, [workspaceStore]);

  const StyledAppbar = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme }) => ({
    backgroundColor: "main",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }));

  return (
    <StyledAppbar position={"fixed"} elevation={0} enableColorOnDark theme={theme}>
      <Toolbar>
        <main.ToolbarLogo />
        <main.ToolbarModuleName title={title} icon={icon} />
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: "flex" }}>
          <IconButton onClick={toggleMode} color="inherit">
            {mode === "light" ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
        </Box>
        <BranchSwitcher />
        <LangSwitcher />
      </Toolbar>
    </StyledAppbar>
  );
});

export default ApplicationBar;
