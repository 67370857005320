import React from "react";
import { Box, Card, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import Translator from "../../../components/Translator";

/**
 *  Блок старта и финиша.
 */
export default function StartFinishDatetimes({ scanning }) {
  const theme = useTheme();
  if (!scanning) return;
  return (
    <Box sx={{ flexDirection: "column", display: "flex", mt: "0.25rem" }}>
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          mt: 0,
        }}
      >
        {scanning.start && (
          <Card sx={{ p: "0 0.5rem", textAlign: "center", flex: 1, borderColor: theme.palette.grey["400"] }}>
            <Typography sx={{ fontSize: "1.25rem" }}>
              <Translator time={scanning.start} variant="compact" />{" "}
            </Typography>
            <Typography sx={{ fontSize: "0.75rem" }}>
              <Translator date={scanning.start} variant="compact" />{" "}
            </Typography>
          </Card>
        )}
        <Box
          sx={{
            flexDirection: "column",
            display: "flex",
            m: 0.5,
          }}
        >
          &mdash;
        </Box>
        {scanning.finish && (
          <Card sx={{ p: "0 0.5rem", textAlign: "center", flex: 1, borderColor: theme.palette.grey["400"] }}>
            <Typography sx={{ fontSize: "1.25rem" }}>
              <Translator time={scanning.finish} variant="compact" />
            </Typography>
            <Typography sx={{ fontSize: "0.75rem" }}>
              <Translator date={scanning.finish} variant="compact" />
            </Typography>
          </Card>
        )}
      </Box>
    </Box>
  );
}
