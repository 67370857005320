import React, { useState, useCallback, useMemo } from "react";

import moment from "moment";

import { Dialog } from "@mui/material";

import useStores from "~/hooks";

import Form from "~/components/Form";
import { Notify } from "~/common/components";
import { Translator } from "~/components";

/**
 * Диалог добавления коммерческого предложения.
 */
const ChangeOfferStateDialog = ({ isVisible, setIsVisible, offer }) => {
  const { commercialOffersStore, languageStore } = useStores();
  const [isPending, setIsPending] = useState(false);
  const [isNotifyOpen, setIsNotifyOpen] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  // Создать offer
  const handleSubmit = useCallback(
    (data) => {
      // TODO: ai: а вот эти превращения значений должны быть в филде!
      const targetDate = moment(data.targetDate, "L").format("YYYY-MM-DD");
      data = { ...data, targetDate };
      async function changeOfferState(offerId, data) {
        setIsPending(true);
        await commercialOffersStore.changeOfferState(offerId, data);
        setIsPending(false);
      }
      changeOfferState(offer.id, data);
      setIsNotifyOpen(true);
      setIsVisible(false);
    },
    [commercialOffersStore, setIsVisible, offer]
  );

  // Закрывать диалог только по клику в кнопку и никак не реагировать на клик в фон
  const closeDialog = useCallback(
    (e, reason) => {
      if (reason !== "backdropClick") {
        setIsVisible(false);
      }
    },
    [setIsVisible]
  );

  // example: Commercial offer Poland-24-164 for Awesome Client from 29.01.2024
  const title = useMemo(() => {
    return (
      languageStore.translate({ text: "Commercial offer" }) +
      ` ${offer?.number} ` +
      languageStore.translate({ text: "for" }) +
      ` ${offer?.client?.name} ` +
      languageStore.translate({ text: "from" }) +
      " " +
      languageStore.translate({ date: offer?.date, compact: true })
    );
  }, [offer?.number, languageStore, offer?.client?.name, offer?.date]);

  const config = useMemo(() => {
    return {
      submitText: "Confirm",
      cancelText: "Cancel",
      formTitle: title,
      fields: [
        {
          name: "targetDate",
          title: "Target Date",
          type: "date",
          disablePast: true,
          disableFuture: false,
          isRequired: true,
          isReadOnly: false,
          validate: true,
          initialValue: moment().add(7, "days"),
        },
        {
          name: "state",
          title: "State",
          type: "select",
          isRequired: true,
          options: [
            { label: "Создано", value: "created" },
            { label: "Принято", value: "accepted" },
            { label: "Отложено", value: "postponed" },
            { label: "Отклонено", value: "rejected" },
          ],
          initialValue: offer?.state,
        },
        {
          name: "comment",
          title: "Comment",
          type: "text",
          multiline: true,
          isRequired: false,
          isReadOnly: false,
          validate: true,
        },
      ],
    };
  }, [title, offer?.state]);

  return (
    <>
      <Dialog open={isVisible} onClose={closeDialog}>
        {!isPending && (
          <Form
            isPending={isPending}
            errors={formErrors}
            config={config}
            onSubmit={handleSubmit}
            onCancel={closeDialog}
          />
        )}
      </Dialog>
      <Notify isOpen={isNotifyOpen} onClose={() => setIsNotifyOpen(false)}>
        <Translator text={"Commercial offer updated."} />
      </Notify>
    </>
  );
};

export default ChangeOfferStateDialog;
