import { makeObservable, observable } from "mobx";

import moment from "moment";

/**
 * Модель результата сканирования.
 *
 * "result": {
 *        "id": 18,
 *        "planId": 18,
 *        "createdAt": "2022-11-16T17:09:54.885571+00:00",
 *        "rawCloud": "https://disk.yandex.ru/?id=1231212sdfw11",
 *        "linkedCloud": "https://docs.google.com/spreadsheets/d/1q9ZUauFBCEnWygHnnTkBFfsKN767xa9XEGrAAtrklDs/edit#gid=2012497491",
 *        "linkedAt": "2022-12-09T13:35:28.443544+00:00"
 *    }
 */
export default class ScanningResult {
  // данные
  @observable id; // идентификатор
  @observable createdAt = null; // когда создали
  @observable rawCloud = ""; // исходное облако
  @observable linkedAt = null; // когда сшили
  @observable linkedCloud = ""; // сшили облако

  // связанные
  @observable plan = null;

  constructor(plan, props) {
    makeObservable(this);
    const { id, createdAt, rawCloud, linkedAt, linkedCloud } = props;
    this.id = id;

    this.createdAt = moment(createdAt);
    this.rawCloud = rawCloud || "";

    this.linkedAt = moment(linkedAt);
    this.linkedCloud = linkedCloud || "";

    this.plan = plan;
  }
}
