import React, { useState, useCallback, useEffect, useMemo } from "react";

import moment from "moment";

import { Dialog, DialogTitle } from "@mui/material";

import useStores from "~/hooks";
import Form from "~/components/Form";
import Preloader from "../../../components/Preloader";
import Translator from "../../../components/Translator";

/**
 * Диалог добавления коммерческого предложения.
 */
const AddCommercialOfferDialog = ({ isVisible, setIsVisible }) => {
  const { branchStore, clientStore, commercialOffersStore } = useStores();
  const [isPending, setIsPending] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [number, setNumber] = useState("");

  const { branch } = branchStore;

  // на открытии формы запросим свободный номер для предложения
  // TODO: Сделать wrapper на этот паттерн
  useEffect(() => {
    async function fetchData() {
      setIsPending(true);
      const number = await commercialOffersStore.makeNumber(branch);
      setNumber(number);
      setIsPending(false);
    }
    if (isVisible) fetchData();
  }, [isVisible, commercialOffersStore, branch]);

  // Создать offer
  const handleSubmit = useCallback(
    (data) => {
      // TODO: ai: а вот эти превращения значений должны быть в филде!
      const date = moment(data.date, "L").format("YYYY-MM-DD");
      const value = {
        amount: Number(data.value),
        currency: branch.currency,
      };
      data = { ...data, value, date };
      async function createOffer(data) {
        setIsPending(true);
        await commercialOffersStore.createOffer(branch, data);
        setIsPending(false);
        setIsVisible(false);
      }
      createOffer(data);
    },
    [commercialOffersStore, setIsVisible, branch]
  );

  // Закрывать диалог только по клику в кнопку и никак не реагировать на клик в фон
  const closeDialog = useCallback(
    (e, reason) => {
      if (reason !== "backdropClick") {
        // на закрытии формы удалим номер т.к. его не использовали
        commercialOffersStore.dropNumber(number);
        setIsVisible(false);
      }
    },
    [setIsVisible, commercialOffersStore, number]
  );

  const clientsOptions = useMemo(
    () =>
      clientStore.clientsArray.map((client) => {
        return { id: client.id, label: client.name, value: client.id };
      }),
    [clientStore.clientsArray]
  );

  const config = useMemo(() => {
    return {
      submitText: "Create",
      cancelText: "Cancel",
      fields: [
        {
          name: "numberVisible",
          title: "Number",
          type: "string",
          isRequired: true,
          isReadOnly: true,
          initialValue: number,
          isPending: isPending,
        },
        {
          name: "number",
          type: "hidden",
          initialValue: number,
        },
        {
          name: "date",
          title: "Date",
          type: "date",
          disablePast: false,
          disableFuture: true,
          isRequired: true,
          isReadOnly: false,
          validate: true,
          initialValue: moment(),
        },
        {
          name: "crmLink",
          title: "CRM link",
          type: "url",
          multiline: false,
          isRequired: true,
          isReadOnly: false,
          validate: true,
        },
        {
          name: "clientId",
          fakeName: "client",
          title: "Client",
          type: "autocomplete",
          withCreate: true,
          isRequired: true,
          isReadOnly: false,
          validate: true,
          loading: clientStore.isPending,
          options: clientsOptions,
        },
        {
          name: "clientName",
          title: "Client name",
          type: "text",
          multiline: false,
          isRequired: true,
          isReadOnly: false,
          validate: true,
          revealFunction: (values) => values["clientId"] === "create new",
        },
        {
          name: "clientDescription",
          title: "Client description",
          type: "text",
          multiline: true,
          isRequired: false,
          isReadOnly: false,
          validate: true,
          revealFunction: (values) => values["clientId"] === "create new",
        },
        // TODO: ai: А такой должен быть MoneyField!
        {
          name: "value",
          title: "Value without VAT",
          type: "number",
          viewConfig: "input",
          inputMode: "decimal",
          min: 1,
          step: 0.01,
          startAdornment: branch?.currency,
          isRequired: true,
          isReadOnly: false,
          validate: true,
        },
        {
          name: "description",
          title: "Description",
          type: "text",
          multiline: true,
          isRequired: false,
          isReadOnly: false,
          validate: false,
        },
        {
          id: "documents",
          name: `documents`,
          title: "Documents",
          type: "file",
          multiple: true,
          isRequired: false,
          isReadOnly: false,
          validate: true,
        },
      ],
    };
  }, [isPending, number, clientsOptions, clientStore.isPending, branch?.currency]);

  return (
    <Dialog open={isVisible} onClose={closeDialog}>
      <DialogTitle sx={{ display: "flex", dlexDirection: "row", justifyContent: "space-between" }}>
        <Translator text="Create commercial offer" />
        <Preloader isPending={isPending} size={"2rem"} sx={{ justifyContent: "end" }} />
      </DialogTitle>
      <Form config={config} isPending={isPending} errors={formErrors} onSubmit={handleSubmit} onCancel={closeDialog} />
    </Dialog>
  );
};

export default AddCommercialOfferDialog;
