import React, { useMemo, useCallback, useState, useEffect } from "react";

import moment from "moment";

import { observer } from "mobx-react";

import { Box, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Button } from "@mui/material";
import { DataGrid, useGridApiRef } from "~/components/grid";

import useStores from "~/hooks";
import Translator from "~/components/Translator";
import DocumentAsIcon from "~/components/DocumentAsIcon";

import { ContractContextMenu } from "~/modules/contracts/components/menus";
import { EditContractDialog } from "~/modules/contracts/components/dialogs";
import { AddingContractExpenseDialog } from "~/modules/expenses/components/dialogs";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import CloseIcon from "@mui/icons-material/Close";
import { ContractExpenses } from "~/modules/contracts/components";
import { CreateInvoiceDialog } from "~/modules/invoices/components";
import ContractLink from "../components/buttons/ContractLink";

/**
 * Таблица контрактов.
 */
const ContractsTable = observer(({ daterange, searchBy, selectedStates, refreshPls }) => {
  const { contractStore, languageStore } = useStores();
  const { isPending } = contractStore;
  const { branch } = contractStore.rootStore.branchStore;
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 25 });

  // ссылка на API таблицы
  const gridApi = useGridApiRef();

  // Контроль контекстного меню
  const [selectedRow, setSelectedRow] = useState();
  const [rowMenuPosition, setRowMenuPosition] = useState([null, null]);

  // Открыть контексное меню
  const handleContextMenu = useCallback(
    (event) => {
      event.preventDefault();
      // поставить новую выбранную строку
      const id = Number(event.currentTarget.getAttribute("data-id"));
      setSelectedRow(id);
      // убрать прежние выбранные
      const selectedIds = gridApi.current.getSelectedRows();
      selectedIds.forEach((value, x) => {
        gridApi.current.selectRow(x, false, true);
      });
      gridApi.current.selectRow(id);
      setRowMenuPosition([event.clientX - 2, event.clientY - 4]);
    },
    [gridApi]
  );

  // Закрыть контекстное меню
  const closeContextMenu = useCallback(() => {
    setRowMenuPosition([null, null]);
  }, []);

  // Контроль над диалогом редактирования контракта
  const [isEditContractDialogOpen, setIsEditContractDialogOpen] = useState(false);
  const openEditContractDialog = useCallback(() => setIsEditContractDialogOpen(true), []);
  const closeEditContractDialog = useCallback(() => {
    setIsEditContractDialogOpen(false);
    closeContextMenu();
  }, [closeContextMenu]);

  // Контроль над диалогом добавления расхода
  const [isAddContractExpenseDialogOpen, setIsAddContractExpenseDialogOpen] = useState(false);
  const openAddContractExpenseDialog = useCallback(() => setIsAddContractExpenseDialogOpen(true), []);
  const closeAddContractExpenseDialog = useCallback(() => {
    setIsAddContractExpenseDialogOpen(false);
    closeContextMenu();
  }, [closeContextMenu]);

  // Контроль над диалогом отображения расходов
  const [isContractExpenseDialogOpen, setIsContractExpenseDialogOpen] = useState(false);
  const openContractExpenseDialog = useCallback(() => setIsContractExpenseDialogOpen(true), []);
  const closeContractExpenseDialog = useCallback(() => {
    setIsContractExpenseDialogOpen(false);
    closeContextMenu();
  }, [closeContextMenu]);

  // Контроль над диалогом добавления счёта
  const [isContractCreateInvoiceDialogOpen, setIsContractCreateInvoiceDialogOpen] = useState(false);
  const openContractCreateInvoiceDialog = useCallback(() => setIsContractCreateInvoiceDialogOpen(true), []);
  const closeContractCreateInvoiceDialog = useCallback(() => {
    setIsContractCreateInvoiceDialogOpen(false);
    closeContextMenu();
  }, [closeContextMenu]);

  // Запросить контракты
  const fetchData = useCallback(async () => {
    if (paginationModel.pageSize < 1) return;
    if (!!branch) {
      await contractStore.findContracts(branch.id, searchBy, selectedStates, daterange, paginationModel);
    }
  }, [contractStore, branch, paginationModel, daterange, searchBy, selectedStates]);

  // Вызовем fetchData при изменении нужных данных
  useEffect(() => {
    fetchData();
  }, [fetchData, refreshPls]);

  // Текущий выбранный контракт
  const contract = useMemo(() => {
    return contractStore.getContractById(selectedRow);
  }, [contractStore, selectedRow]);

  const columns = useMemo(
    () => [
      {
        field: "number",
        headerName: languageStore.translate({ text: "Number" }),
        type: "string",
        width: 150,
        valueGetter: (value) => value.number,
        renderCell: ({ row }) => (
          <ContractLink contractId={row.id} contractNumber={row.number} /> // передаем только id и номер
        ),
        // renderCell: ({ row }) => <Tooltip title={row.description}>{row.number}</Tooltip>, // все еще пока что не разобрался

      }, 
      
      {
        field: "date",
        headerName: languageStore.translate({ text: "Date" }),
        type: "date",
        width: 100,
        renderCell: ({ row }) => <Translator date={row.date} variant="compact" />,
        valueGetter: (value) => moment(value).toDate(),
      },
      {
        field: "client",
        valueGetter: (value) => value.name,
        headerName: languageStore.translate({ text: "Client" }),
        type: "string",
        width: 200,
        renderCell: ({ row }) => <Translator text={row.client.name} />,
      },
      {
        field: "description",
        headerName: languageStore.translate({ text: "Description" }),
        type: "string",
        width: 400,
        renderCell: ({ row }) => <Translator text={row.description} />,
      },
      {
        field: "Manager",
        headerName: languageStore.translate({ text: "Manager" }),
        type: "string",
        width: 150,
        valueGetter: (value) => value?.name,
        renderCell: ({ row }) => row.manager?.name,
      },
      {
        field: "totalArea",
        headerName: languageStore.translate({ text: "Area" }),
        type: "number",
        width: 75,
        valueGetter: (value) => value,
        renderCell: ({ row }) => <Translator text={row.totalArea} />,
      },
      {
        field: "state",
        headerName: languageStore.translate({ text: "Status" }),
        type: "string",
        width: 100,
        renderCell: ({ row }) => <Translator text={row.state} />,
      },
      {
        field: "valueTotal",
        headerName: languageStore.translate({ text: "Value Net" }),
        type: "number",
        width: 150,
        valueGetter: (value) => value.amount,
        renderCell: ({ row }) => <Translator money={row.valueTotal.amount} />,
      },
      {
        field: "payedTotal",
        headerName: languageStore.translate({ text: "Payed Net" }),
        type: "number",
        width: 150,
        valueGetter: (value) => value.amount,
        renderCell: ({ row }) => <Translator money={row.payedTotal.amount} />,
      },
      {
        field: "expensesPlanTotal",
        headerName: languageStore.translate({ text: "Expense plan" }),
        type: "number",
        width: 100,
        valueGetter: (value) => value.value,
        renderCell: ({ row }) => <Translator money={row.expensesPlanTotal} />,
      },
      {
        field: "expensesFactTotal",
        headerName: languageStore.translate({ text: "Expense fact" }),
        type: "number",
        width: 100,
        valueGetter: (value) => value,
        renderCell: ({ row }) => <Translator money={row.expensesFactTotal} />,
      },
      {
        field: "scannerEmployees",
        headerName: languageStore.translate({ text: "Scanners" }),
        width: 200,
        renderCell: ({ row }) => <Box>{row.scannerEmployees.map((e) => e.name).join(", ")}</Box>,
      },
      {
        field: "scanningFinishDatetime",
        headerName: languageStore.translate({ text: "Scanned at" }),
        type: "date",
        width: 100,
        renderCell: ({ row }) => <Translator date={row.scanningFinishDatetime} variant="compact" />,
        valueGetter: (value) => value?.toDate(),
      },
      {
        field: "modellingBudget",
        headerName: languageStore.translate({ text: "Modelling Budget" }),
        type: "number",
        width: 150,
        valueGetter: (value) => value.amount,
        renderCell: ({ row }) => <Translator money={row.modellingBudget.amount} />,
      },
      {
        field: "architect",
        headerName: languageStore.translate({ text: "Architect" }),
        type: "string",
        width: 175,
        valueGetter: (value, row) => row.modellingManager?.name,
      },
      {
        field: "modellers",
        headerName: languageStore.translate({ text: "Modellers" }),
        type: "string",
        width: 175,
        // valueGetter: (value) => value?.name,
        renderCell: ({ row }) => <Box>{row.modellingEmployees.map((e) => e.name).join(", ")}</Box>,
      },
      {
        field: "modellingFinishDatetime",
        headerName: languageStore.translate({ text: "Modelled at" }),
        type: "date",
        width: 100,
        renderCell: ({ row }) => <Translator date={row.modellingFinishDatetime} variant="compact" />,
        valueGetter: (value) => value?.toDate(),
      },
      {
        field: "documents",
        headerName: languageStore.translate({ text: "Documents" }),
        width: 250,
        renderCell: ({ row }) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            {row.documents.map((doc) => (
              <DocumentAsIcon document={doc} key={doc.id} type="documents" isCompact={true} />
            ))}
          </Box>
        ),
      },
    ],
    [languageStore]
  );

  // функция раскраски ячейки
  const getCellClassName = (params) => {
    const { field, row } = params;
    let className = "";

    if (field === "description") {
      if (!row.description) className += "orange-cell";
    }

    if (field === "expensesFactTotal") {
      if (row.expensesFactTotal === 0) className += "orange-cell";
    }

    if (field === "expensesFactTotal") {
      if (row.expensesFactTotal > row.expensesPlanTotal) {
        className += "red-cell";
      }
    }

    // Если договор на отрисовке и нет архитектора -- желтый.
    if (field === "architect") {
      if (row.state === "Modelling") {
        if (!row.modellingManager) {
          className += "orange-cell";
        }
      }
    }

    // Если есть архитектор и нет моделлеров -- желтый!
    if (field === "modellers") {
      if (!!row.modellingManager && row.modellingEmployees.length === 0) {
        className += "orange-cell";
      }
    }

    return className;
  };

  return (
    <>
      <DataGrid
        apiRef={gridApi}
        rows={contractStore.contractsArray}
        columns={columns}
        disableColumnFilter // фильтры клиентские, убрать
        disableColumnSorting
        stickyHeader={true}
        autoPageSize={false}
        pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
        pagination={true}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        rowCount={contractStore.contractsTotalCount}
        loading={isPending}
        getRowHeight={() => "auto"}
        getEstimatedRowHeight={() => 52}
        getCellClassName={getCellClassName}
        sx={{
          "& .MuiDataGrid-cell": {
            // чтобы был отступ в ячейке при использовании динамической её высоты
            padding: 1,
            // чтобы текст был по вернхнему краю
            alignItems: "baseline",
          },
        }}
        slotProps={{
          row: {
            onContextMenu: handleContextMenu,
            style: { cursor: "context-menu" },
          },
        }}
      />

      {contract && (
        <>
          {/* Контекстное меню контракта */}
          <ContractContextMenu
            position={rowMenuPosition}
            handleClose={closeContextMenu}
            contract={contract}
            openEditContractDialog={openEditContractDialog}
            openAddContractExpenseDialog={openAddContractExpenseDialog}
            openContractExpenseDialog={openContractExpenseDialog}
            openCreateInvoiceDialog={openContractCreateInvoiceDialog}
          />
          {/* Диалог добавления инвойса к договору */}
          <CreateInvoiceDialog
            contract={contract}
            isOpen={isContractCreateInvoiceDialogOpen}
            onClose={closeContractCreateInvoiceDialog}
          />
          {/* Диалог редактирования контракта */}
          <EditContractDialog contract={contract} isOpen={isEditContractDialogOpen} onClose={closeEditContractDialog} />
          {/* Расходы по контракту */}
          <AddingContractExpenseDialog
            contract={contract}
            isOpen={isAddContractExpenseDialogOpen}
            onClose={closeAddContractExpenseDialog}
          />
          {/* Информация по расходам */}
          <Dialog
            open={isContractExpenseDialogOpen}
            onClose={closeContractExpenseDialog}
            sx={{ ".MuiPaper-root": { maxWidth: "60vw" } }}
          >
            <DialogTitle
              sx={{
                display: "flex",
                dlexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Translator text="Contract expenses" />
              <IconButton onClick={closeContractExpenseDialog}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <ContractExpenses contract={contract} />
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="info"
                disabled={false}
                onClick={openAddContractExpenseDialog}
                startIcon={<MoneyOffIcon />}
              >
                <Translator text={"Add contract expense"} />
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
});

export default ContractsTable;
