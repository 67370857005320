import React from "react";

import { CorporateFare, Cottage, Factory, Gite, Castle, Apartment } from "@mui/icons-material";

/**
 * Renders facility icon for type.
 */
export default function FacilityIcon({ kind, sx }) {
  const icons = {
    building: <Factory sx={sx} />,
    house: <Gite sx={sx} />,
    flat: <Apartment sx={sx} />,
    premises: <CorporateFare sx={sx} />,
    townhouse: <Cottage sx={sx} />,
    other: <Castle sx={sx} />,
  };
  return icons[kind];
}