import React, { useCallback } from "react";

import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";

import { Translator as T } from "~/components";

const ExpenseClassSwitcher = ({view, setView}) => {
  const onViewChanged = useCallback((event, newView) => {
    if (!newView) return;
    setView(newView);
  }, [setView]);

  return (
    <ToggleButtonGroup
      fullWidth
      sx={{ margin: ".5rem 0 1rem 0" }}
      value={view}
      exclusive
      onChange={onViewChanged}
      size="small"
      aria-label="text alignment"
    >
      {/* <TableRowsOutlinedIcon /> */}

      <ToggleButton fullWidth value="branch" aria-label="centered">
        <Tooltip title="Branch expenses">
          <T text={"Branch"} />
        </Tooltip>
      </ToggleButton>
      <ToggleButton fullWidth value="salary" aria-label="left aligned">
        <Tooltip title="Salary expenses">
          {/* <DashboardOutlinedIcon /> */}
          <T text={"Salary"} />
        </Tooltip>
      </ToggleButton>
      <ToggleButton fullWidth value="contracts" aria-label="left aligned">
        <Tooltip title="Contracts expenses">
          {/* <DashboardOutlinedIcon /> */}
          <T text={"Contracts"} />
        </Tooltip>
      </ToggleButton>
      <ToggleButton fullWidth value="facilities" aria-label="left aligned">
        <Tooltip title="Facility expenses">
          {/* <DashboardOutlinedIcon /> */}
          <T text={"Facilities"} />
        </Tooltip>
      </ToggleButton>
      <ToggleButton fullWidth value="shifts" aria-label="left aligned">
        <Tooltip title="Shifts expenses">
          {/* <DashboardOutlinedIcon /> */}
          <T text={"Shifts"} />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ExpenseClassSwitcher;
