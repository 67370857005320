/**
 *
 * {
 *     "authorId": 1,
 *     "createdAt": "2023-03-09T16:56:42.502845+00:00",
 *     "file": {
 *         "mimetype": "image/png",
 *         "name": "Screenshot_from_2023-03-06_20-30-40.png",
 *         "size": 17685
 *     },
 *     "id": 27
 * }
 *
 */

import { makeObservable, observable } from "mobx";
import moment from "moment";
import File from "./File";

class Document {
  @observable id;
  @observable createdAt;
  @observable file;

  constructor({ id, createdAt, file }) {
    this.id = id;
    this.createdAt = moment(createdAt);
    this.file = new File(file);

    makeObservable(this);
  }
}

export default Document;
