import React from "react";
import { observer } from "mobx-react";
import { Box, Typography } from "@mui/material";
import Translator from "../../components/Translator";
import {
  AltRoute,
  CalendarMonth,
  InfoRounded,
  Numbers,
  Person,
  ShoppingBag,
} from "@mui/icons-material";

const ReportMeta = observer(({ data }) => {
  const { output } = data;
  const { name, value, type } = output;

  let valueRender = <Translator text={value} />;
  let iconRender = <InfoRounded />;
  switch (type) {
    case "ReportClient":
      iconRender = <ShoppingBag />;
      break;
    case "ReportBranch":
      iconRender = <AltRoute />;
      break;
    case "ReportUser":
      iconRender = <Person />;
      break;
    case "date":
      iconRender = <CalendarMonth />;
      valueRender = <Translator date={value} />;
      break;
    case "datetime":
      iconRender = <CalendarMonth />;
      valueRender = <Translator datetime={value} />;
      break;
    case "number":
      iconRender = <Numbers />;
      valueRender = <Translator number={value} />;
      break;
    default:
      break;
  }

  return (
    <Typography
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        mt: "0.25rem",
        mr: "2rem"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          mr: "0.25rem",
          color: (theme) => {
            return theme.palette.info.main;
          },
        }}
      >
        {iconRender}
      </Box>
      <Translator text={name} />: {valueRender}
    </Typography>
  );
});

export default ReportMeta;
