import { observable, makeObservable } from "mobx";

class File {
  @observable mimetype;
  @observable name;
  @observable size;

  constructor({ mimetype, name, size }) {
    this.name = name;
    this.mimetype = mimetype;
    this.size = size;

    makeObservable(this);
  }
}

export default File;
