import { ApiBase } from "~/api";

/**
 * Photos API v0
 */
class PhotosApiV0 extends ApiBase {
  constructor(root) {
    super(root);
    this.store = root;
    this.domain = "/";
  }

  /**
   * Получить blob c сервера.
   *
   * @param {*} path
   */
  async fetchBlob(path) {
    const url = `${this.domain}${path}`;
    try {
      const response = await fetch(url, { headers: { Authorization: this.store.root.authStore.token }, cache: "force-cache" });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  /**
   * Сделать запрос фотографии с сервера.
   *
   * @param {*} id идентификатор фотографии
   * @returns
   */
  async getPhoto(id) {
    const url = `api/v0/photo-blobs/${id}`;
    return await this.fetchBlob(url);
  }

  /**
   * Uploads file to backend.
   * 
   * @param {File} file
   * @returns {number} fileId
   */
  async uploadPhoto(file) {
    const url = "api/v0/photos";
    const [data] = await this.postFormData(url, { "file": file });
    return data;
  }
}

export default PhotosApiV0;