import { observer } from "mobx-react";

import useStores from "~/hooks";

const Translator = observer(
  ({ text, date, datetime, time, number, currency, money, after, before, variant, numberOfDigits, format }) => {
    const { languageStore } = useStores();
    const { isPending } = languageStore;

    if (isPending) return text;

    return languageStore.translate({
      text,
      date,
      datetime,
      time,
      number,
      currency,
      money,
      after,
      before,
      variant,
      numberOfDigits,
      format,
    });
  }
);

export default Translator;
