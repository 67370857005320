import React, { useState, useCallback } from "react";
import { observer } from "mobx-react";

import { Tab } from "@mui/material";

import { TabContext, TabList, TabPanel } from "@mui/lab";

import useStores from "~/hooks";
import { Translator, SearchField, DateRangePicker, Module, Toolbar } from "~/components";

// import ExpensesGrid from "./ExpensesGrid";
// import { CategoriesSelect, ScopesSelect } from "./components";

import { ExpenseClassSwitcher } from "./components/buttons";

import BranchExpensesGrid from "./BranchExpensesGrid";
import SalaryExpensesGrid from "./SalaryExpensesGrid";
import ContractExpensesGrid from "./ContractExpensesGrid";
import FacilityExpensesGrid from "./FacilityExpensesGrid";
import ShiftExpensesGrid from "./ShiftExpensesGrid";

/**
 * Модуль отображения расходов.
 *
 * @returns ExpensesList
 */
const ExpensesModule = observer(() => {
  const [currentTab, setCurrentTab] = React.useState(0);
  const [view, setView] = useState("branch");

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const [searchBy, setSearchBy] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedScopes, setSelectedScopes] = useState([]);

  const [searchOptions, setSearchOptions] = useState({});

  const { branchStore, expenseStore } = useStores();
  const { branch } = branchStore;

  // useEffect(() => {
  //   if (!branch) return;
  //   expenseStore.fetchExpensesForBranch(branch.id);
  // }, [branch, expenseStore]);

  const onSearch = useCallback((value) => {
    if (value.length > 2) {
      setSearchBy(value);
    } else {
      setSearchBy("");
    }
  }, []);

  const onSelectScopes = useCallback((e) => {
    const {
      target: { value },
    } = e;
    setSelectedScopes(value);
  }, []);

  const onSelectCategories = useCallback((e) => {
    const {
      target: { value },
    } = e;
    setSelectedCategories(value);
  }, []);

  const categories = expenseStore.categoriesArray.map((c) => c.name);
  const scopes = expenseStore.scopesArray.map((s) => s);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Module>
      <Toolbar disableGutters>
        <SearchField label={<Translator text={"Search expense"} />} onSearch={onSearch} />
        <DateRangePicker />
        {/* <ScopesSelect value={selectedScopes} scopes={scopes} onSelect={onSelectScopes} /> */}
        {/* <CategoriesSelect value={selectedCategories} categories={categories} onSelect={onSelectCategories} /> */}
      </Toolbar>
      {/* Кнопки переключения основного компонента */}
      <ExpenseClassSwitcher view={view} setView={setView} />

      {view === "branch" && <BranchExpensesGrid />}
      {view === "salary" && <SalaryExpensesGrid />}
      {view === "contracts" && <ContractExpensesGrid />}
      {view === "facilities" && <FacilityExpensesGrid />}
      {view === "shifts" && <ShiftExpensesGrid />}

      {/* Унести все параметры фильтрации в одни prop
      <TabContext value={currentTab}>
        <TabList value={currentTab} onChange={handleChangeTab} aria-label="basic tabs example">
          <Tab label="Branch" {...a11yProps(0)} />
          <Tab label="Salary" {...a11yProps(1)} />
          <Tab label="Contracts" {...a11yProps(2)} />
          <Tab label="Facilities" {...a11yProps(3)} />
          <Tab label="Shifts" {...a11yProps(4)} />
        </TabList>
        <TabPanel value={0} sx={{ padding: 0 }}></TabPanel>
        <TabPanel value={1} sx={{ padding: 0 }}></TabPanel>
        <TabPanel value={2} sx={{ padding: 0 }}></TabPanel>
        <TabPanel value={3} sx={{ padding: 0 }}></TabPanel>
        <TabPanel value={4} sx={{ padding: 0 }}></TabPanel>
        {/* <TabPanel value={1} sx={{ display: "flex", flex: 2, padding: 0 }}>
          <ExpensesGrid searchBy={searchBy} categories={selectedCategories} scopes={selectedScopes} />
        </TabPanel> */}
      {/* </TabContext> */}
    </Module>
  );
});

export default ExpensesModule;
