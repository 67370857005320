import React, { useCallback } from "react";
import { observer } from "mobx-react";

import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import FacilityIcon from "./FacilityIcon";
import FacilityInfoCard from "./FacilityInfoCard";

const FacilityButton = observer(({ facility, variant }) => {
  const handleClick = useCallback(() => {
    // console.log(facility);
  }, [facility]);

  const isCompact = variant === "compact";
  // const title = isCompact ? `${facility.label}: ${facility.description}` : `${facility.description}`;

  const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.arrow}`]: {
        color: "#1177d3",
      },
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#edf4fb",
        color: "rgba(0, 0, 0, 0.87)",
        minWidth: 400,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #1177d3",
      },
    })
  );

  const tooltipTitle = <FacilityInfoCard facility={facility} />;

  return (
    <HtmlTooltip title={tooltipTitle} placement="bottom-start">
      <Button onClick={handleClick} variant="outlined" size="small" startIcon={<FacilityIcon kind={facility.kind} />}>
        {!isCompact && facility.label}
      </Button>
    </HtmlTooltip>
  );
});

export default FacilityButton;
