import { observable, computed, action, makeObservable, runInAction } from "mobx";
import CommercialOffer from "./models/CommercialOffer";
import Document from "~/modules/documents/models/Document";

/**
 * Стор коммерческих предложений.
 */
class CommercialOffersStore {
  offersMap = new Map();
  totalValue = {};
  totalCount = 0;
  isPending = false;

  constructor(rootStore) {
    makeObservable(this, {
      offersMap: observable,
      totalValue: observable,
      totalCount: observable,
      isPending: observable,
      offers: computed,
      createOffer: action,
      readFromJSON: action,
      loadOffers: action,
    });

    this.root = rootStore;

    this.api = undefined;
    this.isPending = false;
  }

  /**
   * Сходить на сервер за номером.
   */
  async makeNumber(branch) {
    const url = `/api/v0/branches/${branch.id}/commercial-offer-numbers`;
    const response = await fetch(url, {
      method: "post",
      headers: { Authorization: this.root.authStore.token },
    });
    return await response.json();
  }

  /**
   * Дропнуть номер если пользователь его не использовал
   * (нажал Cancel без создания предложения).
   */
  async dropNumber(number) {
    const url = `/api/v0/commercial-offer-numbers/${number}`;
    await fetch(url, {
      method: "delete",
      headers: { Authorization: this.root.authStore.token },
    });
  }

  /**
   * Creates a new offer.
   */
  async createOffer(branch, data) {
    //      {
    //     "date": "14.07.2023",
    //     "client": "New Client",
    //     "clientId": "create new",
    //     "clientName": "New",
    //     "clientDescription": "super client",
    //     "value": "100",
    //     "description": "Сделать лучше всех",
    //     "documents": "",
    //     "branchId": "5"
    // }

    // если требуется - сделаем нового клиента

    runInAction(() => { this.isPending = true; });
    var clientId;
    if (data.clientId === "create new") {
      const newClientData = { name: data.clientName, description: data.clientDescription };
      const client = await this.root.clientStore.createClient(branch, newClientData);
      clientId = client.id;
    } else {
      clientId = data.clientId;
    }

    data = { ...data, client: clientId, clientId: clientId };

    // затем загрузим документы
    if (data.documents?.length) {
      const documentIds = await this.root.uploadDocuments(data.documents);
      data.documents = documentIds;
    } else {
      data.documents = [];
    }

    // остались date и value
    const url = `/api/v0/branches/${branch.id}/commercial-offers`;
    const response = await fetch(url, {
      method: "post",
      headers: { Authorization: this.root.authStore.token },
      body: JSON.stringify(data),
    });
    const created = await response.json();
    runInAction(() => { this.isPending = false; });
    return this.readFromJSON(created);
  }

  /**
   * Отредактировать коммерческое предложение.
   *
   * @param {CommercialOffer} offer экземпляр коммерческого предложения для редактирования
   * @param {object} data обновлённые данные коммерческого предложения
   */
  async editOffer(branch, offer, data) {
    runInAction(() => { this.isPending = true; });
    var clientId;
    if (data.clientId === "create new") {
      const newClientData = { name: data.clientName, description: data.clientDescription };
      const client = await this.root.clientStore.createClient(branch, newClientData);
      clientId = client.id;
    } else {
      clientId = data.clientId;
    }

    data = { ...data, client: clientId, clientId: clientId };

    // затем загрузим документы
    if (data.documents && data.documents.length) {
      const docIds = [];
      const docFiles = [];
      data.documents.forEach((doc) => {
        if (doc && doc.id) {
          docIds.push(doc.id);
        } else {
          docFiles.push(doc);
        }
      });
      const documentIdsArray = await this.root.uploadDocuments(docFiles);
      data.documents = [...docIds, ...documentIdsArray];
    } else {
      data.documents = [];
    }

    const url = `api/v0/commercial-offers/${offer.id}`;
    const response = await fetch(url, {
      method: "put",
      headers: { Authorization: this.root.authStore.token },
      body: JSON.stringify(data),
    });
    const updated = await response.json();
    runInAction(() => { this.isPending = false; });
    return this.readFromJSON(updated);
  }

  /**
   * Reads Offer data from JSON.
   *
   * @param {object} data JSON data w/ Offer's data.
   */
  readFromJSON(data) {
    const read = new CommercialOffer(this, data);
    this.offersMap.set(`${data.id}`, read);
    return read;
  }

  /**
   * Loads offers from server.
   */
  async loadOffers(branchId, daterange, searchBy, selectedStates, pagination) {
    runInAction(() => { this.isPending = true; });
    const { page, pageSize } = pagination;
    const backendPagination = { page: page + 1, perPage: pageSize };
    let params = { searchBy, states: selectedStates, ...backendPagination };
    if (!!daterange[0]) {
      params["from"] = daterange[0].format("YYYY-MM-DD");
    }
    if (!!daterange[1]) {
      params["to"] = daterange[1].format("YYYY-MM-DD");
    }
    params = new URLSearchParams(params);
    // переписать на использование API
    const url = `/api/v0/branches/${branchId}/commercial-offers?${params}`;
    const response = await fetch(url, {
      method: "GET",
      headers: { Authorization: this.root.authStore.token },
    });
    // TODO: @ai: check if not JSON in response
    const result = await response.json();
    const loadedOffers = result["offers"] || [];
    const totalValue = result["total"] || 0;
    var offers;
    runInAction(() => {
      this.offersMap.clear();
      this.totalCount = parseInt(response.headers.get("Total-Count") | "0");
      this.totalValue = totalValue;
      offers = loadedOffers.map((offer) => {
        offer.documents = offer.documents.map((d) => {
          return Document.create(d, this.root.documentStore);
        });
        this.readFromJSON(offer);
      });
      runInAction(() => { this.isPending = false; });
      // TODO: load documents
      // TODO: load clients
    });
    return offers;
  }

  /**
   * Актуальное (отфильтрованное, найденное) представление коммерческих предложений.
   */
  get offers() {
    // return [
    //   {
    //     id: 1,
    //     createdAt: new Date(),
    //     value: { amount: 100, currency: "USD" },
    //     status: "created",
    //     documents: [],
    //   },
    // ];
    const array = [];
    this.offersMap.forEach((offer) => {
      array.push(offer);
    });
    array.sort((a, b) => b.id - a.id);
    return array;
  }

  async changeOfferState(offerId, data) {
    const url = `/api/v0/commercial-offers/${offerId}/states`;
    const response = await fetch(url, {
      method: "POST",
      headers: { Authorization: this.root.authStore.token },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    runInAction(() => {
      this.readFromJSON(result); // обновить состояние
    });
  }

  // returns CommercialOffer by id
  offer(id) {
    return this.offersMap.get(`${id}`);
  }
}

export default CommercialOffersStore;
