import { computed, makeObservable, observable } from "mobx";

class ExpenseCategory {
  @observable
  store = null;
  @observable
  id = null;
  @observable
  name = null;

  constructor(props, store) {
    makeObservable(this);

    this.store = store;
    this.id = `${props.id}`;
    this.name = props.name;
  }

  @computed
  get label() {
    return this.name;
  }

  @computed
  get text() {
    return this.name;
  }

  @computed
  get value() {
    return this.id;
  }
}

export default ExpenseCategory;
