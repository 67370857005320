import { Card as MuiCard } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import useStores from "~/hooks";

/**
 * Базовая карточка.
 *
 * Подстраивает маржины под экран устройства чтобы карточки не
 * слипались выстроившись горизонтально, вертикально или в гриду.
 */
const Card = ({ children, backgroundColor, sx }) => {
  const { workspaceStore } = useStores();
  const { theme } = workspaceStore;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <MuiCard
      sx={{
        mt: isMobile ? "0.5rem" : 0,
        mr: isMobile ? 0 : "0.5rem",
        width: isMobile ? "100%" : "25rem",
        overflow: "initial",
        backgroundColor,
        ...sx,
      }}
    >
      {children}
    </MuiCard>
  );
};

export default Card;
