/**
 * Таблица отображения работчих смен сканировщиков.
 *
 * Нарисует чистый DataGrid из mui и контекстное меню которое используется
 * для доступа к скачиванию командировочных отчётов.
 *
 * Пагинация должна быть серверной.
 */
import { observer } from "mobx-react";
import { useCallback, useMemo, useState, useEffect } from "react";

import { Box, Typography } from "@mui/material";
import { DataGrid } from "~/components/grid";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";

import Translator from "~/components/Translator";
import useStores from "~/hooks";

import ScannerShiftContextMenu from "./ScannerShiftContextMenu";
import { FacilitiesButton } from "~/modules/facilities/components";

import ScannerShiftButton from "./ScannerShiftButton";
import CarShiftButton from "./CarShiftButton";
import PublicTransportShiftButton from "./PublicTransportShiftButton";
import { AddingShiftExpenseDialog } from "~/modules/expenses/components/dialogs";

const ScannersWorkingShiftsGrid = observer((props) => {
  const [selectedRow, setSelectedRow] = useState();
  const [rowMenuPosition, setRowMenuPosition] = useState([null, null]);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 25 });
  const [isAddingShiftExpenseDialogOpen, setIsAddingShiftExpenseDialogOpen] = useState(false);
  const [rowMenuIsOpen, setRowMenuIsOpen] = useState(false);
  const { languageStore, branchStore } = useStores();
  const { store, searchBy, daterange } = props;
  const { branch } = branchStore;

  useEffect(() => {
    if (paginationModel.pageSize < 1) return;
    if (!branch) return;
    const effect = async () => {
      await store.fetchShifts(branch.id, paginationModel, searchBy, daterange);
    };
    effect();
  }, [store, branch, paginationModel, searchBy, daterange]);

  const columns = useMemo(
    () => [
      {
        field: "id",
        type: "integer",
        width: 75,
        headerName: languageStore.translate({ text: "ID" }),
        renderCell: ({ row }) => {
          return row.id;
        },
      },
      {
        field: "employee",
        type: "string",
        width: 150,
        valueGetter: (value) => value.employee?.name,
        headerName: languageStore.translate({ text: "Employee" }),
        renderCell: ({ row }) => row.employee?.name,
      },
      {
        field: "startedAt",
        headerName: languageStore.translate({ text: "Started at" }),
        type: "datetime",
        width: 150,
        renderCell: ({ row }) => <Translator datetime={row.startedAt} variant="compact" />,
      },
      {
        field: "finishedAt",
        headerName: languageStore.translate({ text: "Finished at" }),
        type: "datetime",
        width: 150,
        renderCell: ({ row }) => {
          if (row.finishedAt === null) {
            return (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <AccessTimeFilledIcon color="warning" />
              </Box>
            );
          }
          return <Translator datetime={row.finishedAt} variant="compact" />;
        },
      },
      {
        field: "duration",
        headerName: languageStore.translate({ text: "Duration, hours" }),
        type: "number",
        width: 125,
        renderCell: ({ row }) => {
          if (row.finishedAt === null) {
            return <Typography color="warning.main">{row.duration}</Typography>;
          }
          return row.duration;
        },
      },
      {
        field: "scanner",
        headerName: languageStore.translate({ text: "Scanner" }),
        type: "string",
        width: 275,
        renderCell: ({ row }) => {
          return !!row.scanner ? <ScannerShiftButton scanner={row.scanner} shift={row} /> : "";
        },
      },
      {
        field: "stationsCount",
        headerName: languageStore.translate({ text: "Stations Count" }),
        type: "number",
        width: 125,
        renderCell: ({ row }) => {
          return row.stationsCount;
        },
      },
      {
        field: "transport",
        headerName: languageStore.translate({ text: "Vehicle" }),
        type: "string",
        width: 300,
        renderCell: ({ row }) => {
          return !!row.car ? <CarShiftButton car={row.car} shift={row} /> : <PublicTransportShiftButton shift={row} />;
        },
      },
      {
        field: "mileage",
        headerName: languageStore.translate({ text: "Mileage" }),
        type: "number",
        width: 100,
        renderCell: ({ row }) => {
          return row.mileage;
        },
      },
      {
        field: "facilities",
        headerName: languageStore.translate({ text: "Facilities" }),
        type: "",
        minWidth: 200,
        maxWidth: 350,
        flex: 1,
        renderCell: ({ row }) => {
          return <FacilitiesButton facilities={row.facilities} />;
        },
      },
      {
        field: "facilityExpense",
        headerName: languageStore.translate({ text: "Facility expenses" }),
        type: "number",
        width: 150,
        renderCell: ({ row }) => {
          return <Translator money={row.facilityExpense} variant="compact" />;
        },
      },
      {
        field: "shiftExpensesSum",
        headerName: languageStore.translate({ text: "Shift expenses" }),
        type: "number",
        width: 125,
        renderCell: ({ row }) => {
          return <Translator money={row.shiftExpensesSum} variant="compact" />;
        },
      },
      {
        field: "totalExpensesSum",
        headerName: languageStore.translate({ text: "Total expenses" }),
        type: "number",
        width: 125,
        renderCell: ({ row }) => {
          return <Translator money={row.totalExpensesSum} variant="compact" />;
        },
      },
    ],
    [languageStore]
  );

  // Обработчик контекстного меню
  const handleContextMenu = useCallback(
    (event) => {
      event.preventDefault();
      const id = Number(event.currentTarget.getAttribute("data-id"));
      if (!store.getShift(id).isFinished) {
        return;
      }
      setSelectedRow(id);
      setRowMenuPosition([event.clientX - 2, event.clientY - 4]);
      setRowMenuIsOpen(true);
    },
    [store]
  );

  // Выбранная рабочая смена
  const shift = useMemo(() => {
    return store.getShift(selectedRow);
  }, [selectedRow, store]);

  const handleClose = useCallback(() => {
    setSelectedRow(null);
    setRowMenuPosition([null, null]);
    setRowMenuIsOpen(false);
  }, []);

  // Скачать отчёт по командировке
  const downloadTripCertificate = useCallback(async () => {
    const docx = await store.api.downloadTripCertificate(selectedRow);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    const url = window.URL.createObjectURL(docx);
    a.href = url;
    // a.download = docx.file.name; // TODO: разобраться с именем файла
    a.click();
    handleClose();
    window.URL.revokeObjectURL(url);
  }, [selectedRow, handleClose, store.api]);

  // Добавить расход смены
  const addShiftExpense = useCallback(() => {
    setRowMenuIsOpen(false);
    setIsAddingShiftExpenseDialogOpen(true);
  }, []);

  /** TODO: поменять на про-дата-грид */
  return (
    <>
      <DataGrid
        columns={columns}
        slotProps={{ row: { onContextMenu: handleContextMenu, style: { cursor: "context-menu" } } }}
        // TODO: можно через тему поменять везде? см. https://stackoverflow.com/a/54918529
        rows={store.shifts}
        pagination={true}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        loading={store.isPending}
        pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
        rowCount={store.totalCount}
        paginationMode="server"
      />
      <ScannerShiftContextMenu
        isOpen={rowMenuIsOpen}
        position={rowMenuPosition}
        handleClose={handleClose}
        downloadTripCertificate={downloadTripCertificate}
        addShiftExpense={addShiftExpense}
      />
      <AddingShiftExpenseDialog
        shift={shift}
        isOpen={isAddingShiftExpenseDialogOpen}
        onClose={() => setIsAddingShiftExpenseDialogOpen(false)}
      />
    </>
  );
});

export default ScannersWorkingShiftsGrid;
