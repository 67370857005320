import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { Box, Divider, List, ListItem, ListItemAvatar, ListItemText, useTheme, Tooltip } from "@mui/material";
import { ResponsiveRadialBar } from "@nivo/radial-bar";

import Translator from "../../../components/Translator";
import { CalendarMonth, DocumentScanner, House, Work } from "@mui/icons-material";
import ExpenseListItem from "../../../components/ExpenseListItem";

const ContractExpenses = observer(({ contract, isTiny, onClick }) => {
  const theme = useTheme();

  const { allExpenses: expenses, currency, expensesPlanTotal } = contract;

  const [
    [contractTotalExpense, facilityTotalExpense, shiftTotalExpense],
    [contractExpenses, facilityExpenses, shiftExpenses],
  ] = useMemo(() => {
    let contractTotalExpense = 0;
    let facilityTotalExpense = 0;
    let shiftTotalExpense = 0;
    const shiftExpenses = [];
    const contractExpenses = [];
    const facilityExpenses = [];
    for (const expense of expenses) {
      if (expense.isContractExpense) {
        contractExpenses.push(expense);
        contractTotalExpense += expense.value.amount;
      } else if (expense.isFacilityExpense) {
        facilityExpenses.push(expense);
        facilityTotalExpense += expense.value.amount;
      } else if (expense.isShiftExpense) {
        shiftExpenses.push(expense);
        shiftTotalExpense += expense.value.amount;
      }
    }
    return [
      [contractTotalExpense, facilityTotalExpense, shiftTotalExpense],
      [contractExpenses, facilityExpenses, shiftExpenses],
    ];
  }, [expenses]);

  const data = useMemo(() => {
    const array = [];
    const result = [];

    if (contractTotalExpense) {
      array.push({
        x: "Contract",
        colorSlug: "success",
        itemIcon: <DocumentScanner color="success" />,
        label: <Translator text={"Contract"} />,
        y: contractTotalExpense,
        color: theme.palette.success.light,
      });
    }
    if (shiftTotalExpense) {
      array.push({
        x: "Shift",
        colorSlug: "warning",
        itemIcon: <Work color="warning" />,
        label: <Translator text={"Shift"} />,
        y: shiftTotalExpense,
        color: theme.palette.warning.light,
      });
    }
    if (facilityTotalExpense) {
      array.push({
        x: "Facility",
        colorSlug: "error",
        itemIcon: <House color="error" />,
        label: <Translator text={"Facility"} />,
        y: facilityTotalExpense,
        color: theme.palette.error.light,
      });
    }

    result.push({ id: <Translator text={"Fact"} />, data: array });
    if (expensesPlanTotal > 0) {
      result.push({
        id: "",
        label: <Translator text={"Plan"} />,
        data: [
          {
            x: <Translator text={"Plan"} />,
            colorSlug: "info",
            itemIcon: <CalendarMonth color="info" />,
            label: <Translator text={"Plan"} />,
            y: Number(expensesPlanTotal),
            color: theme.palette.info.light,
          },
        ],
      });
    }
    return result;
  }, [expensesPlanTotal, theme, contractTotalExpense, facilityTotalExpense, shiftTotalExpense]);

  // Суммарный расход числом
  const expenseFact = useMemo(
    () => contractTotalExpense + facilityTotalExpense + shiftTotalExpense,
    [contractTotalExpense, facilityTotalExpense, shiftTotalExpense]
  );

  const CenteredMetric = ({ center }) => {
    return (
      <text
        x={center[0]}
        y={center[1]}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: "1.5rem",
          fontWeight: 600,
          fill: theme.palette.text.primary,
        }}
      >
        {<Translator number={expenseFact} currency={currency} />}
      </text>
    );
  };

  const expensesList = useMemo(() => {
    const items = [];

    contractExpenses.forEach((item) => {
      items.push(
        <ExpenseListItem
          key={`cat1-${item.category}-${item.id}`}
          icon={<DocumentScanner sx={{ mr: "0.25rem" }} color="success" />}
          expense={item}
        />
      );
    });

    shiftExpenses.forEach((item) => {
      items.push(
        <ExpenseListItem
          key={`cat2-${item.category}-${item.id}`}
          icon={<Work sx={{ mr: "0.25rem" }} color="warning" />}
          expense={item}
        />
      );
    });

    facilityExpenses.forEach((item) => {
      items.push(
        <ExpenseListItem
          key={`cat3-${item.category}-${item.id}`}
          icon={<House sx={{ mr: "0.25rem" }} color="error" />}
          expense={item}
        />
      );
    });

    return items;
  }, [contractExpenses, facilityExpenses, shiftExpenses]);

  if (isTiny) {
    return (
      <Tooltip arrow title={<Translator text={"View contract expenses"} />}>
        <ListItem dense={false} sx={{ p: 0 }} onClick={onClick}>
          <ListItemAvatar>
            <Box sx={{ width: "3.5rem", height: "3.5rem" }}>
              <ResponsiveRadialBar
                data={data}
                innerRadius={0.3}
                radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
                circularAxisOuter={{
                  tickSize: 5,
                  tickPadding: 12,
                  tickRotation: 0,
                }}
                padAngle={0}
                endAngle={360}
                cornerRadius={0}
                colors={{ datum: "data.color" }}
                layers={["bars"]}
              />
            </Box>
          </ListItemAvatar>
          <Box sx={{ flex: 1, cursor: "pointer" }}>
            <ListItemText
              primaryTypographyProps={{ whiteSpace: "nowrap" }}
              primary={
                <>
                  <Translator text="Fact" />: <Translator number={expenseFact} currency={currency} />
                </>
              }
              secondary={
                <>
                  <Translator text="Plan" />: <Translator number={expensesPlanTotal} currency={currency} />
                </>
              }
            />
          </Box>
        </ListItem>
      </Tooltip>
    );
  }

  return (
    <Box>
      <Box sx={{ height: "30rem", position: "relative", margin: "0 auto" }}>
        <ResponsiveRadialBar
          data={data}
          margin={{ top: 10, right: 10, bottom: 100, left: 10 }}
          radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
          circularAxisOuter={{ tickSize: 5, tickPadding: 12, tickRotation: 0 }}
          innerRadius={0.6}
          padAngle={1}
          endAngle={360}
          cornerRadius={5}
          colors={{ datum: "data.color" }}
          activeOuterRadiusOffset={8}
          layers={["grid", "bars", "legends", CenteredMetric]}
          enableLabels={true}
          enableCircularGrid={false}
          isInteractive={true}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: 56,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: "#999",
              itemDirection: "left-to-right",
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#000",
                  },
                },
              ],
            },
          ]}
        />
      </Box>
      <Divider />
      <List dense={true}>{expensesList}</List>
    </Box>
  );
});

export default ContractExpenses;
