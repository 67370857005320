import React, { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react";

import {
  Box,
  Button,
  Dialog,
  CardContent,
  CardActions,
  Typography,
  Tooltip,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Difference, Done, FileOpen, FilePresent, Start } from "@mui/icons-material";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";

import useStores from "~/hooks";
import { Card } from "~/components/cards";

import Form from "../../../../components/Form";
import Translator from "../../../../components/Translator";
import Preloader from "../../../../components/Preloader";
import HistoryView from "~/modules/contracts/components/HistoryView";
import ScanningExpenses from "../../ScanningExpenses";

import FinishScanForm from "../forms/FinishScanForm";
import StartFinishDatetimes from "../StartFinishDatetimes";

import { FacilityChip } from "~/modules/facilities/components/bricks";
import { Notify } from "~/components";

import { UploadRawResultButton, UploadLinkedResultButton } from "../buttons";
import { UploadingRawResultDialog, UploadingLinkedResultDialog } from "../dialogs";

import { AddingFacilityExpenseDialog } from "~/modules/expenses/components/dialogs";

/* eslint complexity: 0 */
/**
 * Карточка плана / выполнения сканирования.
 */
const ScanningCard = observer(({ plan, index }) => {
  const { rootStore, branchStore, languageStore, materialsStore, scanningPlansStore, workingShiftsStore } = useStores();
  const { branch } = branchStore;
  const { shift } = workingShiftsStore;
  const { currentLangId } = languageStore;

  const [isNotifyOpen, setIsNotifyOpen] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [isModalPending, setModalPending] = useState(false);
  const [materialVisible, setMaterialVisible] = useState(false);
  const [materialArray, setMaterialArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [formType, setFormType] = useState("scanning");

  // ----
  const [isUploadingRawResultDialogOpen, setIsUploadingRawResultDialogOpen] = useState(false);
  const [isUploadingLinkedResultDialogOpen, setIsUploadingLinkedResultDialogOpen] = useState(false);
  const [isAddingFacilityExpenseDialogOpen, setIsAddingFacilityExpenseDialogOpen] = useState(false);
  const [isPending, setIsPending] = useState(false);
  // ----

  const currentScannerShift = shift;

  const {
    status,
    uploadScanResultFormConfig,
    uploadLinkingResultFormConfig,
    scannigExpensesFormConfig,
    hasRaw,
    hasLinked,
    isPendingExpenses,
  } = plan;

  const [finishFormErrors, setFinishFormErrors] = useState({});

  const [isFinishFormVisible, setIsFinishFormVisible] = useState(false);

  const materialFormConfig = useMemo(() => {
    if (materialsStore.isPending) return {};
    if (!materialArray) return {};
    const currentMaterial = materialArray[currentPage];
    if (!currentMaterial) {
      return {};
    }
    return {
      submitText: currentPage < materialArray.length - 1 ? "Next" : "Start",
      cancelText: "Cancel",
      formTitle: currentMaterial.name,
      content: <div className="text" dangerouslySetInnerHTML={{ __html: currentMaterial.text }}></div>,
      fields: [
        {
          name: "confirm",
          title: `I have read the material`,
          type: "boolean",
          isRequired: true,
          isReadOnly: false,
          validate: true,
          initialValue: false,
        },
      ],
    };
  }, [materialsStore, materialArray, currentPage]);

  // Перед стартом объекта показать материалы.
  const onClickStart = useCallback(async () => {
    setCurrentPage(0);
    setMaterialVisible(true);
    const materials = await materialsStore.fetchMaterialsForRole(branch.id, "scanner", currentLangId);
    if (materials.length > 0) {
      setMaterialArray(materials); // показать материал
    } else {
      await scanningPlansStore.startScanning(plan); // а если их нет - начать сканирование
      setIsNotifyOpen(true);
    }
  }, [plan, branch.id, materialsStore, currentLangId, scanningPlansStore]);

  // начать сканирование
  const onStartScanning = useCallback(async () => {
    setMaterialVisible(false);
    await scanningPlansStore.startScanning(plan);
    setIsNotifyOpen(true);
  }, [plan, scanningPlansStore]);

  // завершить сканирование
  const onSubmitFinishFormDialog = useCallback(
    async (values) => {
      const result = await scanningPlansStore.finishScanning(plan, values);
      if (result[0]) {
        setFinishFormErrors({});
        setIsFinishFormVisible(false);
      } else {
        setFinishFormErrors(result[1]);
      }
    },
    [plan, scanningPlansStore]
  );

  const onSubmitPage = useCallback(
    (data) => {
      if (data.confirm && (data.confirm === "true" || data.confirm === true)) {
        if (currentPage + 1 === materialArray.length) {
          onStartScanning();
        } else {
          setCurrentPage(currentPage + 1);
        }
      }
    },
    [onStartScanning, currentPage, materialArray]
  );

  const onCloseMaterial = useCallback(
    (e, reason) => {
      if (reason === "backdropClick") return;
      setMaterialVisible(false);
      setCurrentPage(0);
    },
    [plan]
  );

  const onSetFinish = useCallback(() => {
    setIsFinishFormVisible(true);
  }, [plan]);

  const onSetExpenses = useCallback(() => {
    setFormType("expenses");
    setFormVisible(true);
  }, []);

  const onCloseDialog = useCallback((e, reason) => {
    if (reason === "backdropClick") return;
    setFormVisible(false);
  }, []);

  if (!plan) {
    return null;
  }

  // Открыть диалог указания ссылки на грязное облако
  const handleUploadRawResult = useCallback(() => {
    setIsUploadingRawResultDialogOpen(true);
  }, []);

  // Открыть диалог указания ссылки на сшитое облако
  const handleUploadLinkedResult = useCallback(() => {
    setIsUploadingLinkedResultDialogOpen(true);
  }, []);
  // ----

  const onSubmit = useCallback(
    async (data) => {
      try {
        setModalPending(true);
        if (formType === "expenses") {
          await plan.setFacilityExpenses(data);
        } else {
          await plan.addResult(data, formType);
        }
      } catch (error) {
        rootStore.setError("error", null, error && error.message);
      }
      setModalPending(false);
      setFormVisible(false);
    },
    [plan, formType, rootStore]
  );

  const [isDiff, setIsDiff] = useState(true);

  const onDiffToggle = useCallback(() => {
    setIsDiff(!isDiff);
  }, [isDiff, setIsDiff]);

  const [historyVisible, setHistoryVisible] = useState(false);

  const showHistory = useCallback(() => {
    setHistoryVisible(true);
  }, []);

  const hideHistory = useCallback(() => {
    setHistoryVisible(false);
  }, []);

  const colorSlug = useMemo(() => {
    if (status === "started") {
      return "warning";
    } else if (hasRaw && hasLinked) {
      return "primary";
    } else if (hasRaw) {
      return "success";
    } else if (status === "finished") {
      return "info";
    } else {
      return "primary";
    }
  }, [status, hasRaw, hasLinked]);

  const [expensesVisible, setExpensesVisible] = useState(false);

  const showExpenses = useCallback(() => {
    setExpensesVisible(true);
  }, []);

  const hideExpenses = useCallback((e, reason) => {
    if (reason === "backdropClick") return;
    setExpensesVisible(false);
  }, []);

  const expensesButton = useMemo(() => {
    if (isPendingExpenses || !plan || !plan.expensesTotal) {
      return null;
    }
    return (
      <Tooltip
        arrow
        title={
          <Typography variant="body">
            <Translator text={"View scanning expenses"} />
          </Typography>
        }
      >
        <Box sx={{ cursor: "pointer", mt: "1rem" }} onClick={showExpenses}>
          {/* <ScanningExpenses scanning={scanning} isTiny={true} /> */}
        </Box>
      </Tooltip>
    );
  }, [showExpenses, plan, isPendingExpenses]);

  // const historyButton = useMemo(() => {
  //   return (
  //     <Tooltip
  //       arrow
  //       title={
  //         <Typography variant="body">
  //           <Translator text={"View contract history"} />
  //         </Typography>
  //       }
  //     >
  //       <HistoryRounded onClick={showHistory} sx={{ cursor: "pointer" }} color={colorSlug} />
  //     </Tooltip>
  //   );
  // }, [showHistory, colorSlug]);

  const formConfigMemo = useMemo(() => {
    if (formType === "scanning") {
      return uploadScanResultFormConfig;
    } else if (formType === "linking") {
      return uploadLinkingResultFormConfig;
    } else if (formType === "expenses") {
      return scannigExpensesFormConfig;
    }
  }, [uploadScanResultFormConfig, uploadLinkingResultFormConfig, scannigExpensesFormConfig, formType]);

  const onCancelFinishFormDialog = useCallback(() => {
    setIsFinishFormVisible(false);
  }, []);

  // Использовать палитру цветов!
  const backgroundColor = useMemo(() => {
    if (status === "started") return "#fff2cf";
    if (status === "finished" && !hasLinked) return "#d5e2f2";
    if (status === "finished" && hasLinked) return "#d9ead4";
    return "#eeeeee";
  }, [status, hasLinked]);

  return (
    <>
      <Card backgroundColor={backgroundColor}>
        <CardContent sx={{ p: "0.5rem" }}>
          <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
            <FacilityChip facility={plan.facility} />
            <Typography variant="h6" sx={{ ml: 1, color: "#303030", flex: 1, p: 0, lineHeight: "1.5rem" }}>
              {plan.facility.name}
            </Typography>
            <Typography variant="h6" sx={{ color: "#0288d1", pl: "1rem" }}>
              {index + 1}
            </Typography>
          </Box>
          {/* Время плана сканирования */}
          <StartFinishDatetimes scanning={plan} />
          {/*  */}
          <Typography sx={{ mt: "0.5rem" }}>{plan.facility.description}</Typography>

          <Box sx={{ flexDirection: "column", display: "flex" }}>
            {(plan.started || plan.finished) && false && (
              <Typography variant="h6">
                <Translator text="Fact" />
              </Typography>
            )}
            {plan.started && false && (
              <Box
                sx={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  mt: 1,
                }}
              >
                <Box>
                  <Typography sx={{ fontSize: "1.25rem" }}>
                    <Translator time={plan.started.datetime} variant="compact" />
                  </Typography>
                  <Typography sx={{ fontSize: "0.75rem" }}>
                    <Translator date={plan.started.datetime} variant="compact" />
                  </Typography>
                </Box>
                <Box
                  sx={{
                    flexDirection: "column",
                    display: "flex",
                    m: 0.5,
                  }}
                >
                  <Start color="primary" />
                </Box>
                {plan.finished && (
                  <Box>
                    <Typography sx={{ fontSize: "1.25rem" }}>
                      <Translator time={plan.finished.datetime} variant="compact" />
                    </Typography>
                    <Typography sx={{ fontSize: "0.75rem" }}>
                      <Translator date={plan.finished.datetime} variant="compact" />
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>
          {(plan.hasRaw || plan.hasLinked) && (
            <Box sx={{ flexDirection: "row", display: "flex", justifyContent: "space-between" }}>
              {hasRaw && (
                <Button href={plan.result?.rawCloud} target="blank" startIcon={<FileOpen />}>
                  <Translator text="Raw cloud" />
                </Button>
              )}
              {hasLinked && (
                <Button href={plan.result?.linkedCloud} target="blank" startIcon={<FilePresent />}>
                  <Translator text="Linked cloud" />
                </Button>
              )}
            </Box>
          )}
          {expensesButton}
        </CardContent>
        {/* Загрузка результата */}
        {/*  */}
        <CardActions
          sx={{ p: "0.5rem", flexDirection: "column", alignItems: "end", justifyContent: "end", width: "100%" }}
        >
          {plan.isFinished && (
            <CardActions sx={{ p: "0.5rem", pr: 0, alignItems: "end", justifyContent: "end", width: "100%" }}>
              {!hasRaw && <UploadRawResultButton onClick={handleUploadRawResult} />}
              {!hasLinked && <UploadLinkedResultButton onClick={handleUploadLinkedResult} />}
            </CardActions>
          )}

          <Button
            variant="contained"
            startIcon={<MoneyOffIcon />}
            disabled={branch.isPendingExpensesForFacility}
            onClick={() => setIsAddingFacilityExpenseDialogOpen(true)}
          >
            <Translator text="Add facility expense" />
          </Button>

          {!plan.isStarted && currentScannerShift && (
            <Button
              variant="contained"
              sx={{ m: "0.125rem" }}
              startIcon={<Start />}
              color={"success"}
              onClick={onClickStart}
              disabled={scanningPlansStore.isPending}
            >
              <Translator text="Start scanning" />
            </Button>
          )}
          {!plan.isFinished && plan.isStarted && currentScannerShift && (
            <Button
              variant="contained"
              sx={{ m: "0.125rem" }}
              startIcon={<Done />}
              color={"success"}
              onClick={onSetFinish}
            >
              <Translator text="Finish scanning" />
            </Button>
          )}
        </CardActions>

        {/* Диалог завершения сканирования */}
        <Dialog sx={{ ".MuiPaper-root": { maxWidth: "90vw" } }} open={isFinishFormVisible} onClose={onCloseDialog}>
          {!isModalPending && (
            <FinishScanForm
              onSubmit={onSubmitFinishFormDialog}
              onClose={onCancelFinishFormDialog}
              errors={finishFormErrors}
              askOdometerReadings={currentScannerShift && currentScannerShift.isCarUsed}
            />
          )}
          <Preloader isPending={isModalPending} />
        </Dialog>

        {/* Диалог хуй знает чего */}
        <Dialog sx={{ ".MuiPaper-root": { maxWidth: "90vw" } }} open={formVisible} onClose={onCloseDialog}>
          {!isModalPending && (
            <Form
              config={formConfigMemo}
              onSubmit={onSubmit}
              onCancel={onCloseDialog}
              initialValues={{ contractId: plan.id }}
            />
          )}
          <Preloader isPending={isModalPending} />
        </Dialog>

        {/* Диалог отображения материала */}
        <Dialog
          sx={{ ".MuiPaper-root": { maxWidth: "90vw" } }}
          open={materialVisible && materialArray.length > 0}
          onClose={onCloseMaterial}
        >
          {!materialsStore.isPending && (
            <Form config={materialFormConfig} onSubmit={onSubmitPage} onCancel={onCloseMaterial} />
          )}
          <Preloader isPending={materialsStore.isPending} />
        </Dialog>

        {/* Диалог отображения истории? */}
        <Dialog sx={{ ".MuiPaper-root": { maxWidth: "90vw" } }} open={historyVisible} onClose={hideHistory}>
          <DialogTitle
            sx={{
              display: "flex",
              dlexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Translator text="Scan result history" />
            <Button
              variant="outlined"
              onClick={onDiffToggle}
              startIcon={<Difference />}
              color={isDiff ? "warning" : "info"}
            >
              <Translator text={isDiff ? "Difference" : "Full"} />
            </Button>
          </DialogTitle>
          <DialogContent sx={{ p: "0.25rem" }}>
            <HistoryView isDiff={isDiff} contract={plan} />
          </DialogContent>
          <DialogActions>
            <Button onClick={hideHistory}>
              <Translator text="close" />
            </Button>
          </DialogActions>
        </Dialog>

        {/*  */}
        {/* Диалог отображения расхода по объекту */}
        <Dialog sx={{ ".MuiPaper-root": { maxWidth: "90vw" } }} open={expensesVisible} onClose={hideExpenses}>
          <DialogTitle
            sx={{
              display: "flex",
              dlexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Translator text="Scanning expenses" />
          </DialogTitle>
          <DialogContent>{plan && <ScanningExpenses scanning={plan} />}</DialogContent>
          <DialogActions>
            <Button onClick={hideExpenses}>
              <Translator text="Close" />
            </Button>
          </DialogActions>
        </Dialog>
      </Card>

      {/* Диалог указания чистого результата сканирования */}
      <UploadingRawResultDialog
        plan={plan}
        isOpen={isUploadingRawResultDialogOpen}
        onClose={() => setIsUploadingRawResultDialogOpen(false)}
      />
      {/* Диалог указания сшитого облака */}
      <UploadingLinkedResultDialog
        plan={plan}
        isOpen={isUploadingLinkedResultDialogOpen}
        onClose={() => setIsUploadingLinkedResultDialogOpen(false)}
      />
      {/* TODO: унести в диалог начала сканирования */}
      <Notify isOpen={isNotifyOpen} onClose={() => setIsNotifyOpen(false)}>
        <Translator text={"Scanning started"} after={"—"} />
        {plan.facility.name}
      </Notify>

      <AddingFacilityExpenseDialog
        facility={plan.facility}
        isOpen={isAddingFacilityExpenseDialogOpen}
        onClose={() => {
          setIsAddingFacilityExpenseDialogOpen(false);
        }}
      />
    </>
  );
});

export default ScanningCard;
