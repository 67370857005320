import { makeObservable, observable, computed } from "mobx";

class Scanner {
  @observable id = -1;
  @observable name = "";
  @observable serial = "";

  constructor({ id, name, serial }) {
    makeObservable(this);

    this.id = id;
    this.name = name;
    this.serial = serial;
  }

  @computed get 
  label() {
    return `${this.name} (${this.serial})`;
  }
}

export default Scanner;
