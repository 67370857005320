import React, { useMemo } from "react";
import { observer } from "mobx-react";

import { Box, Button, Card, CardActions, CardContent, Typography } from "@mui/material";

import useStores from "~/hooks";
import { ViewInAr } from "@mui/icons-material";

const FacilityCard = observer(({ id }) => {
  const { facilityStore } = useStores();
  const facility = useMemo(() => {
    return facilityStore.getFacilityById(id);
  }, [id, facilityStore]);

  if (!facility) {
    return null;
  }

  return (
    <Card>
      <CardContent>
        <Box sx={{ flexDirection: "row", display: "flex", mt: 1 }}>
          <Box sx={{ flexDirection: "row", display: "flex" }}>
            <Box
              sx={{
                flexDirection: "column",
                display: "flex",
                mr: 0.5,
                mt: 0.5,
              }}
            >
              <ViewInAr color="primary" />
            </Box>
            <Box>
              {facility.name && <Typography variant="h6">{facility.name}</Typography>}
              {facility.address && <Typography variant="body2">{facility.address}</Typography>}
              {facility.area && (
                <Typography variant="body2">
                  {facility.area} m<sup>2</sup>
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </CardContent>
      <CardActions>
        <Button variant="outlined" color="info">
          Изменить
        </Button>
      </CardActions>
    </Card>
  );
});

export default FacilityCard;
