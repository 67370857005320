import { action, computed, makeObservable, observable } from "mobx";
import moment from "moment";
import momentTZ from "moment-timezone";

import "moment/locale/ru";
import "moment/locale/it";
import "moment/locale/pl";

import languages from "../common/languages.json";

class LanguageStore {
  @observable lang = "en";
  @observable rootStore = null;
  @observable api = null;

  @observable languages = {};

  @observable translationsMap = new Map();

  @observable pending = false;

  @observable fetchLanguageAbortController = null;

  constructor(rootStore) {
    makeObservable(this);

    this.rootStore = rootStore;
    this.api = this.rootStore.api;
  }

  @action init() {
    const lang = localStorage.getItem("lang");
    if (lang) {
      this.setLang(lang);
    } else {
      localStorage.setItem("lang", this.lang);
    }
    this.languages = languages;
  }

  @action setLang(lang) {
    this.lang = lang;
    moment.locale(lang);
    momentTZ.updateLocale(lang, moment.localeData()._config); // copy locale to moment-timezone
    momentTZ.locale(lang); // apply it to moment-timezone
    momentTZ.tz.setDefault("Europe/Warsaw");
    localStorage.setItem("lang", this.lang);
    this.fetchTranslations(this.lang);
  }

  @action getText(path) {
    return this.translationsMap.get(path) || path;
  }

  /**
   * Перевести на this.currentLangId.
   *
   * @param {String} text текст для перевода
   * @param {String} before текст перед
   * @param {String} after текст после
   * @param {moment} date дата для перевода
   * @param {String} format формат времени для перевода
   *
   * @returns {String} переведенная.
   */
  translate({ text, before, after, date, format, time, variant, datetime, number, currency, numberOfDigits, money }) {
    if (text) {
      return this.translateText({ text, before, after });
    } else if (date) {
      return this.translateDate({ date, format });
    } else if (time) {
      return this.translateTime({ time, variant, format });
    } else if (datetime) {
      return this.translateDatetime({ datetime, variant, format });
    } else if (number !== null && number !== undefined) {
      return this.translateNumber({ number, after, currency, numberOfDigits });
    } else if (money !== null && money !== undefined) {
      return this.translateMoney({ money, after });
    }
    return text && `${before ? `${before} ` : ""}${this.getText(text) || ""}${after ? ` ${after}` : ""}`;
  }

  translateText({ text, before, after }) {
    return text && `${before ? `${before} ` : ""}${this.getText(text) || ""}${after ? ` ${after}` : ""}`;
  }

  translateDate({ date, format }) {
    // const localDatetime = momentTZ(date).tz("Europe/Warsaw")
    return moment(date).format(format || "L");
  }

  translateTime({ time, variant, format }) {
    if (variant === "compact") {
      return moment(time).format(format || "LT");
    }
    return moment(time).format(format || "LTS");
  }

  translateDatetime({ datetime, variant, format }) {
    // TODO: зону нужно брать из Branch но её там сейчас нет(
    const localDatetime = momentTZ(datetime).tz("Europe/Warsaw");

    if (variant === "compact") {
      return localDatetime.format(format || "L LT");
    }

    return localDatetime.format(format || "LLLL");
  }

  translateNumber({ number, after, currency, numberOfDigits }) {
    const str = Number(number).toLocaleString(this.currentLangLocale, {
      currency,
      style: currency && "currency",
      minimumFractionDigits: numberOfDigits,
      maximumFractionDigits: numberOfDigits,
    });
    return `${str}${after ? `${after}` : ""}`;
  }

  translateMoney({ money, after }) {
    const str = Number(money).toLocaleString(this.currentLangLocale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return `${str}${after ? `${after}` : ""}`;
  }

  @action async fetchTranslations(lang) {
    if (lang) {
      if (this.fetchLanguageAbortController) {
        this.fetchLanguageAbortController.abort();
        this.fetchLanguageAbortController = null;
      }
      try {
        this.setPending(true);
        const translationsData = await this.fetchTranslationsWithAbortControll(lang);
        this.processTranslations(translationsData);
      } catch (error) {
        console.warn(error);
      }
      this.setPending();
    }
  }
  @action
  processTranslations(data) {
    this.translationsMap.replace(data);
  }

  @action
  async fetchTranslationsWithAbortControll(lang) {
    if (lang) {
      const error = new Error("Fetch aborted by the user", "AbortError");

      this.fetchLanguageAbortController = new AbortController();
      this.fetchLanguageAbortController.signal.addEventListener("abort", () => {
        // throw error;
      });
      if (this.fetchLanguageAbortController.signal.aborted) {
        // throw error;
        
      }
      return await this.api.getTranslationsForLang(lang, this.rootStore.isLocal);
    }
  }

  @action
  setPending(pending = false) {
    this.pending = pending;
  }

  @computed
  get isPending() {
    return this.pending;
  }

  @computed get languagesArray() {
    const array = Object.keys(this.languages).map((key) => {
      return {
        id: key,
        label: this.languages[key].label,
        name: this.languages[key].languageName,
        flag: this.languages[key].flagIcon,
      };
    });
    return array;
  }

  @computed
  get currentLangId() {
    return this.lang;
  }

  @computed
  get currentLangShortLocale() {
    return this.languages[this.lang] && this.languages[this.lang].localeShorten;
  }

  @computed
  get currentLangLocale() {
    return this.languages[this.lang] && this.languages[this.lang].locale;
  }
}

export default LanguageStore;
