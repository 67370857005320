import React, { useMemo, useState, useCallback } from "react";
import { observer } from "mobx-react";

import { Box, Card, CardContent, Typography, IconButton, Tooltip } from "@mui/material";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";

import { Notify } from "~/common/components";

import { FacilityOnContractCard } from "~/modules/facilities/components";
import { CreateFacilityScanningPlanDialog } from "~/modules/scanning/components/dialogs";

import Translator from "~/components/Translator";

/**
 * Виджет списка объектов на карточке контракта.
 */
const FacilitiesWidget = observer(({ contract, openCreateContractFacilityDialog }) => {
  const [isNotifyOpen, setIsNotifyOpen] = useState(false);
  const [currentFacility, setCurrentFacility] = useState(null);

  // Контроль над диалогов добавления плана сканирования
  const [isCreateFacilityScanningPlanDialogOpen, setIsCreateFacilityScanningPlanDialogOpen] = useState(false);
  const openCreateFacilityScanningPlanDialog = useCallback(
    (facility) => {
      setCurrentFacility(facility);
      setIsCreateFacilityScanningPlanDialogOpen(true);
    },
    [setIsCreateFacilityScanningPlanDialogOpen]
  );
  const closeCreateFacilityScanningPlanDialog = useCallback(() => {
    setCurrentFacility(null);
    setIsCreateFacilityScanningPlanDialogOpen(false);
  }, []);

  const { state, canAddFacility, allFacilities } = contract;

  const [content, totalArea] = useMemo(() => {
    let totalArea = 0;
    if (allFacilities.length <= 0) {
      return [
        <Typography color="error" sx={{ fontSize: "1rem", marginTop: "1rem" }}>
          <Translator text={"No facilities"} />
        </Typography>,
        totalArea,
      ];
    }
    const items = [];
    allFacilities.forEach((f) => {
      items.push(
        <FacilityOnContractCard
          status={state}
          facility={f}
          key={f.id}
          openCreateScanningPlanDialog={openCreateFacilityScanningPlanDialog}
        />
      );
      totalArea += f.area;
    });
    return [items, totalArea];
  }, [allFacilities, state, openCreateFacilityScanningPlanDialog]);

  return (
    <Card sx={{ m: 1, p: 1, pr: 0, display: "flex", flex: 1, flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>{content}</Box>
      <Box sx={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "space-between", alignItems: "end" }}>
        <Box sx={{ flexDirection: "row" }}>
          {!!totalArea && (
            <Tooltip arrow title={<Translator text="The sum of the areas of all facilities" />}>
              <>
                <Translator text={"Total area:"} />
                &nbsp;
                <Translator number={totalArea} /> <Translator text={"m2"} />
              </>
            </Tooltip>
          )}
        </Box>
        <Tooltip title={"Add facility"} arrow>
          {/* Здесь нужна обертка из-за тултипа. Без неё будет ругань в случае disabled button */}
          <Box>
            <IconButton
              key={"addFacility"}
              disabled={!canAddFacility}
              variant="contained"
              color="info"
              onClick={() => openCreateContractFacilityDialog()}
            >
              <AddBusinessIcon fontSize={"large"} />
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
      <CreateFacilityScanningPlanDialog
        facility={currentFacility}
        isOpen={isCreateFacilityScanningPlanDialogOpen}
        onClose={closeCreateFacilityScanningPlanDialog}
      />
      <Notify isOpen={isNotifyOpen} onClose={() => setIsNotifyOpen(false)}>
        <Translator text={"Facility created"} />
      </Notify>
    </Card>
  );
});

export default FacilitiesWidget;
