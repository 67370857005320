import React, { useCallback, useState } from "react";

import { FormDialog } from "~/components/dialogs";
import useStores from "~/hooks";

/**
 * Диалог создания нового плана сканирования.
 *
 * @param {*} props
 */
const CreateFacilityScanningPlanDialog = ({ facility, isOpen, onClose }) => {
  const { facilityStore } = useStores();
  const [isPending, setIsPending] = useState(false);
  const handleSubmit = useCallback(
    async (data) => {
      if (!facility) return;
      setIsPending(true);
      const payload = { ...data, contractId: facility.contract.id, facilityId: facility.id };
      await facilityStore.createFacilityScanningPlan(payload);
      setIsPending(false);
      onClose && onClose();
    },
    [onClose, facility, facilityStore]
  );
  const formConfig = {
    submitText: "Create",
    cancelText: "Cancel",
    fields: [
      {
        name: "schedule",
        type: "schedule",
        startName: "start",
        finishName: "finish",
        role: "scanner",
        employeeIdName: "scannerId",
        minimumPeriodInHours: 2,
      },
    ],
  };

  // TODO: добавить подтверждение создания плана
  return (
    <FormDialog
      title={"Creating scanning plan"}
      formConfig={formConfig}
      isVisible={isOpen}
      closeDialog={onClose}
      handleSubmit={handleSubmit}
      isPending={isPending}
      size="large"
    />
  );
};

export default CreateFacilityScanningPlanDialog;
