import React, { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react";

import useStores from "~/hooks";

import { FormDialog } from "~/components/dialogs";
import { Notify, Translator } from "~/components";

/**
 * Диалог закрытия смены.
 */
const ClosingShiftDialog = observer(({ shift, isOpen, onClose }) => {
  const [backendError, setBackendError] = useState("");
  const [isNotifyOpen, setIsNotifyOpen] = useState(false);

  const { branchStore, workingShiftsStore } = useStores();
  const { branch } = branchStore;

  const isPending = workingShiftsStore.isPending;
  const pendingText = workingShiftsStore.pendingText;

  /**
   * Обработка сабмита формы закрытия смены
   */
  const handleSubmit = useCallback(
    async (data) => {
      setBackendError("");
      const [result, error] = await workingShiftsStore.closeWorkingShift(shift, data);
      if (error) {
        setBackendError(error.detail);
      } else if (result) {
        setIsNotifyOpen(true);
        onClose();
      }
    },
    [shift, workingShiftsStore, onClose, setIsNotifyOpen]
  );

  // конфиг формы
  const config = useMemo(() => {
    if (!branch) return {};
    const fields = [
      {
        id: "scannerPhoto",
        name: "scannerPhoto",
        title: "Scanner photo",
        fileType: "photos",
        type: "file",
        accept: "image/*",
        multiple: true,
        min: 1,
        max: 4,
        isRequired: true,
        isReadOnly: false,
        validate: true,
      },
      {
        name: "carMileage",
        title: "Odometer reading",
        type: "number",
        viewConfig: "input",
        inputMode: "decimal",
        min: (shift && shift.startCarMileage) || 1,
        step: 1,
        isRequired: true,
        isReadOnly: false,
        validate: true,
        revealFunction: () => {
          return !!(shift && shift.startCarId);
        },
      },
      {
        id: "carPhoto",
        name: `carPhoto`,
        title: "Car photo",
        fileType: "photos",
        type: "file",
        accept: "image/*",
        multiple: true,
        min: 1,
        max: 4,
        isRequired: true,
        isReadOnly: false,
        validate: true,
        revealFunction: () => {
          return !!(shift && shift.startCarId);
        },
      },
    ];

    return {
      submitText: "Close shift",
      cancelText: "Cancel",
      fields,
    };
  }, [shift, branch]);

  if (!branch) return;

  return (
    <>
      <FormDialog
        title={"Closing shift"}
        formConfig={config}
        isVisible={isOpen}
        closeDialog={onClose}
        handleSubmit={handleSubmit}
        isPending={isPending}
        errorText={backendError}
        pendingText={pendingText}
      />
      <Notify isOpen={isNotifyOpen} onClose={() => setIsNotifyOpen(false)}>
        <Translator text={"Shift is closed"} />
      </Notify>
    </>
  );
});

export default ClosingShiftDialog;
