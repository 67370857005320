import { action, computed, makeObservable, observable } from "mobx";

class DocumentStore {
  @observable documents = new Map();

  constructor(root) {
    makeObservable(this);
    this.root = root;
  }

  /**
   * Загрузить документы по идентификаторам.
   *
   * @param {array} ids список идентификаторов для загрузки
   */
  loadDocuments(ids) {

  }

  /**
   * Получить документ из 
   * 
   * @param {integer} id идентификатор документа
   */
  getDocument(id) {

  }
}

export default DocumentStore;
