import React from "react";
import { observer } from "mobx-react";

import { Box, CircularProgress, Typography } from "@mui/material";
import { Translator } from "~/components";

const Preloader = observer(({ isPending, label = "", sx, size = "4rem", color = "info" }) => {
  if (!isPending) return null;

  return (
    <Box
      sx={{
        flexDirection: label !== "" ? "column" : "row",
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...sx,
      }}
    >
      <CircularProgress color={color} size={size} />
      {!!label && (
        <Typography sx={{ mt: 1 }}>
          <Translator text={label} /> ...
        </Typography>
      )}
    </Box>
  );
});

export default Preloader;
