import React, { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react";

import useMediaQuery from "@mui/material/useMediaQuery";

import { Dialog, DialogTitle, useTheme, Box } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

import { Form, Preloader, Translator, Notify } from "~/components";

/**
 * Базовый диалог с формой.
 */
const FormDialog = observer((props) => {
  const [isNotifyOpen, setIsNotifyOpen] = useState(false);
  const {
    size,
    formConfig,
    isVisible,
    closeDialog,
    title,
    notifyText,
    handleSubmit,
    isPending,
    formErrors,
    pendingText,
    errorText,
    noCancel,
    headerSx,
    preloaderColor,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const maxWidth = useMemo(() => {
    if (isMobile) return "100vw";
    if (size === "large") return "90vw";
    if (size === "small") return "30vw";
    return "60vw";
  }, [isMobile, size]);

  const isError = useMemo(() => {
    return !!errorText;
  }, [errorText]);

  const header = useMemo(() => {
    if (isPending) return pendingText;
    return title;
  }, [title, isPending, pendingText]);

  // По умолчанию запретим всем backdropclick
  const handleCloseDialog = useCallback(
    (e, reason) => {
      if (reason === "backdropClick") return;
      closeDialog();
    },
    [closeDialog]
  );

  return (
    <>
      <Dialog open={isVisible} onClose={handleCloseDialog} sx={{ ".MuiPaper-root": { maxWidth, borderColor: "red" } }}>
        <DialogTitle sx={{ ...headerSx, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <>
            <Box sx={{ flex: 3 }}>{header}</Box>
            <Preloader
              isPending={isPending}
              size={"2rem"}
              sx={{ justifyContent: "flex-end" }}
              color={preloaderColor || "info"}
            />
          </>
        </DialogTitle>
        {isError && (
          <Box
            sx={{
              p: 2,
              background: theme.palette.red.light,
              display: "flex",
              flexDirection: "row",
              color: theme.palette.grey["900"],
            }}
          >
            <ErrorIcon sx={{ mr: 1 }} />
            <Translator text={errorText} />
          </Box>
        )}
        <Form
          config={formConfig}
          isPending={isPending}
          errors={formErrors}
          onSubmit={handleSubmit}
          onCancel={closeDialog}
          noCancel={noCancel}
        />
      </Dialog>
      <Notify isOpen={isNotifyOpen} onClose={() => setIsNotifyOpen(false)}>
        <Translator text={notifyText} />
      </Notify>
    </>
  );
});

export default FormDialog;
