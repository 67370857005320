import { action, computed, makeObservable, observable } from "mobx";
import moment from "moment";

import Expense from "./Expense";
import Translator from "../components/Translator";

class Shift {
  @observable branch = null;
  @observable store = null;
  @observable id = null;
  @observable startedAt = null;
  @observable finishedAt = null;
  @observable role = null;
  @observable shiftExpensesMap = new Map();

  @observable startMetadata = {};

  @observable pending = false;

  constructor(props, store, branch) {
    makeObservable(this);

    this.branch = branch;
    this.store = store;
    this.api = store.oldApi;
    this.id = `${props.id}`;
    this.role = props.role;
    if (props.startedAt) {
      this.startedAt = moment(props.startedAt);
    }
    if (props.finishedAt) {
      this.finishedAt = moment(props.finishedAt);
    }
    if (props.startMetadata) {
      this.startMetadata = props.startMetadata;
    }
  }

  @action
  async setExpenses(data) {
    const { amount, categoryId } = data;
    let documentIds = undefined;
    if (data.documents) {
      documentIds = await this.store.root.uploadDocuments(data.documents);
    }

    const expense = await this.store.root.api.setShiftExpenses({
      shiftId: this.id,
      documents: documentIds,
      value: { amount, currency: this.branch.currency },
      categoryId,
    });
    this.addExpense(expense);
  }

  @action addExpense(expense) {
    this.shiftExpensesMap.set(`${expense.id}`, expense);
  }

  @action
  async getExpenses() {
    const expensesData = await this.store.root.api.getShiftExpenses(this.id);
    this.processExpenses(expensesData);
  }

  @action
  processExpenses(data) {
    this.shiftExpensesMap.clear();

    if (data && data.length) {
      data.forEach((data) => {
        this.addExpense(data);
      });
    }
  }

  @action
  setPending(pending = false) {
    this.pending = pending;
  }

  @computed
  get isPending() {
    return this.pending;
  }

  @computed
  get expensesArray() {
    return Array.from(this.shiftExpensesMap.values());
  }

  @computed
  get startCarId() {
    return (this.startMetadata && this.startMetadata.carId) || null;
  }

  @computed
  get startScannerId() {
    return (this.startMetadata && this.startMetadata.scannerId) || null;
  }

  @computed
  get startStationsCount() {
    return (this.startMetadata && this.startMetadata.stationsCount) || null;
  }

  @computed
  get startCarMileage() {
    return (this.startMetadata && this.startMetadata.carMileage) || null;
  }

  @computed
  get expensesByCategory() {
    const object = {};
    this.expensesArray.forEach((expense) => {
      if (expense.valueNumber) {
        if (object[expense.categoryId]) {
          object[expense.categoryId].value = object[expense.categoryId].value + expense.valueNumber;
        } else {
          object[expense.categoryId] = {
            id: expense.categoryName,
            label: <Translator text={expense.categoryName} />,
            value: expense.valueNumber,
          };
        }
      }
    });
    return object;
  }

  @computed
  get expensesTotal() {
    let amount = 0;
    this.shiftExpensesMap.forEach((item) => {
      if (item && item.valueNumber) {
        amount += item.valueNumber;
      }
    });
    return amount;
  }

  @computed
  get currency() {
    return this.branch && this.branch.currency;
  }

  @computed
  get pieData() {
    return Array.from(Object.values(this.expensesByCategory));
  }

  @computed
  get label() {
    return this.id;
  }

  // ------- removated
  /**
   * Используется ли машина в смене?
   *
   * @returns boolean
   */
  @computed get isCarUsed() {
    return this.startCarId != null;
  }

  @computed get isOpen() {
    return (!!this.startedAt && !this.finishedAt);
  }
}

export default Shift;
