import React, { useCallback, useEffect, useMemo, useState } from "react";

import { observer } from "mobx-react";
import { FormDialog } from "~/components/dialogs";
import useStores from "~/hooks";
import { Preloader, Notify, Translator } from "~/components";

/**
 * Диалог c формой открытия смены.
 */
const OpeningShiftDialog = observer(({ isOpen, onClose, branch, employeeId }) => {
  const [backendError, setBackendError] = useState("");
  const [isNotifyOpen, setIsNotifyOpen] = useState(false);

  const { equipmentStore, workingShiftsStore } = useStores();
  // Обработать форму
  const handleSubmit = useCallback(
    async (data) => {
      setBackendError("");
      const [result, error] = await workingShiftsStore.openWorkingShift(branch, data);
      if (error) {
        setBackendError(error.detail);
      } else if (result) {
        setIsNotifyOpen(true);
        onClose();
      }
    },
    [branch, workingShiftsStore, onClose]
  );

  const isPending = useMemo(() => {
    return workingShiftsStore.isPending;
  }, [workingShiftsStore.isPending]);

  const scanners = useMemo(() => {
    if (!branch) return [];
    return equipmentStore.getBranchScanners(branch.id);
  }, [branch, equipmentStore]);

  const cars = useMemo(() => {
    if (!branch) return [];
    return equipmentStore.getBranchCars(branch.id);
  }, [branch, equipmentStore]);

  const config = useMemo(() => {
    return {
      fields: [
        {
          name: "scannerId",
          fakeName: "scanner",
          title: "Select scanner",
          type: "select",
          canCreate: false,
          isRequired: false,
          isReadOnly: false,
          validate: true,
          loading: equipmentStore.isPending,
          options: scanners,
        },
        {
          id: "scannerPhoto",
          name: `scannerPhoto`,
          title: "Scanner photo",
          type: "file",
          accept: "image/*",
          multiple: true,
          min: 1,
          max: 4,
          isRequired: true,
          isReadOnly: false,
          validate: true,
        },
        {
          name: "carId",
          fakeName: "car",
          title: "Select car",
          type: "select",
          withEmptyValue: true,
          canCreate: false,
          isRequired: false,
          isReadOnly: false,
          validate: true,
          loading: equipmentStore.isPending,
          options: cars,
        },
        {
          name: "carMileage",
          title: "Car mileage, km",
          type: "number",
          viewConfig: "input",
          inputMode: "decimal",
          min: 1,
          step: 1,
          isRequired: true,
          isReadOnly: false,
          validate: true,
          revealFunction: (values) => {
            return !!values["carId"];
          },
        },
        {
          id: "carPhoto",
          name: `carPhoto`,
          title: "Car photo",
          type: "file",
          accept: "image/*",
          multiple: true,
          min: 1,
          max: 4,
          isRequired: true,
          isReadOnly: false,
          validate: true,
          revealFunction: (values) => {
            return !!values["carId"];
          },
        },
      ],
      submitText: "Open",
      cancelText: "Cancel",
    };
  }, [equipmentStore.isPending, cars, scanners]);

  if (!branch) return <Preloader />;

  return (
    <>
      <FormDialog
        title={"Opening shift"}
        formConfig={config}
        isVisible={isOpen}
        closeDialog={onClose}
        handleSubmit={handleSubmit}
        isPending={isPending}
        errorText={backendError}
        pendingText={workingShiftsStore.pendingText}
      />
      <Notify isOpen={isNotifyOpen} onClose={() => setIsNotifyOpen(false)}>
        <Translator text={"Shift is open"} />
      </Notify>
    </>
  );
});

export default OpeningShiftDialog;
