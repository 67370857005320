import React from "react";

import { Select, MenuItem } from "@mui/material";
import Translator from "../../../components/Translator";

// TODO: Вынести в один общий управляемый компонент
const StatesSelect = (props) => {
  const { options, value, onSelect } = props;

  return (
    <Select
      value={value}
      onChange={onSelect}
      multiple={true}
      sx={{ marginLeft: "1rem", minWidth: "20rem" }}
      inputProps={{ "aria-label": "Without label" }}
      displayEmpty={true}
      renderValue={(selected) => {
        if (selected.length === 0) {
          return (
            <em>
              <Translator text={"Select state..."} />
            </em>
          );
        }
        return selected.map((c) => <Translator key={c} text={c} after={" "} />);
      }}
    >
      {options.map(([id, value]) => (
        <MenuItem key={id} value={id}>
          <Translator text={value} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default StatesSelect;
