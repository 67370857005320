import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { Box, Card, CardActions, CardContent, Chip, Divider, Skeleton, Toolbar, Typography } from "@mui/material";

import ContractCard from "./ContractCard";
import { Translator } from "~/components";
import useStores from "~/hooks";

/**
 * Это не вся доска, только колонка. Один статус - одна колонка.
 */
const ContractsBoardColumn = observer(({ status, contracts }) => {
  const { contractStore, isPending } = useStores();

  const cardsRender = useMemo(() => {
    // TODO: Вынести Skeleton карточки
    if (isPending) {
      return (
        <Card sx={{ m: "0.5rem" }}>
          <CardContent>
            <Skeleton height="3rem" width="60%" />
            <Skeleton width="20%" />
            <Skeleton variant="text" width="75%" />
            <Box
              sx={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box sx={{ margin: 0.5 }}>
                <Skeleton variant="circular" height="1rem" width="1rem" />
              </Box>
              <Box sx={{ margin: 0.5, flex: 1 }}>
                <Skeleton height="0.5rem" />
              </Box>
            </Box>
            <Skeleton variant="text" />
            <Skeleton variant="text" height="1rem" />
            <Skeleton variant="text" height="1rem" width="85%" />
          </CardContent>
          <CardActions>
            <Skeleton height="2.5rem" sx={{ borderRadius: "0.25rem" }} variant="rectangular" width="20%" />
            <Skeleton height="2.5rem" sx={{ borderRadius: "0.25rem" }} variant="rectangular" width="20%" />
          </CardActions>
        </Card>
      );
    }

    // const regex = new RegExp(searchBy, "i");
    // Карточки контрактов
    const array = [];
    contracts.forEach((contract) => {
      array.push(<ContractCard key={contract.id} contract={contract} />);
    });
    return array;
    //   let isFacilityMatches = false;
    //   let isScannerMatches = false;
    //   let isModellerMatches = false;
    //   for (const facility of contract.facilities) {
    //     if (!facility) continue;
    //     if (facility.name.match(regex) || facility.description.match(regex)) {
    //       isFacilityMatches = true;
    //       break;
    //     }
    //     if (
    //       facility.scanPlan &&
    //       facility.scanPlan.employee &&
    //       (facility.scanPlan.employee.fullName.match(regex) || facility.scanPlan.employee.email.match(regex))
    //     ) {
    //       isScannerMatches = true;
    //       break;
    //     }
    //     for (const modellingPlan of facility.modellingPlans) {
    //       if (
    //         modellingPlan.modeller &&
    //         (modellingPlan.modeller.fullName.match(regex) || modellingPlan.modeller.email.match(regex))
    //       ) {
    //         isModellerMatches = true;
    //         break;
    //       }
    //     }
    //   }
    //   if (
    //     contract.number.match(regex) ||
    //     (contract.description && contract.description.match(regex)) ||
    //     contract.manager.fullName.match(regex) ||
    //     contract.manager.email.match(regex) ||
    //     contract.client.name.match(regex) ||
    //     contract.client.description.match(regex) ||
    //     isModellerMatches ||
    //     isScannerMatches ||
    //     isFacilityMatches
    //   ) {
    //   }
    // });
  }, [contracts, isPending]);

  const chipColor = useMemo(() => {
    const count = contracts.length;
    if (count > 5) return "error";
    if (count > 3) return "warning";
    if (count === 0) return "default";
    return "info";
  }, [contracts.length]);

  return (
    <Box
      sx={{
        flexDirection: "column",
        width: "27.5rem",
        overflow: "initial",
        flexShrink: 0,
      }}
    >
      <Toolbar
        variant="dense"
        color="secondary"
        sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
      >
        <Typography variant="body" sx={{ fontWeight: 500, paddingRight: "0.5rem" }} noWrap component="div">
          <Translator text={status.id} />
        </Typography>
        <Chip label={contracts.length} color={chipColor} size="small" />
      </Toolbar>
      <Divider />
      <Box sx={{ flexDirection: "column", flex: 1, overflow: "initial" }}>{cardsRender}</Box>
    </Box>
  );
});

export default ContractsBoardColumn;
