import { makeObservable, observable } from "mobx";

import moment from "moment";
import { GeoPoint } from "~/common/models";

/**
 * Метаданные начала сканирования объекта.
 *
 * {
 *   "id": 23,
 *   "datetime": "2023-09-29T17:12:25.641000+00:00",
 *   "point": [
 *       54.8833527,
 *       38.0752599
 *   ]
 * }
 */
export default class ScanningStarted {
  @observable id;
  @observable datetime = null;
  @observable geoPoint = null;

  // связанные
  @observable plan = null;

  constructor(plan, props) {
    makeObservable(this);

    const { id, datetime, point } = props;

    this.id = id;
    this.datetime = moment(datetime);
    this.geoPoint = new GeoPoint(point[0], point[1]);

    this.plan = plan;
  }
}
