import { action, computed, makeObservable, observable, runInAction, autorun } from "mobx";
import Branch from "./models/Branch";
import { BranchesApiV0 } from "../api";

/**
 *
 */
class BranchStore {
  @observable root = null;
  // @observable pending = false;
  @observable branchesMap = new Map();
  @observable branch; // активный бранч

  // --
  @observable pendingRequests = 0;
  @observable api = null;

  constructor(root) {
    makeObservable(this);
    this.root = root;
    this.api = new BranchesApiV0(root.authStore);

    // Эффект при смене бранча - получим его методы платежа. Они нужны для выставления счетов.
    autorun(() => {
      const effect = async () => {
        await this.root.invoiceStore.fetchPaymentMethods(this.branch);
        await this.root.employeesStore.fetchEmployees(this.branch.id);
        // TODO: также нужно запросить оборудование - авто и сканеры
      };
      if (!this.branch) return;
      if (!!this.root.invoiceStore) effect();
    });
  }

  /**
   * Получить список филиалов доступных пользователю.
   *
   * Поставить текущим либо ранее выбранный, либо первый из полученных.
   */
  @action async getBranches() {
    runInAction(() => this.pendingRequests++);
    const branchesData = await this.api.getBranches();
    const branches = [];
    runInAction(() => {
      branchesData.forEach((item) => {
        const branch = this.addBranch(item);
        branches.push(branch);
      });
      const branchId = localStorage.getItem("branch");
      if (branchId) this.setBranch(branchId);
      else this.setBranch(branches[0].id);
      this.pendingRequests--;
    });
    return branches;
  }

  /**
   * Добавить Бранч в стор.
   */
  @action addBranch(data) {
    const branch = new Branch(this, data);
    this.branchesMap.set(`${branch.id}`, branch);
    return branch;
  }

  /**
   * Поставить активный бранч.
   */
  @action setBranch(id) {
    this.branch = this.branchesMap.get(`${id}`);
    localStorage.setItem("branch", id);
    this.root.clientStore.fetchClientsForBranch(id);
    this.root.employeesStore.fetchEmployees(id);
  }

  @action getBranchById(id) {
    const branch = this.branchesMap.get(`${id}`);
    return branch;
  }

  /**
   * Если ли запросы в списке ожидания.
   */
  @computed get isPending() {
    return this.pendingRequests > 0;
  }

  @computed
  get isCurrentBranchPending() {
    return this.currentBranch && this.currentBranch.isPending;
  }

  @computed
  get currentScannerShift() {
    return (this.currentBranch && !this.isCurrentBranchPending && this.currentBranch.currentScannerShift) || null;
  }

  @computed get currentBranchId() {
    console.warn("Using deprecated property: currentBranchId");
    return this.branch?.id;
  }

  @computed
  get currentBranch() {
    console.warn("Using deprecated property: currentBranch");
    return this.branch;
  }

  @computed
  get currentBranchEquipmentCars() {
    return (this.branch && this.branch.carsArray) || [];
  }

  @computed
  get currentBranchShiftExpenses() {
    return (this.branch && this.branch.shiftExpenseCategoriesArray) || [];
  }

  /**
   * Вернуть бранчи списком.
   */
  @computed get branches() {
    const array = [];
    this.branchesMap.forEach((branch) => array.push(branch));
    return array;
  }
}

export default BranchStore;
