import ApiBase from "~/api/ApiBase";

class MaterialsApiV0 extends ApiBase {
  /**
   * Запросить материалы бранча для роли на языке.
   *
   * @param {*} param0
   * @returns
   */
  async fetchMaterials(branchId, { role, lang }) {
    const urlParams = new URLSearchParams();
    role && urlParams.set("role", role);
    lang && urlParams.set("lang", lang);

    const url = `api/v0/branches/${branchId}/training/materials?${urlParams}`;

    const [data] = await this.get(url);
    return data;
  }
}

export default MaterialsApiV0;
