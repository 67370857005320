import { observable, action, computed, makeObservable, runInAction, autorun, values } from "mobx";
import { EquipmentApiV0 } from "../api";

import { Scanner, Car } from "./models";

/**
 * Стор оборудования.
 */
class EquipmentStore {
  @observable root;
  @observable api;
  @observable scanners = new Map();
  @observable cars = new Map();
  @observable pendingOperations = 0;

  constructor(root) {
    makeObservable(this);
    this.root = root;
    this.api = new EquipmentApiV0(root.authStore);

    // Эффект при смене бранча - получим сканеры и автомобили
    autorun(() => {
      const { branch } = this.root.branchStore;
      if (!branch) return;
      const effect = async (branch) => {
        await Promise.all([
          this.fetchBranchScanners(branch.id),
          this.fetchBranchCars(branch.id),
        ]);
      };
      effect(branch);
    });
  }

  /**
   * Запросить сканеры филиала.
   *
   * @param {*} branchId
   */
  @action async fetchBranchScanners(branchId) {
    const scanners = await this.api.fetchBranchScanners(branchId);
    runInAction(() => {
      this.pendingOperations++;
      const branchKey = `${branchId}`;
      if (!this.scanners.has(branchKey)) {
        this.scanners.set(branchKey, {});
      }
      scanners.forEach((x) => {
        const scanner = new Scanner(x);
        const scannerKey = `${scanner.id}`;
        this.scanners.get(branchKey)[scannerKey] = scanner;
      });
      this.pendingOperations--;
    });
  }

  /**
   * Запросить автомобили филиала.
   *
   * @param {*} branchId
   */
  @action async fetchBranchCars(branchId) {
    const cars = await this.api.fetchBranchCars(branchId);
    runInAction(() => {
      this.pendingOperations++;
      const branchKey = `${branchId}`;
      if (!this.cars.has(branchKey)) {
        this.cars.set(branchKey, {});
      }
      cars.forEach((x) => {
        const car = new Car(x);
        const carKey = `${car.id}`;
        this.cars.get(branchKey)[carKey] = car;
      });
      this.pendingOperations--;
    });
  }

  /**
   * Получить массив сканеров бранча.
   * 
   * @param {number} branchId 
   * @returns
   */
  @action getBranchScanners(branchId) {
    if (!this.scanners.get(`${branchId}`)) return [];
    return values(this.scanners.get(`${branchId}`));
  }

  /**
   * Получить массив автомобилей бранча.
   * 
   * @param {number} branchId 
   * @returns 
   */
  @action getBranchCars(branchId) {
    if (!this.cars.get(`${branchId}`)) return [];
    return values(this.cars.get(`${branchId}`));
  }

  /**
   * Есть ли активный запрос в текущий момент.
   */
  @computed get isPending() {
    return this.api.isPending || this.pendingOperations > 0;
  }
}

export default EquipmentStore;
