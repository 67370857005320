import React from "react";
import { observer } from "mobx-react";
import useStores from "~/hooks";

import Preloader from "~/components/Preloader";

import { useTheme } from "@mui/material";
import BarChartItem from "./BarChartItem";

const BarChart = observer(({ id }) => {
  const theme = useTheme();
  const { invoiceStore } = useStores();

  const invoice = invoiceStore.getInvoiceById(id);
  if (!invoice || invoice.isPending) {
    return <Preloader isPending={true} />;
  }

  const { chartArray } = invoice;

  const itemsRender = chartArray.map((item, i) => {
    return <BarChartItem key={item.id} item={item} i={i} />;
  });

  return (
    <div
      style={{
        flexDirection: "row",
        flex: 1,
        display: "flex",
        cursor: "pointer",
        overflow: "hidden",
        justifyContent: "flex-start",
        p: "0",
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        borderRadius: "0.25rem",
      }}
    >
      {itemsRender}
    </div>
  );
});

export default BarChart;
