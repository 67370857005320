import React from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { Translator } from "~/components";

import ListItemIcon from "@mui/material/ListItemIcon";
import EditIcon from "@mui/icons-material/Edit";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import DataSaverOffIcon from "@mui/icons-material/DataSaverOff";
import PaymentsIcon from "@mui/icons-material/Payments";
import PaidIcon from "@mui/icons-material/Paid";

/**
 *  Контекстное меню контракта
 */
const ContractContextMenu = (props) => {
  const {
    contract,
    position,
    handleClose,
    openEditContractDialog,
    openAddContractExpenseDialog,
    openContractExpenseDialog,
    openCreateInvoiceDialog,
  } = props;
  const [x, y] = position;
  const isVisible = !!x && !!y;
  return (
    <Menu
      open={isVisible}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={isVisible ? { top: y, left: x } : undefined}
      slotProps={{
        root: {
          onContextMenu: (e) => {
            e.preventDefault();
            handleClose();
          },
        },
      }}
    >
      <MenuItem onClick={openCreateInvoiceDialog} disabled={contract?.state.slug === "closed"}>
        <ListItemIcon>
          <PaymentsIcon />
        </ListItemIcon>
        <Translator text={"Create invoice..."} />
      </MenuItem>
      {/* <MenuItem onClick={openCreateInvoiceDialog} disabled={contract?.state.slug === "closed"}>
        <ListItemIcon>
          <PaidIcon />
        </ListItemIcon>
        <Translator text={"Invoices and payments"} />
      </MenuItem> */}
      <MenuItem onClick={openAddContractExpenseDialog} disabled={contract?.state === "closed"}>
        <ListItemIcon>
          <MoneyOffIcon />
        </ListItemIcon>
        <Translator text={"Register contract expense..."} />
      </MenuItem>
      <MenuItem onClick={openContractExpenseDialog} disabled={contract?.state === "closed"}>
        <ListItemIcon>
          <DataSaverOffIcon />
        </ListItemIcon>
        <Translator text={"Contract expenses"} />
      </MenuItem>
      <MenuItem onClick={openEditContractDialog} disabled={contract?.state.slug === "closed"}>
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        <Translator text={"Edit contract..."} />
      </MenuItem>
    </Menu>
  );
};

export default ContractContextMenu;
